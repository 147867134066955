import { Box, Typography } from "@mui/material";

export function TermsAndConditions() {
  return (
    <Box mt={{ xs: 4, md: 8 }}>
      <Typography component="h2" variant="subtitle" color="text-primary">
        Terms and conditions
      </Typography>

      <Typography component="p" variant="footnote" color="text-dark" mt={{ xs: 2, md: 4 }}>
        * Unless otherwise noted all pricing is on a per person twin share basis. All prices based
        on cash payment - credit card fees may apply. Cruise deposit, amendment and cancellation
        conditions apply. Special conditions may apply - please ask for full details at time of
        enquiry. All passports, vaccinations and visas are the responsibility of the travelling
        guest to secure prior to departure from Australia. Any pricing not in Australian Dollars
        will be converted on the day of payment into the relevant currency based on our daily rate
        of exchange. Please note that many of the prices shown on this website are not shown in real
        time. Whilst we endeavour to keep our pricing as up-to-date as possible, the advertised
        prices shown here may differ slightly from the live prices in our booking system. All
        pricing and offers are subject to availability at time of booking. Whilst all information is
        correct at the time of publication, offers are subject to change. We reserve the right to
        correct errors without penalty. Please submit any data errors/omissions you may notice on
        this site.
      </Typography>

      <Typography component="p" variant="footnote" color="text-dark" mt={{ xs: 2, md: 4 }}>
        Unless otherwise expressly noted, all materials, including images, illustrations, designs,
        icons, and photographs appearing anywhere on this website are protected by worldwide
        copyright laws and treaty provisions. The copyright on such materials is held by the
        original creator of the materials. None of the materials may be copied, reproduced,
        displayed, modified, published, uploaded, posted, transmitted or distributed in whole or in
        part in any form or by any means other than as provided by Cruise Factory and Cruise
        Traveller to its clients, or with the prior written permission of Cruise Factory and Cruise
        Traveller. All rights not expressly granted in these Terms are reserved. Any unauthorised
        use of the materials appearing on this website may violate copyright, trademark and other
        applicable laws and could result in criminal or civil penalties.
      </Typography>
    </Box>
  );
}
