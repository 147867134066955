/* eslint-disable @typescript-eslint/no-explicit-any */
import { nanoid } from "@reduxjs/toolkit";

import { Rooms } from "@store/slices/roomsSlice";

// Detailed payment intent request and response type can be found here:
// https://www.airwallex.com/docs/api#/Payment_Acceptance/Payment_Intents/_api_v1_pa_payment_intents_create/post
type PaymentIntentRequest = {
  request_id: string;
  merchant_order_id: string;
  amount: number;
  currency: string;
  order: {
    products: {
      url: string;
      name: string;
      desc: string;
      unit_price: number;
      currency: string;
      quantity: number;
    }[];
  };
};

type PaymentIntentBody = {
  amount: number;
  client_secret: string;
  id: string;
  currency: string;
};

const API_DOMAIN = process.env.REACT_APP_AIRWALLEX_API_DOMAIN;
const API_KEY = process.env.REACT_APP_AIRWALLEX_API_KEY;
const CLIENT_ID = process.env.REACT_APP_AIRWALLEX_CLIENT_ID;

const getAuthToken = async (): Promise<string> => {
  const authResponse = await fetch(`${API_DOMAIN}/api/v1/authentication/login`, {
    method: "POST",
    headers: {
      "x-api-key": API_KEY as string,
      "x-client-id": CLIENT_ID as string,
    },
  }).then((data) => data.json());

  return authResponse.token;
};

export const createPaymentIntent = async (
  paymentIntent: PaymentIntentRequest,
): Promise<PaymentIntentBody> => {
  const token = await getAuthToken();

  const intentData = await fetch(`${API_DOMAIN}/api/v1/pa/payment_intents/create`, {
    method: "POST",
    body: JSON.stringify(paymentIntent),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((data) => data.json());

  return intentData.data;
};

export const createIntent = async ({
  guests,
  rooms,
  transactions,
  priceType,
}: {
  guests: any;
  rooms?: Rooms;
  transactions: {
    full?: number;
    deposit?: number;
    amount: number;
    currency: string;
    transaction_type: string;
  }[];
  priceType: "full" | "deposit";
}) => {
  const address = {
    city: guests?.[1]?.[1].city ?? "",
    country_code: guests?.[1]?.[1].country ?? "",
    postcode: guests?.[1]?.[1].zip_code ?? "",
    state: guests?.[1]?.[1].state ?? "",
    street: guests?.[1]?.[1].address1 ?? "",
  };

  const customer = {
    address,
    email: guests?.[1]?.[1].email ?? "",
    first_name: guests?.[1]?.[1].given_name ?? "",
    last_name: guests?.[1]?.[1].last_name ?? "",
    phone_number: guests?.[1]?.[1].phone ?? "",
  };

  const products = [
    {
      url: rooms?.[1]?.grade?.images[0] || "https://via.placeholder.com/503x570",
      name: rooms?.[1]?.pnr ?? "",
      desc: "Cabin booking",
      unit_price: transactions[0].amount,
      currency: transactions[0].currency,
      quantity: 1,
      metadata: {
        priceType,
      },
    },
  ];

  const requestBody = {
    request_id: nanoid(),
    merchant_order_id: nanoid(),
    amount: transactions[0].amount,
    currency: transactions[0].currency,
    customer,
    account_id: process.env.REACT_APP_AIRWALLEX_ACCOUNT_ID,
    client_id: CLIENT_ID,
    order: {
      products,
    },
  };

  const { id, client_secret } = await createPaymentIntent(requestBody);

  return { id, client_secret };
};
