import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { PaymentScheduleItem, Rooms } from "@store/slices/roomsSlice";

export interface PricingState {
  payment_schedule: Record<string, PaymentScheduleItem>;
  amount: string;
  currency: string;
  due_date: string;
}

const initialState: PricingState = {
  payment_schedule: {},
  amount: "",
  currency: "",
  due_date: "",
};

const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    setPricing: (state: PricingState, action: PayloadAction<Rooms | undefined>) => {
      if (!action.payload) {
        return;
      }

      const output: Record<string, PaymentScheduleItem> = {};
      const clonedRooms = structuredClone(action.payload);

      Object.keys(clonedRooms).forEach((roomKey) => {
        const { pricing } = clonedRooms[+roomKey];

        if (pricing?.payment_schedule) {
          pricing.payment_schedule.forEach((transaction: PaymentScheduleItem) => {
            const { date, amount, currency } = transaction;

            if (!output[date]) {
              state.currency = currency;
              output[date] = transaction;
            } else {
              state.currency = currency;
              output[date].amount = `${Number(output[date].amount) + Number(amount)}`;
            }
          });
        }
      });

      state.payment_schedule = output;
    },
  },
});

export const { setPricing } = pricingSlice.actions;

export default pricingSlice.reducer;
