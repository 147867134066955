import { Box } from "@mui/material";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

interface IShipSwiperProps {
  images: string[];
}

export function ShipSwiper({ images }: IShipSwiperProps) {
  return (
    <Box
      sx={{
        borderRadius: (theme) => theme.toPx(theme.customShape.smallRadius),
        overflow: "hidden",
      }}
    >
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
      >
        {images.map((image) => {
          return (
            <SwiperSlide key={image}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: {
                    xs: 234,
                    md: 510,
                    lg: 680,
                  },
                  borderRadius: (theme) => theme.toPx(theme.customShape.smallRadius),
                  overflow: "hidden",
                }}
              >
                <img
                  src={image}
                  alt="ship"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
