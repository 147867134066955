export const DRAWER_WIDTH = 320;
export const HEADER_HEIGHT = 120;
export const NAV_BAR_HEIGHT = 46;
export const NAV_ITEMS = [
  { text: "Experiences", href: "/" },
  { text: "Destinations", href: "/destinations" },
  { text: "Cruise Lines", href: "/cruise-lines" },
  { text: "Cruise Search", href: "/cruise-search" },
  { text: "Cruise Specials", href: "/cruise-specials" },
  { text: "Solo Specials", href: "/solo-specials" },
  { text: "Fly Free Specials", href: "/fly-free-specials" },
];
