import { posApi } from "@store/services/apiSingleton";

export interface IInitPosSessionResponse {
  key: string;
}

export interface IInitPosSessionRequest {
  agent: string;
  agency: string;
}

export const PosAPI = posApi.injectEndpoints({
  endpoints: (builder) => ({
    initPosSession: builder.query<IInitPosSessionResponse, IInitPosSessionRequest>({
      query: (body) => ({
        url: "/sessions/",
        method: "POST",
        body,
      }),
    }),
  }),
});
