import { createSlice } from "@reduxjs/toolkit";

import {
  AgencyAPI,
  IInitConsortiaResponse,
  IInitMarketsResponse,
} from "@store/services/AgencyService";

export interface AgencyState {
  consortia: IInitConsortiaResponse;
  markets: IInitMarketsResponse;
}

const initialState: AgencyState = {
  consortia: [],
  markets: [],
};

const agencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(AgencyAPI.endpoints.initConsortia.matchFulfilled, (state, action) => {
        state.consortia = action.payload;
      })
      .addMatcher(AgencyAPI.endpoints.initMarkets.matchFulfilled, (state, action) => {
        state.markets = action.payload;
      });
  },
});

export default agencySlice.reducer;
