import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import BookingDetails from "@componentsV1/base/BookingDetails";
import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import LegalData from "@componentsV1/pages/Payment/molecules/LegalData";
import PriceType from "@componentsV1/pages/Payment/molecules/PriceType";
import Button from "@componentsV1/shared/Button";
import CustomSelect from "@componentsV1/shared/CustomSelect";
import Input from "@componentsV1/shared/Input";
import useScript from "@hooks/useScript";
import { useLazyCreateOpaquePaymentQuery } from "@store/services/PaymentService";
import { showModal } from "@store/slices/modalSlice";
import { showToast } from "@store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import MODAL from "@utils/constants/modal";
import TOAST from "@utils/constants/toast";
import getMonthsList from "@utils/helpers/getMonthsList";
import getYearsList from "@utils/helpers/getYearsList";
import { validateCVV, validateCreditCard, validateDate } from "@utils/validation/functions/payment";

import styles from "./index.module.scss";

interface AcceptObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchData?: (secureData: any, responseHandler: any) => void;
}

function Payment() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const [createOpaqueData] = useLazyCreateOpaquePaymentQuery();

  const { search } = useLocation();
  const { cruiseId } = useParams();
  const [params] = useSearchParams();

  const { isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { sessionKey } = useTypedSelector((state) => state.session);
  const { guests } = useTypedSelector((state) => state.guests);

  const {
    authorize_api_login_id,
    authorize_client_key,
    authorize_is_test,
    authorize_is_mine,
    terms_and_conditions,
    participant_release_agreement,
    supplemental_terms,
    show_cancellation_policy,
    guest_ticket_contract,
    bin_restriction,
  } = useTypedSelector((state) => state.environment);

  const [priceType, setPriceType] = useState<"full" | "deposit">("full");
  const [isLoading, setIsLoading] = useState(false);

  const [date, setDate] = useState({
    month: { value: "", errorMessage: "" },
    year: { value: "", errorMessage: "" },
  });

  const [card, setCard] = useState({
    cardNumber: { value: "", errorMessage: "" },
    expirationDate: {
      value: "",
      errorMessage: "",
    },
    cvv: { value: "", errorMessage: "" },
  });

  const [legalData, setLegalData] = useState({
    terms_and_conditions: !terms_and_conditions,
    participant_release_agreement: !participant_release_agreement,
    supplemental_terms: !supplemental_terms,
    guest_ticket_contract: !guest_ticket_contract,
    show_cancellation_policy: !show_cancellation_policy,
  });

  const isUseAcceptCore = useMemo(
    () => [true, "true"].includes(authorize_is_mine),
    [authorize_is_mine],
  );

  const scriptUrl = useMemo(
    () =>
      [true, "true"].includes(authorize_is_test)
        ? "https://jstest.authorize.net/v1/Accept.js"
        : "https://js.authorize.net/v1/Accept.js",
    [authorize_is_test],
  );

  const [loading] = useScript(scriptUrl);

  const activeRestriction = useMemo(() => {
    const parsedRestrictions: Array<{
      number: string[];
      errorMessage: string;
    }> = JSON.parse(bin_restriction || "[]");

    const matchedRestriction = parsedRestrictions.find((bin) =>
      bin.number.some((number) => card.cardNumber.value.startsWith(number)),
    );

    return matchedRestriction;
  }, [card.cardNumber.value]);

  const isFormValid = useMemo(() => {
    const isNumberValid = !!card.cardNumber.value && !card.cardNumber.errorMessage;

    const isExpDateValid = !!card.expirationDate.value && !card.expirationDate.errorMessage;

    const isCvvValid = !!card.cvv.value && !card.cvv.errorMessage;

    const isLegalDataValid = Object.values(legalData).every((el) => !!el);

    return isNumberValid && isExpDateValid && isCvvValid && isLegalDataValid;
  }, [card, legalData]);

  const transactions: [
    {
      full?: number;
      deposit?: number;
      amount: number;
      currency: string;
      made_by: string;
      transaction_type: string;
    },
  ] = useMemo(() => {
    const output = {
      full: 0,
      deposit: 0,
      amount: 0,
      currency: rooms?.[1].pricing?.payment_schedule?.[0].currency ?? "",
      made_by: "Website Authorize.net",
      transaction_type: "creditcard",
    };

    const deposit = Object.values(rooms ?? {}).reduce((sum, room) => {
      const roomDeposit = room?.pricing?.payment_schedule?.[0]?.amount ?? 0;

      return Number(sum) + Number(roomDeposit);
    }, 0);

    output.deposit = deposit;

    Object.values(rooms ?? {}).forEach((room) => {
      const paymentSchedule = room?.pricing?.payment_schedule;

      if (Array.isArray(paymentSchedule)) {
        paymentSchedule.forEach((payment) => {
          if (payment.amount) {
            output.full += parseFloat(payment.amount);
          }
        });
      }
    });

    if (priceType === "full") {
      output.amount = output.full;
    }

    if (priceType === "deposit") {
      output.amount = output.deposit;
    }

    output.amount = Math.ceil(output.amount * 100) / 100;
    output.full = Math.ceil(output.full * 100) / 100;
    output.deposit = Math.ceil(output.deposit * 100) / 100;

    return [output];
  }, [rooms, card, priceType]);

  const monthList = useMemo(() => {
    return getMonthsList().map((item) => ({ value: item, label: item }));
  }, []);

  const yearList = useMemo(() => {
    return getYearsList(20, 0)
      .sort((a, b) => b - a)
      .map((item) => ({
        value: String(item),
        label: String(item),
      }));
  }, []);

  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  const handleExpirationDateMonth = ({ value }: { value: string }) => {
    setDate((prev) => ({ ...prev, month: { value, errorMessage: "" } }));
  };

  const handleExpirationDateYear = ({ value }: { value: string }) => {
    setDate((prev) => ({ ...prev, year: { value, errorMessage: "" } }));
  };

  const handleInputChange = ({ value, valueKey }: { value: string; valueKey: string }) => {
    setCard((prev) => ({ ...prev, [valueKey]: { errorMessage: "", value } }));
  };

  const handlePriceTypeChange = (type: typeof priceType) => setPriceType(type);

  const handleLegalDataChange = ({ value, valueKey }: { value: boolean; valueKey: string }) => {
    setLegalData((prev) => ({ ...prev, [valueKey]: value }));
  };

  const setErrors = ({
    cardNumber,
    cvv,
    expirationDate,
    combinedLength,
  }: {
    cardNumber: boolean | string;
    cvv: boolean | string;
    expirationDate: boolean | string;
    combinedLength: boolean;
  }) => {
    setCard((prev) => {
      const output = structuredClone(prev);

      if (typeof cardNumber === "string") {
        output.cardNumber.errorMessage = cardNumber;
      } else if (!cardNumber) {
        output.cardNumber.errorMessage = t("Card number is not valid");
      }

      if (typeof cvv === "string") {
        output.cvv.errorMessage = cvv;
      } else if (!cvv) {
        output.cvv.errorMessage = t("CVV number is not valid");
      }

      if (typeof expirationDate === "string") {
        output.expirationDate.errorMessage = expirationDate;
      } else if (!expirationDate) {
        output.expirationDate.errorMessage = t("Date is not valid");
      }

      if (!combinedLength) {
        output.cvv.errorMessage = t("Combined length of card number and CVV should be 19");
      }

      return output;
    });
  };

  const acceptDataHandler = async (opaqueData: { opaqueData: { dataValue: string } }) => {
    try {
      const clonedTransictions = structuredClone(transactions[0]);

      if ("full" in clonedTransictions && "deposit" in clonedTransictions) {
        delete clonedTransictions.full;
        delete clonedTransictions.deposit;
      }

      const response = await createOpaqueData({
        session: sessionKey,
        pnr: rooms?.[1]?.pnr ?? "",
        source: params.get("source"),
        transactions: [
          {
            ...clonedTransictions,
            amount: `${clonedTransictions.amount}`,
            tokenized: opaqueData.opaqueData.dataValue,
          },
        ],
      });

      if (response.data.errors && response.isError) {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Payment failed"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      } else {
        dispatch(
          showToast({
            type: TOAST.SUCCESS_TYPE,
            message: t("Payment success"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );

        navigate(`/search-results/${cruiseId}/payment-confirmation${search}`);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);

      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Payment failed"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const makeOpaqueRequet = async () => {
    try {
      const cardData = {
        cardNumber: card.cardNumber.value,
        year: card.expirationDate.value.split("-")[0],
        month: card.expirationDate.value.split("-")[1],
        cardCode: card.cvv.value,
      };

      const opaqueTransactionRequest = [
        {
          currency: transactions[0].currency ?? "",
          amount: transactions[0].amount,
          transaction_type: "creditcard",
          made_by: "Website",
          card: {
            pan: cardData.cardNumber,
            expiry_month: cardData.month,
            expiry_year: cardData.year,
            cvv: cardData.cardCode,
            name: `${guests?.[1]?.[1].given_name ?? ""} ${guests?.[1]?.[1].last_name ?? ""}`,
            address: {
              address: `${guests?.[1]?.[1].address1} ${guests?.[1]?.[1].address2 ?? ""}`,
              city: guests?.[1]?.[1].city,
              state: guests?.[1]?.[1].state,
              zip: guests?.[1]?.[1].zip_code,
              country: guests?.[1]?.[1].country,
            },
            email: guests?.[1]?.[1].email,
            phone: guests?.[1]?.[1].phone,
          },
        },
      ];

      const response = await createOpaqueData({
        session: sessionKey,
        pnr: rooms?.[1]?.pnr ?? "",
        source: params.get("source"),
        transactions: opaqueTransactionRequest,
      });

      if (response.data.errors && response.isError) {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Payment failed"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      } else {
        dispatch(
          showToast({
            type: TOAST.SUCCESS_TYPE,
            message: t("Payment success"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );

        navigate(`/search-results/${cruiseId}/payment-confirmation${search}`);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);

      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Payment failed"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const payHandler = async () => {
    const authData = {
      apiLoginID: authorize_api_login_id,
      clientKey: authorize_client_key,
    };

    const cardData = {
      cardNumber: card.cardNumber.value,
      year: card.expirationDate.value.split("-")[0],
      month: card.expirationDate.value.split("-")[1],
      cardCode: card.cvv.value,
    };

    const acceptTransactionRequest = {
      amount: transactions[0].amount,
      transactionType: "authCaptureTransaction",
      description: "Cabin booking payment",
      billTo: {
        firstName: guests?.[1]?.[1].given_name ?? "",
        lastName: guests?.[1]?.[1].last_name ?? "",
        city: guests?.[1]?.[1].city ?? "",
        state: guests?.[1]?.[1].state ?? "",
        zip: guests?.[1]?.[1].zip_code ?? "",
        country: guests?.[1]?.[1].country ?? "",
      },
      refId: rooms?.[1]?.pnr ?? "",
    };

    const secureData = {
      authData,
      cardData,
      transactionRequest: acceptTransactionRequest,
    };

    setIsLoading(true);

    if ("Accept" in window && isUseAcceptCore) {
      const accept: AcceptObject = window.Accept as AcceptObject;

      if (accept.dispatchData && typeof accept.dispatchData === "function") {
        accept.dispatchData(secureData, acceptDataHandler);
      }
    } else {
      makeOpaqueRequet();
    }
  };

  const validateForm = () => {
    const cardNumber = validateCreditCard(card.cardNumber.value);
    const cvv = validateCVV(card.cvv.value);
    const expirationDate = validateDate(card.expirationDate.value);

    const combinedLength =
      card.cardNumber.value.replace(/\s/g, "").length + card.cvv.value.length === 19;

    if (!cardNumber || !cvv || !expirationDate || !combinedLength) {
      setErrors({
        cardNumber,
        cvv,
        expirationDate,
        combinedLength,
      });
    } else {
      payHandler();
    }
  };

  useEffect(() => {
    setCard((prevState) => ({
      ...prevState,
      expirationDate: {
        ...prevState.expirationDate,
        value:
          Boolean(date.year.value) && Boolean(date.month.value)
            ? `${date.year.value}-${date.month.value}`
            : "",
      },
    }));
  }, [date.month.value, date.year.value]);

  useEffect(() => {
    if (activeRestriction) {
      setCard((prev) => ({
        ...prev,
        cardNumber: {
          ...prev.cardNumber,
          errorMessage: activeRestriction.errorMessage,
        },
      }));
    } else {
      setCard((prev) => ({
        ...prev,
        cardNumber: {
          ...prev.cardNumber,
          errorMessage: "",
        },
      }));
    }
  }, [activeRestriction]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={!loading && !isCruiseLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1 className={styles.title}>{t("Payment")}</h1>

            <PriceType
              full={transactions[0].full ?? 0}
              deposit={transactions[0].deposit ?? 0}
              currency={transactions[0].currency ?? ""}
              onPriceTypeChange={handlePriceTypeChange}
            />

            <form className={styles.form} onSubmit={validateForm}>
              <div className={styles.inputs}>
                <Input
                  className={styles.input}
                  value={card.cardNumber.value}
                  valueKey="cardNumber"
                  label={t("Card number")}
                  placeholder={t("Enter 16 digits")}
                  errorMessage={card.cardNumber.errorMessage}
                  onChange={handleInputChange}
                />
              </div>

              <div className={styles.inputsDate}>
                <CustomSelect
                  className={styles.input}
                  value={date.month.value}
                  label={t("month")}
                  errorMessage={card.expirationDate.errorMessage}
                  items={monthList}
                  placeholder={t("Month")}
                  onChange={handleExpirationDateMonth}
                />

                <CustomSelect
                  className={styles.input}
                  value={date.year.value}
                  label={t("year")}
                  errorMessage={card.expirationDate.errorMessage}
                  items={yearList.reverse()}
                  placeholder={t("Year")}
                  onChange={handleExpirationDateYear}
                />
              </div>

              <div className={styles.inputs}>
                <Input
                  className={styles.input}
                  value={card.cvv.value}
                  valueKey="cvv"
                  label={t("Card Code")}
                  placeholder={t("3 digits")}
                  errorMessage={card.cvv.errorMessage}
                  onChange={handleInputChange}
                />
              </div>

              <LegalData
                terms_and_conditions_checked={legalData.terms_and_conditions}
                participant_release_agreement_checked={legalData.participant_release_agreement}
                supplemental_terms_checked={legalData.supplemental_terms}
                guest_ticket_contract_checked={legalData.guest_ticket_contract}
                show_cancellation_policy_checked={legalData.show_cancellation_policy}
                onChange={handleLegalDataChange}
              />
            </form>

            <div className={styles.inputs}>
              <Button
                onClick={validateForm}
                className={styles.button}
                label={t("Pay")}
                loading={isLoading}
                disabled={!isFormValid || isLoading}
              />
            </div>
          </div>

          <div className={styles.right}>
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowItineraryModal}
            />

            <BookingDetails />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Payment;
