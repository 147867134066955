import classNames from "classnames";
import { ChangeEvent, useCallback, useEffect, useState, useRef } from "react";

import styles from "./index.module.scss";

interface IRangeSliderProps {
  min: number;
  max: number;
  onChange?: (value: { min: number; max: number }) => void;
  onMouseUp?: (value: { min: number; max: number }) => void;
  showLabel?: boolean;
  className?: string;
}

function RangeSlider({ min, max, onChange, onMouseUp, showLabel, className }: IRangeSliderProps) {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);

  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef<HTMLDivElement>(null);

  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  );

  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  return (
    <div className={classNames(styles.container, className)}>
      <input
        className={classNames(styles.thumb, styles.thumb__left)}
        style={{ zIndex: minVal > max - 100 ? 5 : undefined }}
        type="range"
        min={min}
        max={max}
        value={minVal}
        onMouseUp={() => {
          if (onMouseUp) {
            onMouseUp({ min: minVal, max: maxVal });
          }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);

          minValRef.current = value;
          setMinVal(value);

          if (onChange) {
            onChange({ min: value, max: maxVal });
          }
        }}
      />

      <input
        className={classNames(styles.thumb, styles.thumb__right)}
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onMouseUp={() => {
          if (onMouseUp) {
            onMouseUp({ min: minVal, max: maxVal });
          }
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(Number(event.target.value), minVal + 1);

          maxValRef.current = value;
          setMaxVal(value);

          if (onChange) {
            onChange({ min: minVal, max: value });
          }
        }}
      />

      <div className={classNames(styles.slider)}>
        <div className={classNames(styles.slider__track)} />
        <div ref={range} className={classNames(styles.slider__range)} />

        {showLabel && (
          <>
            <div className={classNames(styles.slider__left__value)}>{minVal}</div>
            <div className={classNames(styles.slider__right__value)}>{maxVal}</div>
          </>
        )}
      </div>
    </div>
  );
}

RangeSlider.defaultProps = {
  showLabel: false,
  className: "",
  onChange: () => null,
  onMouseUp: () => null,
};

export default RangeSlider;
