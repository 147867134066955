/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
import LIVR from "livr";
import extraRules from "livr-extra-rules";

import {
  NOT_POSITIVE_ERRORS,
  WRONG_FORMAT_ERRORS,
  REQUIRED_ERRORS,
  NOT_EQUAL_ERRORS,
  TOO_SHORT_ERRORS,
  WRONG_INTEGER_ERRORS,
} from "./errors";

import rules from "./rules";

LIVR.Validator.registerDefaultRules(extraRules);

LIVR.Validator.defaultAutoTrim(true);

function validate({ rule, data, onSuccess, onError }) {
  const validator = new LIVR.Validator(rule);

  validator.registerRules({
    phone: () => {
      const phoneRegex = /^[0-9+ ]+$/;

      return function (value) {
        if (typeof value !== "string" || !phoneRegex.test(value)) {
          return "PHONE_FORMAT_ERROR";
        }
      };
    },
  });

  const validData = validator.validate(data);

  if (validData && onSuccess) {
    if (onSuccess) {
      onSuccess(validData);
    }
  } else {
    const decodedErrors = decodeErrorObject(validator.getErrors());

    if (onError) {
      onError(decodedErrors);
    }
  }
}

export function validateSearch(args) {
  return validate({ rule: rules.search, ...args });
}

export function validateRooms(args) {
  return validate({ rule: rules.rooms, ...args });
}

export function validateGuestForm(args) {
  return validate({ rule: rules.guestForm, ...args });
}

function decodeErrorObject(errors) {
  const decodedErrors = { ...errors };

  for (const field in decodedErrors) {
    if (Object.prototype.hasOwnProperty.call(decodedErrors, field)) {
      const errorField = field.replace("data/", "");

      decodedErrors[errorField] = decodeErrorCode(decodedErrors[field], errorField);
    }
  }

  return decodedErrors;
}

export function decodeErrorCode(code, field = "") {
  switch (code) {
    case "REQUIRED": {
      const errorMessage = field && REQUIRED_ERRORS[field] && REQUIRED_ERRORS[field]();

      return errorMessage || "Required";
    }

    case "WRONG_DATE": {
      const errorMessage = field && WRONG_FORMAT_ERRORS[field] && WRONG_FORMAT_ERRORS[field]();

      return errorMessage || "Wrong format";
    }

    case "WRONG_EMAIL": {
      const errorMessage = field && WRONG_FORMAT_ERRORS[field] && WRONG_FORMAT_ERRORS[field]();

      return errorMessage || "Wrong format";
    }

    case "WRONG_URL": {
      const errorMessage = field && WRONG_FORMAT_ERRORS[field] && WRONG_FORMAT_ERRORS[field]();

      return errorMessage || "Wrong format";
    }

    case "NOT_POSITIVE_INTEGER": {
      const errorMessage = field && NOT_POSITIVE_ERRORS[field] && NOT_POSITIVE_ERRORS[field]();

      return errorMessage || "Not positive";
    }

    case "FIELDS_NOT_EQUAL": {
      const errorMessage = field && NOT_EQUAL_ERRORS[field] && NOT_EQUAL_ERRORS[field]();

      return errorMessage || "Not equal";
    }

    case "TOO_SHORT": {
      const errorMessage = field && TOO_SHORT_ERRORS[field] && TOO_SHORT_ERRORS[field]();

      return errorMessage || "Too short";
    }

    case "TOO_LOW": {
      const errorMessage = field && WRONG_INTEGER_ERRORS[field] && WRONG_INTEGER_ERRORS[field]();

      return errorMessage || "Wrong value";
    }

    case "TOO_HIGH": {
      const errorMessage = field && WRONG_INTEGER_ERRORS[field] && WRONG_INTEGER_ERRORS[field]();

      return errorMessage || "Wrong value";
    }

    case "PHONE_FORMAT_ERROR": {
      const errorMessage = field && WRONG_FORMAT_ERRORS[field] && WRONG_FORMAT_ERRORS[field]();

      return errorMessage || "Wrong value";
    }

    default: {
      return code;
    }
  }
}
