import { Typography, Box } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

export function TripInclusions() {
  const { cruise } = useTypedSelector((state) => state.search);

  const { title, descriptionLines } = useMemo(() => {
    if (!cruise?.cruise?.descriptions || !cruise?.cruise?.itinerary) {
      return {
        descriptionItems: ["Loading..."],
      };
    }

    const descriptionItem = cruise.cruise.descriptions.find(
      (desc) => desc.category === "Inclusions",
    );

    const descriptionLines = descriptionItem?.description?.split("\n") ?? [];

    return {
      title: descriptionItem?.title ?? "Inclusions",
      descriptionLines,
    };
  }, [cruise]);

  return (
    <Box>
      <Typography component="h2" variant="sectionTitle" color="text-primary" mb={4}>
        {title}
      </Typography>

      <Typography component="span" variant="footnote">
        {descriptionLines &&
          descriptionLines.map((line) => (
            <span key={line}>
              {line}
              <br />
            </span>
          ))}
      </Typography>
    </Box>
  );
}
