import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CabinSelectAPI, Cabin } from "@store/services/CabinSelectService";
import { Deck, Grade, Price } from "@store/slices/searchSlice";

export interface PaymentScheduleItem {
  amount: string;
  currency: string;
  date: string;
}

interface Pricing {
  payment_schedule?: PaymentScheduleItem[];
  total_price?: string;
  guests?: Array<{
    discount: string;
    gft: string;
    guest: number;
    nccf: string;
    total_fare: string;
    total_price: string;
  }>;
}

export interface Room {
  fare?: Price;
  marketType?: string;
  grade?: Grade;
  guestsNumber?: number;
  cabin?: Cabin;
  deck?: Deck;
  pricing?: Pricing;
  pnr?: string;
}

export type Rooms = Record<number, Room>;

export interface RoomsState {
  rooms?: Rooms;
  cabins?: Cabin[];
  isCabinsLoading: boolean;
}

const initialState: RoomsState = {
  rooms: undefined,
  cabins: undefined,
  isCabinsLoading: true,
};

const roomsSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    updateRoomsState(state: RoomsState, action: PayloadAction<RoomsState>) {
      state.rooms = action.payload.rooms;
      state.cabins = action.payload.cabins;
    },
    updateRooms(state: RoomsState, action: PayloadAction<Rooms>) {
      state.rooms = action.payload;
    },
    updateRoom(state: RoomsState, action: PayloadAction<{ room: Room; roomNumber: number }>) {
      if (state.rooms) {
        state.rooms[action.payload.roomNumber] = action.payload.room;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(CabinSelectAPI.endpoints.initCabins.matchPending, (state) => {
        state.isCabinsLoading = true;
      })
      .addMatcher(CabinSelectAPI.endpoints.initCabins.matchFulfilled, (state, action) => {
        state.cabins = action.payload || [];
        state.isCabinsLoading = false;
      })
      .addMatcher(CabinSelectAPI.endpoints.initCabins.matchFulfilled, (state) => {
        state.isCabinsLoading = false;
      });
  },
});

export const { updateRoomsState, updateRooms, updateRoom } = roomsSlice.actions;

export default roomsSlice.reducer;
