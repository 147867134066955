import { AppBarProps, ComponentsOverrides } from "@mui/material";

import { HEADER_HEIGHT } from "@utils/constants/header";

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    "bg-primary": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
    red: true;
  }
}

interface IMuiAppBar {
  defaultProps: Partial<AppBarProps>;
  styleOverrides: ComponentsOverrides["MuiAppBar"];
}

export const MuiAppBar: IMuiAppBar = {
  defaultProps: {
    color: "bg-primary",
    component: "header",
  },
  styleOverrides: {
    root: {
      minHeight: HEADER_HEIGHT,
      boxShadow: "none",
      padding: 0,
    },
  },
};
