import { Box, Typography, Stack, Divider, useMediaQuery } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

export function ShipStats() {
  const { cruise } = useTypedSelector((state) => state.search);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const statsInfo = useMemo(() => {
    if (!cruise?.ship) {
      return [];
    }

    const shipName = cruise.ship.name;
    const totalPassengers = cruise.ship.total_passengers;

    const crew = cruise.ship.descriptions.find((desc) => desc.category === "Crew");
    const officers = cruise.ship.descriptions.find((desc) => desc.category === "Officers");
    const diningStaff = cruise.ship.descriptions.find((desc) => desc.category === "Dining Staff");

    return [
      {
        title: "PASSENGERS",
        info: `The ${shipName} carries ${totalPassengers} passengers`,
      },
      {
        title: crew?.title,
        info: crew?.description,
      },
      {
        title: officers?.title,
        info: officers?.description,
      },
      {
        title: diningStaff?.title,
        info: diningStaff?.description,
      },
    ];
  }, [cruise]);

  return (
    <Box>
      <Typography component="p" variant="sectionHeader" color="text-primary" mb={2}>
        Ship Profile & Stats
      </Typography>

      <Stack
        spacing={{ xs: 2, md: 4 }}
        direction={{ xs: "column", md: "row" }}
        divider={isMobile ? null : <Divider orientation="vertical" flexItem />}
      >
        {statsInfo.map(({ title, info }) => (
          <Box
            key={title}
            sx={{
              width: { xs: "100%", md: "25%" },
            }}
          >
            <Typography
              component="p"
              variant="footnote"
              color="text-dark"
              fontWeight={700}
              mb={0.5}
              textTransform="uppercase"
            >
              {title}
            </Typography>

            <Typography component="p" variant="footnote" fontWeight={400} color="text-dark">
              {info}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
