import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { hideToast } from "@store/slices/toastSlice";
import { useTypedSelector } from "@store/store";

import styles from "./index.module.scss";
import Toast from "./molecules/Toast";

interface IToast {
  isVisible: boolean;
  message: string;
  type: string;
  duration: number;
}

const SWITCH_TOAST_DURATION = 500;

const DEFAULT_ACTIVE_TOAST = {
  isVisible: false,
  message: "",
  type: "",
  duration: 0,
};

function ToastContainer({ children }: React.PropsWithChildren) {
  const dispatch = useDispatch();

  const toastState = useTypedSelector((state) => state.toast);

  const [toastQueue, setToastQueue] = useState([DEFAULT_ACTIVE_TOAST]);
  const [activeToast, setActiveToast] = useState<IToast>(DEFAULT_ACTIVE_TOAST);

  const hideActiveToast = () => {
    setActiveToast((prevToast) => ({ ...prevToast, isVisible: false }));

    setTimeout(() => {
      setActiveToast(DEFAULT_ACTIVE_TOAST);
    }, SWITCH_TOAST_DURATION);
  };

  const showNextToast = () => {
    if (toastQueue.length > 0) {
      const nextToast = toastQueue[0];

      setActiveToast(nextToast);

      setTimeout(() => {
        setToastQueue((prevQueue) => prevQueue.slice(1));

        hideActiveToast();
        dispatch(hideToast());
      }, nextToast.duration);
    }
  };

  useEffect(() => {
    if (toastState.message) {
      const newToast = { ...toastState, isVisible: true };

      setToastQueue([...toastQueue, newToast]);
    }
  }, [toastState.message]);

  useEffect(() => {
    if (!activeToast.message) {
      showNextToast();
    }
  }, [toastQueue, activeToast]);

  return (
    <div className={styles.container}>
      {children}

      <Toast
        isVisible={activeToast.isVisible}
        message={activeToast.message}
        type={activeToast.type}
      />
    </div>
  );
}

export default ToastContainer;
