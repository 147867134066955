/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useLazyCreateOpaquePaymentQuery } from "../../../store/services/PaymentService"; // Custom hook for making lazy queries to the payment service // TODO: possible use for the new endpoint (createIntent) when the backend is ready
import {
  AirwallexEnv,
  CardNumberElementType,
  confirmPaymentIntent,
  createElement,
  CvcElementType,
  ExpiryDateElementType,
  getElement,
  loadAirwallex,
} from "airwallex-payment-elements";
import { useEffect, useMemo, useRef, useState } from "react"; // React hooks for managing component state and lifecycle
import { useTranslation } from "react-i18next"; // Translation hook for multilingual support
import { useLocation, useNavigate, useParams } from "react-router-dom"; // Importing hooks for navigation and URL parameters from React Router.

import LoadingContainer from "@componentsAWX/containers/LoadingContainer"; // Container component for displaying loading state
import Button from "@componentsAWX/shared/Button"; // Reusable button component
import { showToast } from "@store/slices/toastSlice"; // Redux action for showing toast messages
import { useTypedDispatch, useTypedSelector } from "@store/store"; // Import hooks for Redux store
import TOAST from "@utils/constants/toast"; // Constants for toast messages

import { usePaymentState } from "./hooks/usePaymentState";
import styles from "./index.module.scss"; // Styles for the Payment component
import LegalData from "./molecules/LegalData"; // Component for accepting legal terms
import PriceType from "./molecules/PriceType"; // Component for selecting price type (full/deposit)
import RightSideBlock from "./molecules/RightSideBlock";
import { createIntent } from "./utils";

// React functional component representing the Payment page.
function Payment() {
  // React hooks for state management and side effects
  const navigate = useNavigate(); // Navigate function provided by React Router
  const dispatch = useTypedDispatch(); // Typed dispatch function from the Redux store
  const { t } = useTranslation(); // Translation function provided by react-i18next

  // React Router hooks for accessing location and URL parameters
  const { search } = useLocation(); // Location object for the current URL
  const { cruiseId } = useParams(); // Parameters extracted from the URL path

  // Redux selectors for accessing state variables
  const { isCruiseLoading } = useTypedSelector((state) => state.search); // Flag indicating if cruise data is loading
  const { rooms } = useTypedSelector((state) => state.rooms); // Available rooms fetched from the Redux store
  const { guests } = useTypedSelector((state) => state.guests); // Guest information fetched from the Redux store

  // Environment variables fetched from the Redux store
  const {
    terms_and_conditions,
    participant_release_agreement,
    supplemental_terms,
    show_cancellation_policy,
    guest_ticket_contract,
  } = useTypedSelector((state) => state.environment);

  const {
    priceType,
    setPriceType,
    isLoading,
    setIsLoading,
    // airwallex:
    cardNumberReady,
    setCardNumberReady,
    cvcReady,
    setCvcReady,
    expiryReady,
    setExpiryReady,
    cardNumberComplete,
    setCardNumberComplete,
    cvcComplete,
    setCvcComplete,
    expiryComplete,
    setExpiryComplete,
    errorMessage,
    setErrorMessage,
    inputErrorMessage,
    setInputErrorMessage,
  } = usePaymentState();

  const cardNumberRef = useRef<CardNumberElementType | null>(null);
  const expiryRef = useRef<ExpiryDateElementType | null>(null);
  const cvcRef = useRef<CvcElementType | null>(null);

  //  State variable to manage legal data related to terms and conditions, participant release agreement, supplemental terms, guest ticket contract, and show cancellation policy.
  const [legalData, setLegalData] = useState({
    terms_and_conditions: !terms_and_conditions,
    participant_release_agreement: !participant_release_agreement,
    supplemental_terms: !supplemental_terms,
    guest_ticket_contract: !guest_ticket_contract,
    show_cancellation_policy: !show_cancellation_policy,
  });

  // Memoized transactions array containing payment details, based on room pricing and selected price type.
  const transactions: [
    {
      full?: number;
      deposit?: number;
      amount: number;
      currency: string;
      transaction_type: string;
    },
  ] = useMemo(() => {
    const output = {
      full: 0,
      deposit: 0,
      amount: 0,
      currency: rooms?.[1].pricing?.payment_schedule?.[0].currency ?? "",
      transaction_type: "creditcard",
    };

    const deposit = Object.values(rooms ?? {}).reduce((sum, room) => {
      const roomDeposit = room?.pricing?.payment_schedule?.[0]?.amount ?? 0;

      return Number(sum) + Number(roomDeposit);
    }, 0);

    output.deposit = deposit;

    Object.values(rooms ?? {}).forEach((room) => {
      const paymentSchedule = room?.pricing?.payment_schedule;

      if (Array.isArray(paymentSchedule)) {
        paymentSchedule.forEach((payment) => {
          if (payment.amount) {
            output.full += parseFloat(payment.amount);
          }
        });
      }
    });

    if (priceType === "full") {
      output.amount = output.full;
    }

    if (priceType === "deposit") {
      output.amount = output.deposit;
    }

    output.amount = Math.ceil(output.amount * 100) / 100;
    output.full = Math.ceil(output.full * 100) / 100;
    output.deposit = Math.ceil(output.deposit * 100) / 100;

    return [output];
  }, [rooms, cardNumberRef.current, priceType]);

  // Handler function to update the selected price type (full or deposit)
  const handlePriceTypeChange = (type: typeof priceType) => setPriceType(type);

  // Handler function to update the value of a legal data field
  const handleLegalDataChange = ({ value, valueKey }: { value: boolean; valueKey: string }) => {
    setLegalData((prev) => ({ ...prev, [valueKey]: value }));
  };

  const onPaymentFail = () => {
    dispatch(
      showToast({
        type: TOAST.ERROR_TYPE,
        message: t("Payment failed"),
        duration: TOAST.DEFAULT_DURATION,
      }),
    );
  };

  const onPaymentSuccess = () => {
    // Dispatch a success toast message and navigate to payment confirmation page
    dispatch(
      showToast({
        type: TOAST.SUCCESS_TYPE,
        message: t("Payment success"),
        duration: TOAST.DEFAULT_DURATION,
      }),
    );

    navigate(`/search-results/${cruiseId}/payment-confirmation${search}`);
  };

  // Handler function for initiating payment process (Airwallex)
  const handleConfirm = async () => {
    setIsLoading(true); // Example: set loading state
    setErrorMessage(""); // Example: reset error message

    // Get the card number element
    const cardNumElement = getElement("cardNumber");

    // Ensure the cardNumElement is available
    if (!cardNumElement) {
      setIsLoading(false); // Reset loading state if element is not found

      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Card number element is not available"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      return;
    }

    try {
      const { id, client_secret } = await createIntent({
        guests,
        rooms,
        transactions,
        priceType,
      });
      // Call confirmPaymentIntent with the cardNumElement and other required parameters
      const response = await confirmPaymentIntent({
        element: cardNumElement, // Only need to submit CardNumber element
        id,
        client_secret,
        payment_method_options: {
          card: {
            auto_capture: true,
          },
        },
      });

      // : Handle success response
      setIsLoading(false); // Reset loading state
      // eslint-disable-next-line no-console
      console.log("Payment confirmed successfully", response);
      onPaymentSuccess();
    } catch (error) {
      // Handle error response
      const errorText = error instanceof Error ? error.message : JSON.stringify(error);

      setIsLoading(false); // Reset loading state

      onPaymentFail();
      setErrorMessage(errorText); // Example: set error message
    }
  };

  // Effect to scroll to the top of the page when component mounts
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const initializeAirwallex = async () => {
      try {
        // Initialize Airwallex on mount with the appropriate production environment and other configurations
        await loadAirwallex({
          env: process.env.REACT_APP_AIRWALLEX_MODE as AirwallexEnv, // Can choose other production environments, 'staging | 'demo' | 'prod'
          origin: window.location.origin, // Setup your event target to receive the browser events message
          // For more detailed documentation at https://github.com/airwallex/airwallex-payment-demo/tree/master/docs#loadAirwallex
        });

        // Create and mount the individual card elements
        const cardNumEle = createElement("cardNumber");
        const cvcEle = createElement("cvc");
        const expiryEle = createElement("expiry");

        if (!cardNumEle || !cvcEle || !expiryEle) return;
        // Mount split card elements
        cardNumEle?.mount("cardNumber"); // This 'cardNumber' id MUST MATCH the id on your cardNumber empty container created in Step 3
        cvcEle?.mount("cvc"); // Same as above
        expiryEle?.mount("expiry"); // Same as above
        cardNumberRef.current = cardNumEle;
        expiryRef.current = expiryEle;
        cvcRef.current = cvcEle;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error initializing Airwallex:", error);

        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: t("Error initializing Airwallex"),
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      }
    };

    initializeAirwallex();

    // Add an event handler to ensure the element is mounted
    const onReady = (event: any) => {
      const { type } = event.detail;

      if (type === "cardNumber") {
        setCardNumberReady(true);
      }

      if (type === "cvc") {
        setCvcReady(true);
      }

      if (type === "expiry") {
        setExpiryReady(true);
      }
    };

    // Add an event listener to listen to the changes in each of the input fields
    const onChange = (event: any) => {
      const { type, complete } = event.detail;

      if (type === "cardNumber") {
        setCardNumberComplete(complete);
      }

      if (type === "cvc") {
        setCvcComplete(complete);
      }

      if (type === "expiry") {
        setExpiryComplete(complete);
      }
    };

    // Add an event listener to get input focus status
    const onFocus = (event: CustomEvent) => {
      const { type } = event.detail;

      setInputErrorMessage({
        ...inputErrorMessage,
        [type]: "", // Example: clear input error message
      });
      // Customize your input focus style by listen onFocus event
    };

    // STEP #10: Add an event listener to show input error message when finish typing
    const onBlur = (event: CustomEvent) => {
      const { type, error } = event.detail;

      setInputErrorMessage((prev) => ({
        ...prev,
        [type]: error?.message ?? JSON.stringify(error),
      }));
    };

    const cardNumberElement = document.getElementById("cardNumber");
    const expiryElement = document.getElementById("expiry");
    const cvcElement = document.getElementById("cvc");
    const domElementArray = [cardNumberElement, expiryElement, cvcElement];

    domElementArray.forEach((element) => {
      element?.addEventListener("onReady", onReady as EventListener);
      element?.addEventListener("onChange", onChange as EventListener);
      element?.addEventListener("onFocus", onFocus as EventListener);
      element?.addEventListener("onBlur", onBlur as EventListener);
    });

    return () => {
      domElementArray.forEach((element) => {
        element?.removeEventListener("onReady", onReady as EventListener);
        element?.removeEventListener("onChange", onChange as EventListener);
        element?.removeEventListener("onFocus", onFocus as EventListener);
        element?.removeEventListener("onBlur", onBlur as EventListener);
      });
    };
  }, []); // This effect should ONLY RUN ONCE as we do not want to reload Airwallex and remount the elements

  const allElementsReady = cardNumberReady && cvcReady && expiryReady;
  // Example: combine all element complete states
  const allElementsComplete = cardNumberComplete && cvcComplete && expiryComplete;

  const legalDataAccepted =
    legalData.guest_ticket_contract &&
    legalData.participant_release_agreement &&
    legalData.show_cancellation_policy &&
    legalData.supplemental_terms &&
    legalData.terms_and_conditions;

  // Return JSX for the Payment component
  return (
    <div className={styles.container}>
      {/* Content section */}
      <div className={styles.content}>
        {/* Left section */}
        <div className={styles.left}>
          {/* Title */}
          <h1 className={styles.title}>{t("Payment")}</h1>
          <LoadingContainer isLoading={isCruiseLoading}>
            {/* Price type selection */}
            <PriceType
              full={transactions[0].full ?? 0}
              deposit={transactions[0].deposit ?? 0}
              currency={transactions[0].currency ?? ""}
              onPriceTypeChange={handlePriceTypeChange}
            />
          </LoadingContainer>
          {/* Payment form */}
          <LoadingContainer isLoading={!allElementsReady} />
          <form className={styles.form} style={{ display: !allElementsReady ? "none" : "flex" }}>
            {/* AIRWALLEX  */}
            {errorMessage.length > 0 && <div className={styles.inputError}>{errorMessage}</div>}
            <div style={{ display: allElementsReady ? "block" : "none" }}>
              <div className={styles.fieldContainer}>
                <div className={styles.labelAirWallex}>Card number</div>
                <div className={styles.inputAirWallex} id="cardNumber" />
                <div className={styles.inputError}>{inputErrorMessage.cardNumber || " "}</div>
              </div>
              <div className={styles.fieldContainer}>
                <div className={styles.labelAirWallex}>Expiry</div>
                <div className={styles.inputAirWallex} id="expiry" />
                <span className={styles.inputError}>{inputErrorMessage.expiry || " "}</span>
              </div>
              <div className={styles.fieldContainer}>
                <div className={styles.labelAirWallex}>Card Code</div>
                <div className={styles.inputAirWallex} id="cvc" />
                <span className={styles.inputError}>{inputErrorMessage.cvc || " "}</span>
              </div>
            </div>

            {/* Legal data checkboxes */}
            <LegalData
              terms_and_conditions_checked={legalData.terms_and_conditions}
              participant_release_agreement_checked={legalData.participant_release_agreement}
              supplemental_terms_checked={legalData.supplemental_terms}
              guest_ticket_contract_checked={legalData.guest_ticket_contract}
              show_cancellation_policy_checked={legalData.show_cancellation_policy}
              onChange={handleLegalDataChange}
            />
          </form>

          {/* Pay button */}
          <div className={styles.inputs}>
            <Button
              label={isLoading ? t("Loading") : t("Pay")}
              className={styles.button}
              onClick={handleConfirm}
              loading={isLoading}
              disabled={!allElementsComplete || isLoading || !legalDataAccepted} // Prevents invalid submissions
            />
          </div>
        </div>
        <RightSideBlock />
      </div>
    </div>
  );
}

export default Payment;
