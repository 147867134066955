import { Box, Tabs, Tab, Grid2 as Grid } from "@mui/material";
import { useState } from "react";

import { ItineraryContent } from "../molecules/ItineraryContent";
import { Pricing } from "../molecules/Pricing";
import { Ship } from "../molecules/Ship";
import { TripInclusions } from "../molecules/TripInclusions";

export function CruiseTabs() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box mt={{ xs: 4, md: 8 }} sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        component={Grid}
        container
        spacing={2}
        value={selectedTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons={false}
        sx={{ mb: 4, "& .MuiTabs-flexContainer": { gap: 2 } }}
      >
        <Grid sx={{ typography: "tab" }} component={Tab} size={3} label="Itinerary" />
        <Grid sx={{ typography: "tab" }} component={Tab} size={3} label="Inclusions" />
        <Grid sx={{ typography: "tab" }} component={Tab} size={3} label="Pricing" />
        <Grid sx={{ typography: "tab" }} component={Tab} size={3} label="The Ship" />
      </Tabs>

      <Box>
        {selectedTab === 0 && <ItineraryContent />}
        {selectedTab === 1 && <TripInclusions />}
        {selectedTab === 2 && <Pricing />}
        {selectedTab === 3 && <Ship />}
      </Box>
    </Box>
  );
}
