import { Box, Typography, Grid2 as Grid } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

export function ShipFacilities() {
  const { cruise } = useTypedSelector((state) => state.search);

  const facilitiesInfo = useMemo(() => {
    if (!cruise?.ship) {
      return [];
    }

    const facilities = cruise.ship.descriptions.filter((el) => el.category === "Ship Facilities");

    return facilities;
  }, [cruise]);

  return (
    <Box>
      <Typography component="p" variant="sectionHeader" color="text-primary" mb={2}>
        Ship Facilities
      </Typography>

      <Grid container spacing={{ xs: 2, md: 4 }}>
        {facilitiesInfo.map(({ title, description }) => (
          <Grid size={{ xs: 12, md: 6, lg: 4 }} key={title}>
            <Box
              sx={{
                padding: { xs: 2, lg: 4 },
                border: "2px solid",
                borderColor: (theme) => theme.palette["border-color"].main,
                borderRadius: (theme) => theme.toPx(theme.customShape.largeRadius),
                height: "100%",
              }}
            >
              <Typography
                component="p"
                variant="footnote"
                color="text-dark"
                fontWeight={700}
                mb={1}
                textTransform="uppercase"
              >
                {title}
              </Typography>

              <Typography component="p" variant="footnote" fontWeight={400} color="text-dark">
                {description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
