import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "@componentsV1/shared/Button";
import CustomSelect from "@componentsV1/shared/CustomSelect";
import DatePicker from "@componentsV1/shared/DatePicker";
import SearchInput from "@componentsV1/shared/SearchInput";
import useSearch from "@hooks/useSearch";
import { useLazySearchLocationsQuery } from "@store/services/SearchService";
import { useTypedSelector } from "@store/store";
import { validateSearch } from "@utils/validation";

import styles from "./index.module.scss";

interface IInput {
  value: string;
  errorMessage?: string;
}

interface IDateInput {
  value: string[];
  errorMessage?: string;
}

interface IInputs {
  [key: string]: IInput | IDateInput;
  country: IInput;
  date: IDateInput;
  duration: IInput;
}

interface IValidationInputs {
  [key: string]: string;
  country: string;
  date: string;
  duration: string;
}

const DURATION_ITEMS = [
  { value: "1", label: "1" },
  { value: "3-5", label: "3-5" },
  { value: "7", label: "7" },
  { value: "8-10", label: "8-10" },
  { value: "11-14", label: "11-14" },
  { value: "14+", label: "14+" },
];

const createArrayFromRange = (rangeString: string) => {
  const [start, end] = rangeString.split("-").map(Number);

  const resultArray = [...Array(end - start + 1)].map((_, index) => start + index);

  return resultArray;
};

function Search() {
  const [searchLocations] = useLazySearchLocationsQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [lastDate, setLastDate] = useState("");

  const { destination_input_placeholder } = useTypedSelector((state) => state.environment);

  const { locations, searchHandlers, resetHandlers } = useSearch();

  const [chosenLocations, setChosenLocations] = useState<[] | Array<Record<string, string>>>([]);

  const [inputs, setInputs] = useState<IInputs>({
    country: { value: "" },
    date: {
      value: [],
      errorMessage: "",
    },
    duration: { value: "" },
  });

  const initLastDate = async () => {
    try {
      const { data } = await searchLocations("");

      if (data?.last_date) {
        setLastDate(data.last_date);
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);

      throw new Error(errorMessage);
    }
  };

  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs: IInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey]) {
        updatedInputs[errorKey].errorMessage = errors[errorKey];
      }
    });

    setInputs(updatedInputs);
  };

  const searchLocation = async (value: string) => {
    if (value) {
      await searchHandlers.locationSearch(value);
    } else {
      resetHandlers.locationReset();
    }
  };

  const handleInputChange = ({
    value,
    valueKey,
  }: {
    value: string | string[];
    valueKey?: string;
  }) => {
    if (valueKey) {
      setInputs((prev: IInputs) => {
        return {
          ...prev,
          [valueKey]: {
            errorMessage: "",
            value: Array.isArray(value) ? value : [value],
          },
        };
      });
    }

    if (valueKey === "country") {
      searchLocation(value as string);
    }
  };

  const handleChosenLocationsChange = ({ items }: { items?: Array<Record<string, string>> }) =>
    setChosenLocations(items ?? []);

  const handleSaveSearch = (payload: {
    duration: string;
    date: string[];
    chosenLocations: [] | Array<Record<string, string>>;
  }) => {
    localStorage.setItem("search", JSON.stringify({ ...payload }));
  };

  const handleSearch = () => {
    const night = (() => {
      switch (true) {
        case inputs.duration.value[0]?.includes("-"): {
          const rangeArray = createArrayFromRange(inputs.duration.value[0]);

          return rangeArray.join("-");
        }

        case inputs.duration.value[0]?.includes("+"): {
          return inputs.duration.value[0].replace("+", "");
        }

        default: {
          return inputs.duration.value[0];
        }
      }
    })();

    const { country, ship, tag } = (() => {
      const output: Record<string, string[]> = {
        country: [],
        ship: [],
        cruise_tag: [],
      };

      if (chosenLocations.length) {
        chosenLocations.forEach((location) => {
          const { type } = location;

          output[type].push(location.value);
        });
      }

      return {
        country: output.country.join("-"),
        ship: output.ship.join("-"),
        tag: output.cruise_tag.join("-"),
      };
    })();

    const payload: Record<string, string> = {
      night,
      country,
      ship,
      tag,
    };

    inputs.date.value.forEach((date, index) => {
      const dateKey = index === 0 ? "start_date" : "end_date";

      payload[dateKey] = date;
    });

    handleSaveSearch({
      duration: inputs.duration.value[0],
      date: inputs.date.value,
      chosenLocations,
    });

    const searchParams = new URLSearchParams();

    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        searchParams.append(key, value);
      }
    });

    const newSearch = searchParams.toString();

    navigate(`/search-results?${newSearch}`);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const handleSubmit = (_: any) => {
    validateSearch({
      data: {
        country: inputs.country.value,
        start_date: inputs.date.value[0],
        end_date: inputs.date.value[1],
        duration: inputs.duration.value,
      },
      onSuccess: handleSearch,
      onError: (errors: IValidationInputs) => {
        const clonedErrors = structuredClone(errors);

        if (clonedErrors.start_date || clonedErrors.end_date) {
          clonedErrors.date = clonedErrors.start_date ?? clonedErrors.end_date;

          delete clonedErrors.start_date;
          delete clonedErrors.end_date;
        }

        handleFormError(clonedErrors);
      },
    });
  };

  const location = useLocation();

  useEffect(() => {
    if (!localStorage.getItem("search") || location.search !== "?edit") {
      return;
    }

    const search = JSON.parse(localStorage.getItem("search") ?? "");

    setChosenLocations(search?.chosenLocations ?? []);

    handleInputChange({
      value: search?.duration ? [search?.duration] : [],
      valueKey: "duration",
    });

    handleInputChange({
      value: search?.date ?? [],
      valueKey: "date",
    });
  }, [location]);

  useEffect(() => {
    (async () => {
      await initLastDate();
    })();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{t("start your search")}</h1>

        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.searchInputContainer}>
            <SearchInput
              changes={chosenLocations}
              value={inputs.country.value}
              valueKey="country"
              displayKey="name"
              results={locations.results}
              placeholder={
                destination_input_placeholder || t("Type for ships, countries, companies")
              }
              errorMessage={inputs.country.errorMessage}
              isLoading={locations.isLoading}
              onChange={handleInputChange}
              onChosenChange={handleChosenLocationsChange}
              isMultiple
              showError
            />
          </div>

          <div className={styles.selectDateContainer}>
            <DatePicker
              placeholder={t("Select date")}
              defaultDate={inputs.date.value}
              valueKey="date"
              returnFormat="YYYY-MM"
              minDate={dayjs().startOf("month")}
              maxDate={dayjs(dayjs(lastDate).toDate()).startOf("month")}
              mode="range"
              errorMessage={inputs.date.errorMessage}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.durationContainer}>
            <CustomSelect
              value={inputs.duration.value[0] ?? ""}
              valueKey="duration"
              placeholder={t("Duration")}
              errorMessage={inputs.duration.errorMessage}
              items={DURATION_ITEMS}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.searchButtonContainer}>
            <Button label={t("search")} type="submit" className={styles.button} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Search;
