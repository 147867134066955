import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import SessionGuard from "@componentsV1/guards/SessionGuard";
import { useTypedSelector } from "@store/store";

function RootGuard({ children }: React.PropsWithChildren) {
  const isAppInitialized = useTypedSelector(
    (state) => state.session.isInitialized && state.environment.isInitialized,
  );

  return (
    <SessionGuard>
      <LoadingContainer isLoading={!isAppInitialized}>{children}</LoadingContainer>
    </SessionGuard>
  );
}

export default RootGuard;
