import { createSlice } from "@reduxjs/toolkit";

import { InitLocalesResponse, LocalesAPI } from "@store/services/LocalesService";

const initialState: InitLocalesResponse = {
  languages: [],
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setLanguages(state, action) {
      state.languages = action.payload.languages;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(LocalesAPI.endpoints.initLocales.matchFulfilled, (state, action) => {
      state.languages = action.payload.languages;
    });
  },
});

export const { setLanguages } = toastSlice.actions;

export default toastSlice.reducer;
