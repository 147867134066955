import classNames from "classnames";
import { useTranslation } from "react-i18next";

import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import Button from "@componentsV1/shared/Button";
import { Cabin } from "@store/services/CabinSelectService";
import { Room } from "@store/slices/roomsSlice";
import { useTypedSelector } from "@store/store";

import styles from "./index.module.scss";

interface ICabinsProps {
  cabins?: Cabin[];
  room?: Room;
  handleChoseCabin: (room: Room) => void;
}

function Cabins({ cabins, room, handleChoseCabin }: ICabinsProps) {
  const { t } = useTranslation();

  const { isCabinsLoading } = useTypedSelector((state) => state.rooms);

  const handleCabinClick = (cabin: Cabin, isActive: boolean) => () => {
    const updatedStateroom = structuredClone(room ?? {});

    if (isActive) {
      delete updatedStateroom?.cabin;
    } else {
      const element = document.getElementById("cabins");

      updatedStateroom.cabin = cabin;

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }

    handleChoseCabin(updatedStateroom);
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("select a stateroom")}</p>

      <div className={styles.cabins}>
        <LoadingContainer isLoading={isCabinsLoading || !cabins || !room}>
          {cabins?.length ? (
            cabins?.map((el) => {
              const isActive = el.number === room?.cabin?.number;

              return (
                <div
                  key={el.number}
                  className={classNames(styles.cabin, {
                    [styles.cabin_active]: isActive,
                  })}
                >
                  <div className={styles.left}>
                    <p className={styles.name}>
                      {t("stateroom")} {el.number}
                    </p>

                    <div className={styles.row}>
                      <div className={styles.cell}>
                        <span className={styles.cellKey}>{t("max occupancy:")}</span>

                        <span className={styles.cellValue}>{el.maximum_occupancy}</span>
                      </div>

                      <div className={styles.cell}>
                        <span className={styles.cellKey}>{t("size")}</span>
                        <span className={styles.cellValue}>{el.size}</span>
                      </div>
                    </div>
                  </div>

                  <Button
                    className={styles.button}
                    label={isActive ? t("de-select") : t("select")}
                    variant={isActive ? "secondary" : "primary"}
                    onClick={handleCabinClick(el, isActive)}
                  />
                </div>
              );
            })
          ) : (
            <p>{t("No data to display")}</p>
          )}
        </LoadingContainer>
      </div>
    </div>
  );
}

Cabins.defaultProps = {
  cabins: [],
  room: {},
};

export default Cabins;
