import classNames from "classnames";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import SvgIcon from "@componentsV1/shared/SvgIcon";
import { useLazyGetPricingQuery } from "@store/services/CabinSelectService";
import { Room } from "@store/slices/roomsSlice";
import { Grade, Prices } from "@store/slices/searchSlice";
import { useTypedSelector } from "@store/store";
import { currencyToFormat } from "@utils/helpers/currency";

import styles from "./index.module.scss";

interface IStateroomsListProps {
  room?: Room;
  handleChoseStateroom: (stateroom: Room) => void;
  handleShowImagesModal: (images: string[]) => void;
  handleShowStateroomsModal: (grade: Grade) => void;
}

const getPrices = (prices?: Prices) => {
  const defaultSingle = +(prices?.single ?? 0);
  const defaultDouble = +(prices?.standard ?? 0);
  const defaultTriple = +(prices?.third ?? prices?.extra ?? 0);
  const defaultQuadruple = +(prices?.fourth ?? prices?.extra ?? 0);

  const single = defaultSingle > 0 ? Math.floor(+defaultSingle) : 0;
  const double = defaultDouble > 0 ? Math.floor(+defaultDouble) : 0;

  const triple = defaultTriple > 0 ? Math.floor((+defaultDouble * 2 + +defaultTriple) / 3) : 0;

  const quadruple =
    defaultQuadruple > 0
      ? Math.floor((+defaultDouble * 2 + +defaultTriple + +defaultQuadruple) / 4)
      : 0;

  return { single, double, triple, quadruple };
};

function StateroomsList({
  room,
  handleChoseStateroom,
  handleShowImagesModal,
  handleShowStateroomsModal,
}: IStateroomsListProps) {
  const { t } = useTranslation();

  const { cruise } = useTypedSelector((state) => state.search);
  const { subtract_gft, show_pricing_breakdown } = useTypedSelector((state) => state.environment);

  const [getPricing] = useLazyGetPricingQuery();

  const market = useMemo(() => {
    if (room?.marketType && cruise?.markets) {
      return cruise?.markets[room?.marketType as "suite" | "inside" | "balcony" | "outside"];
    }

    return undefined;
  }, [room?.marketType]);

  const gradesByMarketType: Grade[] | undefined = useMemo(() => {
    const farePricesCodes = room?.fare?.prices.map((price) => price.grade);

    const grades = cruise?.ship.grades.filter(
      (grade) => grade.meta_category === room?.marketType && farePricesCodes?.includes(grade.code),
    );

    const gradesWithPrice = grades?.map((grade) => {
      const clonedGrade = structuredClone(grade);

      const fareGrade = room?.fare?.prices.find((el) => el.grade === grade.code);

      const { single, double, triple, quadruple } = getPrices(fareGrade);

      clonedGrade.single = single;
      clonedGrade.double = double;
      clonedGrade.triple = triple;
      clonedGrade.quadruple = quadruple;

      return clonedGrade;
    });

    if (gradesWithPrice) {
      const sortPrice = gradesWithPrice.sort((a, b) => {
        const priceA = a.double === 0 ? a.single : a.double;
        const priceB = b.double === 0 ? b.single : b.double;

        return Number(priceA) - Number(priceB);
      });

      return sortPrice;
    }

    return gradesWithPrice;
  }, [room]);

  const handleImageClick = (images: string[]) => () => {
    handleShowImagesModal(images);
  };

  const handleStateroomsDetailsClick = (grade: Grade) => () => {
    handleShowStateroomsModal(grade);
  };

  const handleUpdateChosenGrades = (grade: Grade, guestsNumber: number) => () => {
    const updatedStateroom = structuredClone({
      ...room,
      grade,
      guestsNumber,
    });

    setTimeout(() => {
      if (show_pricing_breakdown) {
        const element = document.getElementById("continue-2");

        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      } else {
        const element = document.getElementById("continue");

        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    }, 250);

    handleChoseStateroom(updatedStateroom);
  };

  const requestPrice = async () => {
    const clonedRoom: Room = structuredClone(room ?? {});
    const guestsDobQs: string[] = [];

    for (let i = 1; i <= (clonedRoom?.guestsNumber ?? 0); i += 1) {
      guestsDobQs.push(`guest_dob_${i}=1979-04-22`);
    }

    const payload = {
      sailing_code: cruise?.code ?? "",
      source: cruise?.source ?? "",
      rate_code: clonedRoom?.fare?.rate_code ?? "",
      grade_code: clonedRoom?.grade?.code ?? "",
      cabin_number: "GTY",
      guest_count: `${clonedRoom?.guestsNumber ?? 1}`,
      guests_dobs: guestsDobQs.join("&"),
    };

    await getPricing(payload);
  };

  useEffect(() => {
    if (show_pricing_breakdown && room?.guestsNumber !== undefined) {
      requestPrice();
    }
  }, [room]);

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <p className={styles.title} id="pricing">
          {t("pricing per person")}
        </p>
        <div className={styles.grades}>
          {gradesByMarketType?.length ? (
            gradesByMarketType?.map((grade) => {
              const { single, double, triple, quadruple } = grade;

              const pricesByGuestNumber: Record<number, number> = {
                1: single,
                2: double,
                3: triple,
                4: quadruple,
              };

              return (
                <div key={grade.code} className={styles.grade}>
                  <div className={styles.gradeHeader}>
                    {grade.images.length ? (
                      <div
                        role="button"
                        tabIndex={0}
                        className={styles.imageContainer}
                        onClick={handleImageClick(grade.images)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            handleImageClick(grade.images);
                          }
                        }}
                      >
                        <div className={styles.loupe}>
                          <SvgIcon type="loupe" />
                        </div>

                        <img src={grade.images[0]} className={styles.image} alt="grade" />
                      </div>
                    ) : (
                      <div className={styles.blankImage}>
                        <p>{t("no pictures")}</p>
                      </div>
                    )}

                    {[1, 2, 3, 4].map((guestsNumber) => {
                      const guestTitle = guestsNumber === 1 ? t("guest") : t("guests");

                      const price =
                        guestsNumber > grade.maximum_occupancy ||
                        guestsNumber < grade.minimum_occupancy ||
                        pricesByGuestNumber[guestsNumber] <= 0
                          ? "$N/A"
                          : currencyToFormat(
                              pricesByGuestNumber[guestsNumber] -
                                +(subtract_gft ? market?.gft ?? "0" : 0),
                              cruise?.pricing?.[0]?.currency || "USD",
                            ).slice(0, -3);

                      const isDisabled = price === "$N/A";

                      const isActive =
                        room?.grade?.code === grade.code && room?.guestsNumber === guestsNumber;

                      return (
                        <div
                          key={guestsNumber}
                          role="button"
                          tabIndex={0}
                          className={classNames(styles.market, styles.gradePrice, {
                            [styles.market_active]: isActive,
                            [styles.market_disabled]: isDisabled,
                          })}
                          onClick={
                            !isDisabled ? handleUpdateChosenGrades(grade, guestsNumber) : () => null
                          }
                          onKeyPress={(e) => {
                            if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
                              handleUpdateChosenGrades(grade, guestsNumber);
                            }
                          }}
                        >
                          <p className={styles.marketName}>{`${guestsNumber} ${guestTitle}`}</p>

                          <p className={styles.price}>{`${price ?? 0}${isDisabled ? "" : "pp"}`}</p>
                        </div>
                      );
                    })}
                  </div>

                  <div className={styles.gradeBody}>
                    <p className={styles.gradeName}>{grade.name}</p>
                    <p className={styles.gradeSize}>{grade.size}</p>

                    <p className={styles.gradeDescription}>
                      {grade.descriptions?.[0].description ?? ""}
                    </p>
                  </div>

                  <div className={styles.gradeFooter}>
                    <button
                      type="button"
                      className={styles.findOutMoreButton}
                      onClick={handleStateroomsDetailsClick(grade)}
                    >
                      {t("find out more")}
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <p>no data to display</p>
          )}
        </div>
      </div>
    </div>
  );
}

StateroomsList.defaultProps = {
  room: {},
};

export default StateroomsList;
