import { sessionApi } from "@store/services/apiSingleton";

export interface IInitApiTokenResponse {
  token: string;
}

export interface IInitApiTokenRequest {
  username: string;
  password: string;
}

export const SessionApi = sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    initApiToken: builder.query<IInitApiTokenResponse, IInitApiTokenRequest>({
      query: (body) => ({
        url: "/api/token/",
        method: "POST",
        body,
      }),
    }),
  }),
});
