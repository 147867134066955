function getMonthsList() {
  const monthsList = [];

  for (let i = 0; i < 12; i += 1) {
    const month = String(i + 1).padStart(2, "0");

    monthsList.push(month);
  }

  return monthsList;
}

export default getMonthsList;
