import { nanoid } from "@reduxjs/toolkit";
import { MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import BookingDetails from "@componentsV1/base/BookingDetails";
import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import RoomForms from "@componentsV1/pages/Passengers/molecules/RoomForms";
import SubmitButton from "@componentsV1/pages/Passengers/molecules/SubmitButton";
import Button from "@componentsV1/shared/Button";
import { GuestFields, updateGuest } from "@store/slices/guestsSlice";
import { showModal } from "@store/slices/modalSlice";
import { setPricing } from "@store/slices/pricingSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import MODAL from "@utils/constants/modal";

import styles from "./index.module.scss";

type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
      errors: Partial<GuestFields>;
      expand: () => void;
      setErrors: (errors: Partial<Record<keyof GuestFields, string>>) => void;
      scrollIntoView: () => void;
    }
  >
>;

type SubmitButtonPayload = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
    }
  >
>;

function Passengers() {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const { isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);

  const { check_email_uniqueness } = useTypedSelector((state) => state.environment);

  const submitButtonRef: MutableRefObject<{
    submit: (guests: SubmitButtonPayload) => void;
  }> = useRef({
    submit: () => null,
  });

  const roomsFormsRefs: Record<
    number,
    MutableRefObject<{
      validateForms: () => ValidateFormsOutput;
    }>
  > = { 1: useRef({ validateForms: () => ({}) }) };

  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  const flattenForms = () => {
    let validationResult: ValidateFormsOutput = {};

    Object.entries(roomsFormsRefs).forEach(([, room]) => {
      const roomFormsValidationResult: ValidateFormsOutput = room.current.validateForms();

      validationResult = {
        ...validationResult,
        ...(roomFormsValidationResult ?? {}),
      };
    });

    if (check_email_uniqueness) {
      const emails: string[] = [];

      Object.entries(validationResult).forEach(([roomKey, room]) => {
        const roomNumber = Number(roomKey);

        Object.entries(room).forEach(([guestKey, { inputs, errors, setErrors }]) => {
          const guestIndex = Number(guestKey);
          const isEmailExistsAndValid = !errors?.email && inputs.email;
          const isEmailUnique = !emails.includes(inputs.email);

          if (isEmailExistsAndValid && !isEmailUnique) {
            setErrors({ email: t("Email should be unique") });

            if (!errors) {
              validationResult[roomNumber][guestIndex].errors = {};
            }

            validationResult[roomNumber][guestIndex].errors.email = t("Email should be unique");
          }

          emails.push(inputs.email);
        });
      });
    }

    return validationResult;
  };

  const handleValidate = () => {
    const validationResult = flattenForms();

    let isFormsValid = true;

    Object.entries(validationResult).forEach(([roomKey, room]) => {
      const roomNumber = Number(roomKey);

      Object.entries(room).forEach(([formKey, form]) => {
        const isFormValid = !form.errors;

        if (!isFormValid && isFormsValid) {
          requestAnimationFrame(() => {
            form.expand();

            setTimeout(() => {
              form.scrollIntoView();
            }, 500);
          });

          isFormsValid = false;
        }

        if (isFormValid) {
          dispatch(
            updateGuest({
              stateroomNumber: roomNumber,
              guestNumber: Number(formKey),
              guestFields: form.inputs,
            }),
          );
        }
      });
    });

    if (isFormsValid) {
      submitButtonRef.current.submit(validationResult);
    }
  };

  useEffect(() => {
    dispatch(setPricing(rooms));
  }, [rooms]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={isCruiseLoading || !rooms}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1 className={styles.title}>{t("who’s travelling")}</h1>

            <div className={styles.passengers}>
              {Object.entries(rooms ?? {}).map(([stateroomKey, stateroom], stateroomIndex) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const ref = useRef({ validateForms: () => ({}) });
                const description = stateroom?.grade?.descriptions?.[0]?.description;

                roomsFormsRefs[+stateroomKey] = ref;

                return (
                  <div key={nanoid()} className={styles.passenger}>
                    <div className={styles.stateroomInfo}>
                      <p className={styles.subtitle}>stateroom {stateroomIndex + 1}</p>

                      <p className={styles.name}>
                        {stateroom?.grade?.name}
                        <span>{description ? ` - ${description}` : ""}</span>
                      </p>

                      <p className={styles.name}>{stateroom?.guestsNumber} guests</p>
                      <p className={styles.name}>stateroom {stateroom?.cabin?.number}</p>
                    </div>

                    <RoomForms
                      ref={ref}
                      isIncludesLead={stateroomIndex === 0}
                      roomNumber={+stateroomKey}
                      guestsAmount={stateroom?.guestsNumber ?? 1}
                    />
                  </div>
                );
              })}
            </div>

            <SubmitButton ref={submitButtonRef} onClick={handleValidate} />
          </div>

          <div className={styles.right}>
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowItineraryModal}
            />

            <BookingDetails />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Passengers;
