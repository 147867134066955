import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";

const Lato = ({
  weight = ["100", "300", "400", "700", "900"],
  style = ["normal"],
  display = "swap",
}: {
  subsets?: string[];
  weight?: string[];
  style?: string[];
  display?: string;
}) => {
  return {
    fontFamily: "Lato, sans-serif",
    fontWeight: weight.join(", "),
    fontStyle: style.join(", "),
    fontDisplay: display,
  };
};

export const lato = Lato({
  subsets: ["latin"],
  weight: ["100", "300", "400", "700", "900"],
  style: ["normal", "italic"],
  display: "swap",
});
