import { ComponentsOverrides, Theme } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    "bg-primary": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
    red: true;
  }

  interface ButtonPropsVariantOverrides {
    containedPrimary: true;
    containedSecondary: true;
    outlinedPrimary: true;
  }
}

interface IMuiButton {
  styleOverrides: ComponentsOverrides<Theme>["MuiButton"];
}

export const MuiButton: IMuiButton = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: theme.customShape.smallRadius,
      textTransform: "none",
      boxShadow: "none",
      whiteSpace: "nowrap",
      "&:hover": {
        boxShadow: "none",
      },
    }),
    sizeSmall: ({ theme }) => ({
      height: theme.spacing(3),
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      fontSize: theme.typography.pxToRem(12),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(14),
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.pxToRem(16),
      },
    }),
    sizeMedium: ({ theme }) => ({
      height: theme.spacing(8.25),
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      fontSize: theme.typography.pxToRem(18),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(20),
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.pxToRem(24),
      },
    }),
    sizeLarge: ({ theme }) => ({
      height: theme.spacing(12.5),
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
      fontSize: theme.typography.pxToRem(20),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.pxToRem(28),
      },
    }),
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette["bg-button"].main,
      color: theme.palette["text-light"].main,
      "&:hover": {
        backgroundColor: theme.palette["bg-secondary"].main,
      },
      "&:disabled": {
        color: theme.palette["text-light"].main,
        backgroundColor: theme.palette["bg-dark"].main,
        cursor: "not-allowed",
        opacity: 0.8,
      },
    }),
    containedSecondary: {
      backgroundColor: "#000000",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#111111",
      },
      "&:disabled": {
        color: "#FFFFFF",
        backgroundColor: "##333333",
        cursor: "not-allowed",
      },
    },
    outlinedPrimary: {
      border: "2px solid #3C82BB",
      color: "#3C82BB",
      "&:hover": {
        color: "#FFFFFF",
        backgroundColor: "#3C82BB",
      },
    },
  },
};
