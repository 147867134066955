import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { SORT_TYPE } from "@store/slices/searchFilterSlice";
import { ICruise, Market } from "@store/slices/searchSlice";
import { RootState, store } from "@store/store";

export const selectSearch = (state: RootState) => state.search;

export const selectSearchFilter = (state: RootState) => state.searchFilter;

function sortByName(array: ICruise[], ascending = true) {
  return array.slice().sort((a, b) => {
    const order = ascending ? 1 : -1;

    return order * a.ship.name.localeCompare(b.cruise.name);
  });
}

function sortByDate(array: ICruise[], ascending = true) {
  return array.slice().sort((a, b) => {
    const order = ascending ? 1 : -1;

    return order * (dayjs(a.embark).toDate().getTime() - dayjs(b.embark).toDate().getTime());
  });
}

export const getPriceByCruise = (cruise: ICruise) => {
  const prices = Object.values(cruise.markets ?? {})
    .filter(
      (market): market is Market =>
        typeof market === "object" && market !== null && "price" in market,
    )
    .map((market) =>
      parseFloat(
        `${Number(market?.price ?? "0") - Number(store.getState().environment.subtract_gft ? market?.gft ?? "0" : "0")}`,
      ),
    )
    .filter((price) => !Number.isNaN(price) && price > 0);

  return prices.length > 0 ? Math.min(...prices) : 0;
};

function sortByPrice(array: ICruise[], ascending = true) {
  return array.slice().sort((a, b) => {
    const order = ascending ? 1 : -1;

    return order * (getPriceByCruise(a) - getPriceByCruise(b));
  });
}

export const selectSearchResultsByFilter = createSelector(
  [selectSearch, selectSearchFilter],
  (search, searchFilter) => {
    if (search && searchFilter) {
      const mutableSearch = { ...search };

      if (mutableSearch.results) {
        if (searchFilter.price.length > 0) {
          mutableSearch.results = mutableSearch.results.filter(
            (result: ICruise) =>
              getPriceByCruise(result) >= searchFilter.price[0] &&
              getPriceByCruise(result) <= searchFilter.price[1],
          );
        }

        if (searchFilter.sortType === SORT_TYPE.NANE) {
          mutableSearch.results = sortByName(mutableSearch.results, searchFilter.sortName);
        }

        if (searchFilter.sortType === SORT_TYPE.DATE) {
          mutableSearch.results = sortByDate(mutableSearch.results, searchFilter.sortDate);
        }

        if (searchFilter.sortType === SORT_TYPE.PRICE) {
          mutableSearch.results = sortByPrice(mutableSearch.results, searchFilter.sortPrice);
        }

        return mutableSearch;
      }
    }

    return search;
  },
);

export const selectSearchCalculatePrice = createSelector([selectSearch], (search) => {
  if (search.results) {
    const minValueObject = search.results.reduce(
      (min, obj) => (getPriceByCruise(obj) < getPriceByCruise(min) ? obj : min),
      search.results[0],
    );

    const maxValueObject = search.results.reduce(
      (max, obj) => (getPriceByCruise(obj) > getPriceByCruise(max) ? obj : max),
      search.results[0],
    );

    return [getPriceByCruise(minValueObject), getPriceByCruise(maxValueObject)];
  }

  return [0, 0];
});
