import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DEFAULT_GUEST_FIELDS } from "@utils/constants/defaultGuestFields";

export interface GuestFields {
  address1: string;
  address2: string;
  city: string;
  country: string;
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
  email: string;
  given_name: string;
  last_name: string;
  gender: string;
  lead_passenger: boolean;
  nationality: string;
  phone: string;
  state: string;
  title: string;
  zip_code: string;
  passport_number: string;
  issue_date: string;
  expiry_date: string;
  place_of_birth: string;
  country_of_issue: string;
  request: string;
  isExpanded?: boolean;
}

export type GuestsFields = Record<number, Record<number, GuestFields>>;

export interface GuestsState {
  guests: GuestsFields;
}

const initialState: GuestsState = {
  guests: {
    1: {
      1: DEFAULT_GUEST_FIELDS,
    },
  },
};

const guestsSlice = createSlice({
  name: "guests",
  initialState,
  reducers: {
    clearGuest(state: GuestsState) {
      state.guests = {
        1: {
          1: DEFAULT_GUEST_FIELDS,
        },
      };
    },
    updateGuestsState(state: GuestsState, action: PayloadAction<GuestsFields>) {
      state.guests = action.payload;
    },
    updateGuest(
      state: GuestsState,
      action: PayloadAction<{
        stateroomNumber: number;
        guestNumber: number;
        guestFields: GuestFields;
      }>,
    ) {
      const { stateroomNumber, guestNumber, guestFields } = action.payload;
      const isRoomExists = !!state.guests[stateroomNumber];

      if (!isRoomExists) {
        state.guests[stateroomNumber] = {
          [guestNumber]: guestFields,
        };
      }

      if (isRoomExists) {
        state.guests[stateroomNumber][guestNumber] = guestFields;
      }
    },
  },
});

export const { clearGuest, updateGuestsState, updateGuest } = guestsSlice.actions;

export default guestsSlice.reducer;
