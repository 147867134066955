import classNames from "classnames";
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";

import SvgIcon from "@componentsV1/shared/SvgIcon";

import styles from "./index.module.scss";

interface ICollapsibleProps {
  expanded?: boolean;
  onExpand?: (value: boolean) => void;
  renderHeader: () => React.ReactNode;
  headerStyle?: string;
  bodyStyle?: string;
  className?: string;
}

const Collapsible = forwardRef(function Collapsible(
  {
    expanded,
    onExpand,
    renderHeader,
    children,
    headerStyle,
    bodyStyle,
    className,
  }: React.PropsWithChildren<ICollapsibleProps>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [internalExpanded, setInternalExpanded] = useState(!!expanded);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const bodyRef = useRef<HTMLDivElement>(null);

  const handleExpand = (value: boolean) => () => {
    if (onExpand) {
      onExpand(value);
    } else {
      setInternalExpanded(value);
    }

    setTimeout(() => {
      requestAnimationFrame(() => {
        if (bodyRef.current) {
          setContentHeight(bodyRef.current.scrollHeight ?? 0);
        }
      });
    }, 250);
  };

  useEffect(() => {
    if (internalExpanded) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          if (bodyRef.current) {
            setContentHeight(bodyRef.current.scrollHeight ?? 0);
          }
        });
      }, 250);
    }
  });

  useEffect(() => {
    if (expanded !== internalExpanded) {
      setInternalExpanded(!!expanded);
    }
  }, [expanded]);

  return (
    <div ref={ref} className={classNames(styles.container, className)}>
      <div
        className={classNames(styles.header, headerStyle)}
        onClick={handleExpand(!internalExpanded)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleExpand(!internalExpanded)();
          }
        }}
        role="button"
        tabIndex={0}
        aria-expanded={internalExpanded}
      >
        {renderHeader()}

        <SvgIcon type={internalExpanded ? "minus" : "plus"} />
      </div>

      <div
        ref={bodyRef}
        className={classNames(styles.body, bodyStyle)}
        style={{
          maxHeight: internalExpanded ? `${contentHeight}px` : "0",
          padding: internalExpanded ? 20 : "0 20px",
        }}
      >
        {children}
      </div>
    </div>
  );
});

export default Collapsible;
