import { nanoid } from "@reduxjs/toolkit";
import Carousel from "react-bootstrap/Carousel";

import styles from "./index.module.scss";
import "./index.css";

type IImageItem = string;

interface ICarouselProps {
  items: IImageItem[];
  className?: string;
}

function CustomCarousel({ items, className }: ICarouselProps) {
  return (
    <Carousel className={className} indicators={false}>
      {items.map((slide) => {
        return (
          <Carousel.Item key={nanoid()}>
            <img src={slide} className={styles.slide} loading="lazy" alt="slide" />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

CustomCarousel.defaultProps = {
  className: "",
};

export default CustomCarousel;
