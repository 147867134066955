import classNames from "classnames";
import { Link, To } from "react-router-dom";

import styles from "./index.module.scss";

interface ICustomLinkProps {
  to: To;
  isPrimary?: boolean;
  isDisabled?: boolean;
  className?: string;
}

function DisabledLink({
  isPrimary,
  className,
  children,
}: React.PropsWithChildren<Pick<ICustomLinkProps, "isPrimary" | "className">>) {
  return (
    <span
      className={classNames(styles.container, className, {
        [styles.primary]: isPrimary,
      })}
    >
      {children}
    </span>
  );
}

function CustomLink({
  to,
  isPrimary,
  isDisabled,
  className,
  children,
}: React.PropsWithChildren<ICustomLinkProps>) {
  if (isDisabled) {
    return (
      <DisabledLink isPrimary={isPrimary} className={className}>
        {children}
      </DisabledLink>
    );
  }

  return (
    <Link
      to={to}
      className={classNames(styles.container, className, {
        [styles.primary]: isPrimary,
      })}
    >
      {children}
    </Link>
  );
}

CustomLink.defaultProps = {
  className: "",
  isPrimary: false,
  isDisabled: false,
};

export default CustomLink;
