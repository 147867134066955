import { Box, Typography } from "@mui/material";

import { DeckPlansAccordion } from "./DeckPlansAccordion";

export function ShipDeckPlans() {
  return (
    <Box>
      <Typography component="p" variant="sectionHeader" color="text-primary" mb={2}>
        Deck Plans
      </Typography>

      <DeckPlansAccordion />
    </Box>
  );
}
