import { Stack } from "@mui/material";

import { ShipCruiseLine } from "./ShipCruiseLine";
import { ShipDeckPlans } from "./ShipDeckPlans";
import { ShipFacilities } from "./ShipFacilities";
import { ShipOverview } from "./ShipOverview";
import { ShipStats } from "./ShipStats";

export const Ship = () => {
  return (
    <Stack mt={{ xs: 4, md: 8 }} spacing={4}>
      <ShipOverview />
      <ShipStats />
      <ShipFacilities />
      <ShipDeckPlans />
      <ShipCruiseLine />
    </Stack>
  );
};
