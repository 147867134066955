import Flag from "react-world-flags";

import CustomLink from "@componentsV1/shared/CustomLink";
import { useTypedSelector } from "@store/store";
import formatPhoneNumber from "@utils/helpers/formatPhoneNumber";

import styles from "./index.module.scss";

function Header() {
  const {
    header_telephone_number,
    header_show_currency_code,
    header_show_language_picture,
    header_logo_picture,
    header_logo_height,
  } = useTypedSelector((state) => state.environment);

  return (
    <header className={styles.container}>
      {header_logo_picture && (
        <CustomLink to="/">
          <img
            src={header_logo_picture}
            style={{ height: `${header_logo_height || 100}px` }}
            alt="logo"
          />
        </CustomLink>
      )}

      <div className={styles.flex}>
        <div className={styles.block}>
          {header_show_currency_code && (
            <p className={styles.currency}>{header_show_currency_code}</p>
          )}

          {header_show_language_picture && (
            <Flag code={header_show_language_picture} style={{ width: "30px", height: "auto" }} />
          )}
        </div>

        {header_telephone_number && (
          <a className={styles.telephone} href={`tel:${header_telephone_number}`}>
            {formatPhoneNumber(header_telephone_number)}
          </a>
        )}
      </div>
    </header>
  );
}

export default Header;
