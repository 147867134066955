import classNames from "classnames";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Checkbox from "@componentsV1/shared/Checkbox";

import styles from "./index.module.scss";

interface IPriceTypeProps {
  full: number;
  deposit: number;
  currency: string;
  onPriceTypeChange: (priceType: "full" | "deposit") => void;
}

function PriceType({ full, deposit, currency, onPriceTypeChange }: IPriceTypeProps) {
  const { t } = useTranslation();

  const [priceType, setPriceType] = useState<"full" | "deposit">("full");

  const { formattedFull, formattedDeposit } = useMemo(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      maximumFractionDigits: 2,
    });

    return {
      formattedFull: formatter.format(full),
      formattedDeposit: deposit === 0 || full === 0 ? "N/A" : formatter.format(deposit),
    };
  }, [full, deposit, currency]);

  const handlePriceTypeChange = (type: typeof priceType) => () => {
    if (type === "deposit" && formattedDeposit === "N/A") {
      return;
    }

    setPriceType(type);
    onPriceTypeChange(type);
  };

  return (
    <div className={styles.container}>
      <div
        role="button"
        tabIndex={0}
        onClick={handlePriceTypeChange("full")}
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handlePriceTypeChange("full");
          }
        }}
        className={classNames(styles.item, {
          [styles.item_active]: priceType === "full",
        })}
      >
        <div className={styles.priceInfo}>
          <p className={styles.title}>{t("full amount")}</p>
          <p className={styles.price}>{formattedFull}</p>
        </div>

        <div className={styles.checkboxContainer}>
          <Checkbox value={priceType === "full"} onChange={handlePriceTypeChange("full")} />
        </div>
      </div>

      <div
        role="button"
        tabIndex={0}
        onClick={handlePriceTypeChange("deposit")}
        onKeyPress={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handlePriceTypeChange("deposit");
          }
        }}
        className={classNames(styles.item, {
          [styles.item_active]: priceType === "deposit",
          [styles.item_disabled]: formattedDeposit === "N/A",
        })}
      >
        <div className={styles.priceInfo}>
          <p className={styles.title}>{t("deposit payment")}</p>
          <p className={styles.price}>{formattedDeposit}</p>
        </div>

        <div className={styles.checkboxContainer}>
          <Checkbox value={priceType === "deposit"} onChange={handlePriceTypeChange("deposit")} />
        </div>
      </div>
    </div>
  );
}

export default PriceType;
