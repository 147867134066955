import classNames from "classnames";

import styles from "./index.module.scss";

interface IToastProps {
  isVisible: boolean;
  message: string;
  type: string;
}

function Toast({ isVisible, message, type }: IToastProps) {
  return (
    <div
      className={classNames(styles.container, styles[`container_${type}`], {
        [styles.container_visible]: isVisible,
      })}
    >
      <span className={styles.message}>{message}</span>
    </div>
  );
}

export default Toast;
