import LoadingIndicator from "@componentsV1/shared/LoadingIndicator";

import styles from "./index.module.scss";

interface ILoadingContainerProps {
  isLoading: boolean;
}

function LoadingContainer({
  isLoading,
  children,
}: React.PropsWithChildren<ILoadingContainerProps>) {
  if (isLoading) {
    return (
      <div className={styles.container}>
        <LoadingIndicator />
      </div>
    );
  }

  return <>{children}</>;
}

export default LoadingContainer;
