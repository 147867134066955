import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { initApiToken, initPosSession } from "@store/actions/sessionActions";

export interface ISessionState {
  token: string;
  sessionKey: string;
  isLoading: boolean;
  isInitialized: boolean;
}

const initialState: ISessionState = {
  token: "",
  sessionKey: "",
  isLoading: false,
  isInitialized: false,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    updateToken(state: ISessionState, action: PayloadAction<string>) {
      state.token = action.payload;
      state.isInitialized = true;
    },
    updateSessionKey(state: ISessionState, action: PayloadAction<string>) {
      state.sessionKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initApiToken.pending, (state) => {
        state.isLoading = true;
        state.isInitialized = false;
      })
      .addCase(initPosSession.pending, (state) => {
        state.isLoading = true;
        state.isInitialized = false;
      })
      .addCase(initApiToken.fulfilled, (state, action) => {
        state.token = action.payload?.token ?? "";
      })
      .addCase(initPosSession.fulfilled, (state, action) => {
        state.sessionKey = action.payload?.key ?? "";
        state.isLoading = false;
        state.isInitialized = true;
      })
      .addCase(initApiToken.rejected, (state) => {
        state.isInitialized = true;
      })
      .addCase(initPosSession.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { updateToken, updateSessionKey } = sessionSlice.actions;

export default sessionSlice.reducer;
