import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import ImagesCarouselModal from "@componentsV1/containers/ModalContainer/modals/ImagesCarouselModal";
import ItineraryModal from "@componentsV1/containers/ModalContainer/modals/ItineraryModal";
import StateroomsModal from "@componentsV1/containers/ModalContainer/modals/StateroomsModal";
import SvgIcon from "@componentsV1/shared/SvgIcon";
import useOnClickOutside from "@hooks/useClickOutside";
import { hideModal } from "@store/slices/modalSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import MODAL from "@utils/constants/modal";

import styles from "./index.module.scss";

const MODALS_BY_TYPE = {
  [MODAL.MODAL_TYPES.ITINERARY]: ItineraryModal,
  [MODAL.MODAL_TYPES.IMAGES]: ImagesCarouselModal,
  [MODAL.MODAL_TYPES.STATEROOMS]: StateroomsModal,
};

function Modal({ type }: { type: string }) {
  const Component = MODALS_BY_TYPE[type] ?? "div";

  return <Component />;
}

function ModalContainer({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch();

  const { type } = useTypedSelector((state) => state.modal);

  const [internalType, setInternalType] = useState(type);

  const outsideRef = useRef(null);

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    setTimeout(
      () => {
        setInternalType(type);
      },
      type ? 0 : 300,
    );
  }, [type]);

  useOnClickOutside(outsideRef, handleCloseModal);

  return (
    <div
      className={classNames(styles.container, {
        [styles.container_active]: internalType,
      })}
    >
      {children}
      <div
        className={classNames(styles.modal, {
          [styles.modal_active]: internalType,
        })}
      >
        <div
          ref={outsideRef}
          className={classNames(styles.content, {
            [styles.content_active]: type,
          })}
        >
          <div className={styles.header}>
            <span className={styles.headerTitle}>{internalType}</span>

            <SvgIcon type="plus" className={styles.closeIcon} onClick={handleCloseModal} />
          </div>

          <Modal type={internalType} />
        </div>
      </div>
    </div>
  );
}

export default ModalContainer;
