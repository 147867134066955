export const validateCreditCard = (cardNumber) => {
  const cardWithoutSpaces = cardNumber.replace(/\s/g, "");

  const isValidLength = cardWithoutSpaces.length === 15 || cardWithoutSpaces.length === 16;

  const isConvertibleToNumber = !Number.isNaN(Number(cardWithoutSpaces));

  if (!isValidLength) {
    return "Card number length is not valid";
  }

  if (!isConvertibleToNumber) {
    return "Card number is not a number";
  }

  return true;
};

export const validateCVV = (cvv) => {
  const cvvPattern = /^[0-9]{3,4}$/;

  if (!cvvPattern.test(cvv)) {
    return "CVV format is not valid";
  }

  return true;
};

export const validateDate = (dateString) => {
  const datePattern = /^(20\d{2})-(0[1-9]|1[0-2])$/;

  if (!datePattern.test(dateString)) {
    return "Date format is not valid";
  }

  const [year, month] = dateString.split("-");

  if (parseInt(year, 10) < 2000 || parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
    return "Date components are not valid";
  }

  return true;
};
