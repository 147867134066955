import { nanoid } from "@reduxjs/toolkit";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import StateroomPriceBreakdown from "@componentsV1/pages/Rooms/molecules/StateroomPriceBreakdown";
import Collapsible from "@componentsV1/shared/Collapsible";
import { useTypedSelector } from "@store/store";

import styles from "./index.module.scss";

function CompletedStaterooms() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const currentRoomNumber = +(searchParams.get("room") ?? 0);

  const { rooms } = useTypedSelector((state) => state.rooms);

  const { show_pricing_breakdown } = useTypedSelector((state) => state.environment);

  const [isExpanded, setIsExpanded] = useState<Record<number, boolean>>({});

  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }

    return undefined;
  }, [rooms, currentRoomNumber]);

  const updateIsExpanded = (index: number) => {
    setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  useEffect(() => {
    if (room?.grade) {
      setIsExpanded(() => ({ [currentRoomNumber - 1]: true }));
    }
  }, [room?.grade]);

  return (
    <div className={styles.container}>
      {Object.values(rooms ?? {}).map((stateroom, index) => {
        return (
          <Collapsible
            expanded={isExpanded[index]}
            onExpand={() => updateIsExpanded(index)}
            key={nanoid()}
            renderHeader={() => <p className={styles.header}>{`${t("stateroom")} ${index + 1}`}</p>}
          >
            <div className={styles.body}>
              {stateroom.grade && <p className={styles.gradeName}>{stateroom.grade.name}</p>}

              <div className={styles.textBlock}>
                <span>{`${t("farename")}:`}</span>
                <span>{stateroom.fare?.rate_name ?? "N/A"}</span>
              </div>

              {show_pricing_breakdown && <StateroomPriceBreakdown roomNumber={index + 1} />}
            </div>
          </Collapsible>
        );
      })}
    </div>
  );
}

export default CompletedStaterooms;
