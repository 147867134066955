import { EventEmitter } from "events";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en/translation.json";
import esTranslations from "./locales/es/translation.json";

import { LocalesAPI } from "@store/services/LocalesService";
import { store } from "@store/store";

const localesEventEmitter = new EventEmitter();

function transformData(inputData) {
  const result = {};

  inputData.languages.forEach((item) => {
    const translation = {};

    item.translations.forEach((translate) => {
      translation[translate.original] = translate.translation;
    });
    result[item.language] = translation;
  });

  return result;
}

const LANGUAGES = {
  EN: "en",
  ES: "es",
};

const detection = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

export const detectLanguage = () => {
  const localLanguage = localStorage.getItem("APP_LANG") ?? LANGUAGES.EN;

  return localLanguage;
};

const resources = {
  [LANGUAGES.EN]: {
    translation: enTranslations,
  },
  [LANGUAGES.ES]: {
    translation: esTranslations,
  },
};

const fetchDataAndSetLanguage = async () => {
  try {
    const { data } = await store.dispatch(LocalesAPI.endpoints.initLocales.initiate());

    if (data?.languages) {
      localesEventEmitter.emit("localesChanged");
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);

    throw new Error(errorMessage);
  }
};

fetchDataAndSetLanguage();

localesEventEmitter.on("localesChanged", () => {
  const { locales } = store.getState();
  const translations = transformData(locales);

  Object.entries(translations).forEach((value) => {
    resources[value[0]] = { translation: value[1] };
  });
});

const languageDetector = {
  type: "languageDetector",
  detect: () => {
    const lng = detectLanguage();

    return lng;
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection,
    resources,
    fallbackLng: LANGUAGES.EN,
    supportedLngs: Object.values(LANGUAGES),
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
