import { nanoid } from "@reduxjs/toolkit";
import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import Cabins from "@componentsV1/pages/CabinSelect/molecules/Cabins";
import DeckPlans from "@componentsV1/pages/CabinSelect/molecules/DeckPlans";
import Button from "@componentsV1/shared/Button";
import {
  useLazyInitCabinsQuery,
  useLazyHoldCabinQuery,
  useLazyGetPricingQuery,
} from "@store/services/CabinSelectService";
import { Room, updateRoom } from "@store/slices/roomsSlice";
import { showToast } from "@store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import TOAST from "@utils/constants/toast";
import { prepareParams } from "@utils/helpers/rooms";

import styles from "./index.module.scss";

function CabinSelect() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [initRoomCabins] = useLazyInitCabinsQuery();
  const [holdRoomCabin] = useLazyHoldCabinQuery();
  const [getPricing] = useLazyGetPricingQuery();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { cruiseId } = useParams();

  const { cruise, isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms, cabins } = useTypedSelector((state) => state.rooms);

  const totalRooms = +(searchParams.get("rooms") ?? 0);

  const currentRoomNumber = useMemo(
    () => +(searchParams.get("room") ?? 0),
    [searchParams.get("room")],
  );

  const [initCabinsKey, setInitCabinsKey] = useState(nanoid());
  const [isHoldCabinLoading, setIsHoldCabinLoading] = useState(false);

  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }

    return undefined;
  }, [rooms, currentRoomNumber]);

  const preparedDecks = useMemo(() => {
    const output = cruise?.ship.decks
      .map((deck) => ({
        value: deck.code,
        label: deck.name,
      }))
      .filter((deck) => room?.grade?.decks.map((el) => el.code).includes(deck.value));

    return output ?? [];
  }, [room, cruise]);

  const canGoWorward = useMemo(() => {
    return !!room?.cabin && cabins && cabins.length !== 0 && !!room?.deck;
  }, [room, cabins]);

  const initCabins = () => {
    const guestsDobQs: string[] = [];

    for (let i = 1; i <= (room?.guestsNumber ?? 0); i += 1) {
      guestsDobQs.push(`guest_dob_${i}=1979-04-22`);
    }

    const source = cruise?.source ?? searchParams.get("source");

    initRoomCabins({
      cruiseId: cruiseId ?? "",
      rateCode: room?.fare?.rate_code ?? "",
      gradeCode: room?.grade?.code ?? "",
      guestCount: `${room?.guestsNumber ?? ""}`,
      guestsDobQs: guestsDobQs.join("&"),
      source,
    });
  };

  const initChosenData = () => {
    let isChanged = false;
    const updatedStateroom = structuredClone({ ...room });

    if (cabins?.length && !room?.cabin) {
      const [firstCabin] = cabins;

      updatedStateroom.cabin = firstCabin;
      isChanged = true;
    }

    if (cabins?.length && !room?.deck) {
      updatedStateroom.deck = cruise?.ship.decks.find(
        (deck) => deck.code === preparedDecks[0].value,
      );

      isChanged = true;
    }

    if (isChanged) {
      dispatch(updateRoom({ room: updatedStateroom, roomNumber: currentRoomNumber }));
    }
  };

  const handleChose = (incomingRoom: Room) => {
    dispatch(updateRoom({ room: incomingRoom, roomNumber: currentRoomNumber }));
  };

  const initPricing = async () => {
    setIsHoldCabinLoading(true);

    try {
      // Cloning room object.
      const clonedRoom: Room = structuredClone(room ?? {});
      const guestsDobQs: string[] = [];

      for (let i = 1; i <= (clonedRoom?.guestsNumber ?? 0); i += 1) {
        guestsDobQs.push(`guest_dob_${i}=1979-04-22`);
      }

      const payload = {
        sailing_code: cruise?.code ?? "",
        source: cruise?.source ?? "",
        rate_code: clonedRoom?.fare?.rate_code ?? "",
        grade_code: clonedRoom?.grade?.code ?? "",
        cabin_number: clonedRoom?.cabin?.number ?? "",
        guest_count: `${clonedRoom?.guestsNumber ?? 1}`,
        guests_dobs: guestsDobQs.join("&"),
      };

      const response = await getPricing(payload);

      if (response.data && clonedRoom.grade) {
        clonedRoom.grade.price = `${response.data.total_price}`;
      }

      if (response.data) {
        clonedRoom.pricing = clonedRoom.pricing ?? {};
        clonedRoom.pricing.payment_schedule = response.data.payment_schedule;
        clonedRoom.pricing.guests = response.data.guests;
        clonedRoom.pricing.total_price = response.data.total_price;
      }

      dispatch(updateRoom({ room: clonedRoom, roomNumber: currentRoomNumber }));

      return response;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);

      throw new Error(errorMessage);
    } finally {
      setIsHoldCabinLoading(false);
    }
  };

  const holdCabin = async () => {
    setIsHoldCabinLoading(true);

    try {
      if (room?.cabin?.number === "GTY") {
        return { isSuccess: true };
      }

      const response = await holdRoomCabin({
        cabin_number: room?.cabin?.number ?? "",
        grade_code: room?.grade?.code ?? "",
        rate_code: room?.fare?.rate_code ?? "",
        sailing_code: cruise?.code ?? "",
        agency: cruise?.source ?? "",
      });

      return response;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);

      throw new Error(errorMessage);
    } finally {
      setIsHoldCabinLoading(false);
    }

    return undefined;
  };

  const handleSubmit = async () => {
    const pricingResponse = await initPricing();
    const holdResponse = await holdCabin();

    const pathParam = currentRoomNumber < totalRooms ? "cabin-select" : "guests";

    const params = prepareParams(
      searchParams,
      rooms ?? {},
      currentRoomNumber,
      currentRoomNumber < totalRooms,
    );

    const isSuccess = !!pricingResponse?.isSuccess && !!holdResponse?.isSuccess;

    if (isSuccess) {
      navigate(`/search-results/${cruiseId}/${pathParam}?${params}`);
      setInitCabinsKey(nanoid());
    }

    if (!isSuccess) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Hold cabin error",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }
  };

  const getButtonLabel = () => {
    if (currentRoomNumber < totalRooms) {
      return `${t("continue to stateroom")} ${currentRoomNumber + 1}`;
    }

    return t("continue to guests");
  };

  useEffect(initChosenData, [cabins]);

  useEffect(() => {
    initCabins();
  }, [initCabinsKey]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={isCruiseLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1 className={styles.title}>{t("choose staterooms")}</h1>

            <div className={styles.stateroomInfo}>
              <p className={styles.subtitle}>
                {t("stateroom")} {currentRoomNumber}
              </p>

              <p className={styles.name}>
                {room?.grade?.name} -{" "}
                <span>{room?.grade?.descriptions?.[0].description ?? ""}</span>
              </p>

              <p className={styles.name}>
                {room?.guestsNumber} {t("guests")}
              </p>
            </div>

            <Cabins cabins={cabins} room={room} handleChoseCabin={handleChose} />

            <div className={styles.buttonContainer}>
              <Button
                id="cabins"
                className={styles.button}
                label={getButtonLabel()}
                onClick={handleSubmit}
                disabled={!canGoWorward}
                loading={isHoldCabinLoading}
              />
            </div>
          </div>

          <DeckPlans room={room} decks={preparedDecks} handleChoseDeck={handleChose} />
        </div>
      </div>
    </LoadingContainer>
  );
}

export default CabinSelect;
