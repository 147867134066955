import { Oval } from "react-loader-spinner";

import { useTypedSelector } from "@store/store";

interface ILoadingIndicatorProps {
  width?: number;
  height?: number;
  strokeWidth?: number;
  strokeWidthSecondary?: number;
  className?: string;
}

function LoadingIndicator({
  width,
  height,
  strokeWidth,
  strokeWidthSecondary,
  className,
}: ILoadingIndicatorProps) {
  const { primary_color } = useTypedSelector((state) => state.environment);

  return (
    <Oval
      ariaLabel="oval-loading"
      width={width}
      height={height}
      color={primary_color || "#000000"}
      secondaryColor={primary_color || "#000000"}
      strokeWidth={strokeWidth}
      strokeWidthSecondary={strokeWidthSecondary}
      wrapperClass={className}
    />
  );
}

LoadingIndicator.defaultProps = {
  strokeWidth: 8,
  strokeWidthSecondary: 4,
  width: 40,
  height: 40,
  className: "",
};

export default LoadingIndicator;
