"use client";

import HouseIcon from "@mui/icons-material/HouseRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/MenuRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Fab,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { useLocation } from "react-router-dom";

import { DRAWER_WIDTH, HEADER_HEIGHT, NAV_BAR_HEIGHT, NAV_ITEMS } from "@utils/constants/header";
import { BACK_TO_TOP_ANCHOR } from "@utils/constants/layout";
import { scrollToElement } from "@utils/helpers/layout";

function ScrollTop({ children }: { children: ReactElement }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => scrollToElement(BACK_TO_TOP_ANCHOR);

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", zIndex: 40, bottom: 32, right: 32 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function HideAndChangeAppBarOnScroll({ children }: { children: ReactElement }) {
  const hideTrigger = useScrollTrigger({
    threshold: 100,
  });

  return (
    <Slide appear={false} direction="down" in={!hideTrigger}>
      <AppBar
        sx={{
          background: (theme) => theme.palette["bg-primary"].main,
        }}
      >
        {children}
      </AppBar>
    </Slide>
  );
}

export function Header() {
  const { pathname } = useLocation();

  const hideTrigger = useScrollTrigger({
    threshold: 100,
  });

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen((prev) => !prev);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", pt: 4 }}>
      <Link href="/">
        <img src="/images/crt/logo-dark.png" alt="logo" width={128} height={51} />
      </Link>

      <List>
        {NAV_ITEMS.map(({ text, href }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              component={Link}
              href={href}
              sx={{
                textAlign: "center",
                color: pathname === href ? "text-light.main" : "text-primary.main",
                bgcolor: (theme) =>
                  pathname === href ? theme.palette["text-primary"].main : "transparent",
                "&:hover": {
                  bgcolor: (theme) => theme.palette["text-primary"].main,
                  color: "text-light.main",
                },
              }}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <HideAndChangeAppBarOnScroll>
        <>
          <Container
            maxWidth="xl"
            sx={{
              height: HEADER_HEIGHT,
              py: 2,
            }}
          >
            <Toolbar sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Link href="/" style={{ display: "flex" }}>
                <img src="/images/crt/logo-dark.png" alt="logo" width={276} height={104} />
              </Link>

              <Box
                sx={{
                  width: "max-content",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 0.1,
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
              >
                <Button size="small" variant="containedPrimary">
                  Subscribe to Newsletter
                </Button>

                <Typography variant="caption">Phone: (07)5575 8094</Typography>

                <Typography
                  component={Link}
                  href="tel:1800507777"
                  variant="h5"
                  sx={{
                    color: "text-dark.main",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PhoneIcon /> 1800 507 777
                </Typography>

                <Typography variant="caption">Mon - Fri 8:30am ro 5pm AEST time</Typography>
              </Box>

              <IconButton
                color="text-primary"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ display: { md: "none" } }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Toolbar>
          </Container>
        </>
      </HideAndChangeAppBarOnScroll>

      <Toolbar
        sx={{
          display: { xs: "none", md: "flex" },
          position: "fixed",
          top: hideTrigger ? 0 : HEADER_HEIGHT,
          width: "100%",
          height: NAV_BAR_HEIGHT,
          maxHeight: NAV_BAR_HEIGHT,
          minHeight: "unset !important",
          zIndex: 1101,
          transition: "top 0.225s cubic-bezier(0, 0, 0.2, 1)",
          gap: 4,
          justifyContent: "center",
          bgcolor: (theme) => theme.palette["bg-secondary"].main,
        }}
      >
        <HouseIcon color="text-light" />

        {NAV_ITEMS.map(({ text, href }) => (
          <Typography
            key={text}
            variant="body2"
            component={Link}
            href={href}
            sx={{
              textAlign: "center",
              color: "text-light.main",
            }}
          >
            {text}
          </Typography>
        ))}
      </Toolbar>

      <Drawer
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            backgroundColor: "bg-primary.main",
          },
        }}
      >
        {drawer}
      </Drawer>

      <ScrollTop>
        <Fab>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}
