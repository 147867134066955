import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import SearchFilter from "@componentsV1/base/SearchFilter";
import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import Button from "@componentsV1/shared/Button";
import PaginationComponent from "@componentsV1/shared/Pagination";
import { getPriceByCruise, selectSearch, selectSearchResultsByFilter } from "@store/selectors";
import { useLazyInitSearchResultsQuery } from "@store/services/SearchService";
import { ICruise } from "@store/slices/searchSlice";
import { useTypedSelector } from "@store/store";
import { countryCodeToFullName } from "@utils/helpers/country";
import { currencyToFormat } from "@utils/helpers/currency";

import styles from "./index.module.scss";

function SearchResults() {
  const navigate = useNavigate();

  const [retreiveSearchResults] = useLazyInitSearchResultsQuery();

  const { t } = useTranslation();

  const { pathname, search } = useLocation();

  const { results, isLoading } = useTypedSelector(selectSearchResultsByFilter);
  const { results: searchResults } = useTypedSelector(selectSearch);

  const { date_format, show_pagination } = useTypedSelector((state) => state.environment);

  const { search_item_image_source } = useTypedSelector((state) => state.environment);

  const [items, setItems] = useState<ICruise[]>([]);

  const initSearchResults = () => {
    retreiveSearchResults({ search });
  };

  const getPriceFrom = (cruise: ICruise) => {
    const price = getPriceByCruise(cruise);

    return currencyToFormat(price, cruise.markets.currency ?? "USD");
  };

  const handleNavigate = (cruise: ICruise) => () => {
    const pathName = pathname[pathname.length - 1] === "/" ? pathname.slice(0, -1) : pathname;

    navigate(`${pathName}/${cruise.code}${search}&source=${cruise.source}`);
  };

  useEffect(initSearchResults, []);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t("search results")}</h1>

      <LoadingContainer isLoading={isLoading}>
        {searchResults && searchResults?.length > 1 && <SearchFilter />}
        <div className={styles.results}>
          {(show_pagination && !items?.length) ||
          (!items?.filter((item) => getPriceFrom(item)).length &&
            !show_pagination &&
            !results?.length) ||
          !results?.filter((item) => getPriceFrom(item)).length ? (
            <p>{t("no results found")}</p>
          ) : (
            (show_pagination ? items : results)
              ?.filter((item) => getPriceFrom(item))
              .map((cruise) => {
                const priceFrom = getPriceFrom(cruise);

                return (
                  <div key={cruise.code} className={styles.item}>
                    <img
                      alt={cruise?.cruise?.name}
                      src={cruise[search_item_image_source]?.images?.[0]}
                      className={styles.image}
                    />

                    <div className={styles.bottom}>
                      <div className={styles.body}>
                        <p className={styles.name}>{cruise?.cruise?.name}</p>

                        <table className={styles.details}>
                          <tbody>
                            <tr>
                              <td>{t("SHIP NAME")}</td>
                              <td>{cruise?.ship?.name}</td>
                            </tr>

                            <tr>
                              <td>{t("REGION")}</td>
                              <td>
                                {cruise?.cruise?.countries?.map(countryCodeToFullName).join(", ")}
                              </td>
                            </tr>

                            <tr>
                              <td>{t("DEPARTS")}</td>
                              <td>{dayjs(cruise?.embark).format(date_format)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className={styles.footer}>
                        <div className={styles.price}>
                          <span className={styles.price_from}>{t("From")}</span>

                          <span className={styles.price_value}>{priceFrom}&nbsp;pp</span>
                        </div>

                        <Button
                          label={t("next")}
                          onClick={handleNavigate(cruise)}
                          className={styles.button}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
          )}
        </div>

        {show_pagination && <PaginationComponent data={results} setData={setItems} />}
      </LoadingContainer>
    </div>
  );
}

export default SearchResults;
