import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "@store/store";

import { CabinPricingOptions } from "./molecules/CabinPricingOptions";
import { CruiseDescription } from "./organisms/CruiseDescription";
import { CruiseOverview } from "./organisms/CruiseOverview";
import { CruiseTabs } from "./organisms/CruiseTabs";
import { DestinationGallery } from "./organisms/DestinationGallery";
import { TermsAndConditions } from "./organisms/TermsAndConditions";
import { TripSummary } from "./organisms/TripSummary";

function Cruise() {
  const { t } = useTranslation();

  const { cruise, isCruiseLoading } = useTypedSelector((state) => state.search);

  if (isCruiseLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
        <CircularProgress size={28} color="text-dark" />
      </Box>
    );
  }

  if (!isCruiseLoading && !cruise) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
          py: 4,
          color: "text-dark.main",
        }}
      >
        <ErrorOutlineIcon />

        <Typography variant="h5">{t("Cruise not found")}</Typography>
      </Box>
    );
  }

  return (
    <>
      <CruiseOverview />
      <CruiseDescription />
      <TripSummary />
      <DestinationGallery />
      <CruiseTabs />
      <CabinPricingOptions />
      <TermsAndConditions />
    </>
  );
}

export default Cruise;
