/* eslint-disable max-lines */
const citizenshipList = [
  { nationality: "Afghan", country: "Afghanistan", iso_2: "AF", iso_3: "AFG" },
  {
    nationality: "Åland Islander",
    country: "Åland Islands",
    iso_2: "AX",
    iso_3: "ALA",
  },
  { nationality: "Albanian", country: "Albania", iso_2: "AL", iso_3: "ALB" },
  { nationality: "Algerian", country: "Algeria", iso_2: "DZ", iso_3: "DZA" },
  {
    nationality: "American Samoan",
    country: "American Samoa",
    iso_2: "AS",
    iso_3: "ASM",
  },
  { nationality: "Andorran", country: "AndorrA", iso_2: "AD", iso_3: "AND" },
  { nationality: "Angolan", country: "Angola", iso_2: "AO", iso_3: "AGO" },
  { nationality: "Anguillan", country: "Anguilla", iso_2: "AI", iso_3: "AIA" },
  {
    nationality: "Antarctic",
    country: "Antarctica",
    iso_2: "AQ",
    iso_3: "ATA",
  },
  {
    nationality: "Antiguan and Barbudan",
    country: "Antigua and Barbuda",
    iso_2: "AG",
    iso_3: "ATG",
  },
  {
    nationality: "Argentinian",
    country: "Argentina",
    iso_2: "AR",
    iso_3: "ARG",
  },
  { nationality: "Armenian", country: "Armenia", iso_2: "AM", iso_3: "ARM" },
  { nationality: "Aruban", country: "Aruba", iso_2: "AW", iso_3: "ABW" },
  {
    nationality: "Australian",
    country: "Australia",
    iso_2: "AU",
    iso_3: "AUS",
  },
  { nationality: "Austrian", country: "Austria", iso_2: "AT", iso_3: "AUT" },
  {
    nationality: "Azerbaijani",
    country: "Azerbaijan",
    iso_2: "AZ",
    iso_3: "AZE",
  },
  { nationality: "Bahamian", country: "Bahamas", iso_2: "BS", iso_3: "BHS" },
  { nationality: "Bahraini", country: "Bahrain", iso_2: "BH", iso_3: "BHR" },
  {
    nationality: "Bangladeshi",
    country: "Bangladesh",
    iso_2: "BD",
    iso_3: "BGD",
  },
  { nationality: "Barbadian", country: "Barbados", iso_2: "BB", iso_3: "BRB" },
  { nationality: "Belarusian", country: "Belarus", iso_2: "BY", iso_3: "BLR" },
  { nationality: "Belgian", country: "Belgium", iso_2: "BE", iso_3: "BEL" },
  { nationality: "Belizean", country: "Belize", iso_2: "BZ", iso_3: "BLZ" },
  { nationality: "Beninese", country: "Benin", iso_2: "BJ", iso_3: "BEN" },
  { nationality: "Bermudian", country: "Bermuda", iso_2: "BM", iso_3: "BMU" },
  { nationality: "Bhutanese", country: "Bhutan", iso_2: "BT", iso_3: "BTN" },
  { nationality: "Bolivian", country: "Bolivia", iso_2: "BO", iso_3: "BOL" },
  {
    nationality: "Bosnian and Herzegovinian",
    country: "Bosnia and Herzegovina",
    iso_2: "BA",
    iso_3: "BIH",
  },
  { nationality: "Motswana", country: "Botswana", iso_2: "BW", iso_3: "BWA" },
  {
    nationality: "Bouvet Islander",
    country: "Bouvet Island",
    iso_2: "BV",
    iso_3: "BVT",
  },
  { nationality: "Brazilian", country: "Brazil", iso_2: "BR", iso_3: "BRA" },
  {
    nationality: "British Indian Ocean Territory",
    country: "British Indian Ocean Territory",
    iso_2: "IO",
    iso_3: "IOT",
  },
  {
    nationality: "Bruneian",
    country: "Brunei Darussalam",
    iso_2: "BN",
    iso_3: "BRN",
  },
  { nationality: "Bulgarian", country: "Bulgaria", iso_2: "BG", iso_3: "BGR" },
  {
    nationality: "Burkinabé",
    country: "Burkina Faso",
    iso_2: "BF",
    iso_3: "BFA",
  },
  { nationality: "Burundian", country: "Burundi", iso_2: "BI", iso_3: "BDI" },
  { nationality: "Cambodian", country: "Cambodia", iso_2: "KH", iso_3: "KHM" },
  {
    nationality: "Cameroonian",
    country: "Cameroon",
    iso_2: "CM",
    iso_3: "CMR",
  },
  { nationality: "Canadian", country: "Canada", iso_2: "CA", iso_3: "CAN" },
  {
    nationality: "Cape Verdean",
    country: "Cape Verde",
    iso_2: "CV",
    iso_3: "CPV",
  },
  {
    nationality: "Caymanian",
    country: "Cayman Islands",
    iso_2: "KY",
    iso_3: "CYM",
  },
  {
    nationality: "Central African",
    country: "Central African Republic",
    iso_2: "CF",
    iso_3: "CAF",
  },
  { nationality: "Chadian", country: "Chad", iso_2: "TD", iso_3: "TCD" },
  { nationality: "Chilean", country: "Chile", iso_2: "CL", iso_3: "CHL" },
  { nationality: "Chinese", country: "China", iso_2: "CN", iso_3: "CHN" },
  {
    nationality: "Christmas Islander",
    country: "Christmas Island",
    iso_2: "CX",
    iso_3: "CXR",
  },
  {
    nationality: "Cocos Islander",
    country: "Cocos (Keeling) Islands",
    iso_2: "CC",
    iso_3: "CCK",
  },
  { nationality: "Colombian", country: "Colombia", iso_2: "CO", iso_3: "COL" },
  { nationality: "Comoran", country: "Comoros", iso_2: "KM", iso_3: "COM" },
  { nationality: "Congolese", country: "Congo", iso_2: "CG", iso_3: "COG" },
  {
    nationality: "Congolese",
    country: "Congo, The Democratic Republic of the",
    iso_2: "CD",
    iso_3: "COD",
  },
  {
    nationality: "Cook Islander",
    country: "Cook Islands",
    iso_2: "CK",
    iso_3: "COK",
  },
  {
    nationality: "Costa Rican",
    country: "Costa Rica",
    iso_2: "CR",
    iso_3: "CRI",
  },
  {
    nationality: "Ivorian",
    country: "Cote D'Ivoire",
    iso_2: "CI",
    iso_3: "CIV",
  },
  { nationality: "Croatian", country: "Croatia", iso_2: "HR", iso_3: "HRV" },
  { nationality: "Cuban", country: "Cuba", iso_2: "CU", iso_3: "CUB" },
  { nationality: "Cypriot", country: "Cyprus", iso_2: "CY", iso_3: "CYP" },
  {
    nationality: "Czech",
    country: "Czech Republic",
    iso_2: "CZ",
    iso_3: "CZE",
  },
  { nationality: "Danish", country: "Denmark", iso_2: "DK", iso_3: "DNK" },
  { nationality: "Djiboutian", country: "Djibouti", iso_2: "DJ", iso_3: "DJI" },
  { nationality: "Dominican", country: "Dominica", iso_2: "DM", iso_3: "DMA" },
  {
    nationality: "Dominican",
    country: "Dominican Republic",
    iso_2: "DO",
    iso_3: "DOM",
  },
  { nationality: "Ecuadorian", country: "Ecuador", iso_2: "EC", iso_3: "ECU" },
  { nationality: "Egyptian", country: "Egypt", iso_2: "EG", iso_3: "EGY" },
  {
    nationality: "Salvadoran",
    country: "El Salvador",
    iso_2: "SV",
    iso_3: "SLV",
  },
  {
    nationality: "Equatorial Guinean",
    country: "Equatorial Guinea",
    iso_2: "GQ",
    iso_3: "GNQ",
  },
  { nationality: "Eritrean", country: "Eritrea", iso_2: "ER", iso_3: "ERI" },
  { nationality: "Estonian", country: "Estonia", iso_2: "EE", iso_3: "EST" },
  { nationality: "Ethiopian", country: "Ethiopia", iso_2: "ET", iso_3: "ETH" },
  {
    nationality: "Falkland Islander",
    country: "Falkland Islands (Malvinas)",
    iso_2: "FK",
    iso_3: "FLK",
  },
  {
    nationality: "Faroese",
    country: "Faroe Islands",
    iso_2: "FO",
    iso_3: "FRO",
  },
  { nationality: "Fijian", country: "Fiji", iso_2: "FJ", iso_3: "FJI" },
  { nationality: "Finnish", country: "Finland", iso_2: "FI", iso_3: "FIN" },
  { nationality: "French", country: "France", iso_2: "FR", iso_3: "FRA" },
  {
    nationality: "French Guianese",
    country: "French Guiana",
    iso_2: "GF",
    iso_3: "GUF",
  },
  {
    nationality: "French Polynesian",
    country: "French Polynesia",
    iso_2: "PF",
    iso_3: "PYF",
  },
  {
    nationality: "French Southern Territories",
    country: "French Southern Territories",
    iso_2: "TF",
    iso_3: "ATF",
  },
  { nationality: "Gabonese", country: "Gabon", iso_2: "GA", iso_3: "GAB" },
  { nationality: "Gambian", country: "Gambia", iso_2: "GM", iso_3: "GMB" },
  { nationality: "Georgian", country: "Georgia", iso_2: "GE", iso_3: "GEO" },
  { nationality: "German", country: "Germany", iso_2: "DE", iso_3: "DEU" },
  { nationality: "Ghanaian", country: "Ghana", iso_2: "GH", iso_3: "GHA" },
  { nationality: "Gibraltar", country: "Gibraltar", iso_2: "GI", iso_3: "GIB" },
  { nationality: "Greek", country: "Greece", iso_2: "GR", iso_3: "GRC" },
  {
    nationality: "Greenlandic",
    country: "Greenland",
    iso_2: "GL",
    iso_3: "GRL",
  },
  { nationality: "Grenadian", country: "Grenada", iso_2: "GD", iso_3: "GRD" },
  {
    nationality: "Guadeloupe",
    country: "Guadeloupe",
    iso_2: "GP",
    iso_3: "GLP",
  },
  { nationality: "Guamanian", country: "Guam", iso_2: "GU", iso_3: "GUM" },
  {
    nationality: "Guatemalan",
    country: "Guatemala",
    iso_2: "GT",
    iso_3: "GTM",
  },
  {
    nationality: "Channel Islander",
    country: "Guernsey",
    iso_2: "GG",
    iso_3: "GGY",
  },
  { nationality: "Guinean", country: "Guinea", iso_2: "GN", iso_3: "GIN" },
  {
    nationality: "Guinea-Bissauan",
    country: "Guinea-Bissau",
    iso_2: "GW",
    iso_3: "GNB",
  },
  { nationality: "Guyanese", country: "Guyana", iso_2: "GY", iso_3: "GUY" },
  { nationality: "Haitian", country: "Haiti", iso_2: "HT", iso_3: "HTI" },
  {
    nationality: "Heard and McDonald Islands",
    country: "Heard Island and Mcdonald Islands",
    iso_2: "HM",
    iso_3: "HMD",
  },
  {
    nationality: "Vatican",
    country: "Holy See (Vatican City State)",
    iso_2: "VA",
    iso_3: "VAT",
  },
  { nationality: "Honduran", country: "Honduras", iso_2: "HN", iso_3: "HND" },
  { nationality: "Hong Kong", country: "Hong Kong", iso_2: "HK", iso_3: "HKG" },
  { nationality: "Hungarian", country: "Hungary", iso_2: "HU", iso_3: "HUN" },
  { nationality: "Icelander", country: "Iceland", iso_2: "IS", iso_3: "ISL" },
  { nationality: "Indian", country: "India", iso_2: "IN", iso_3: "IND" },
  {
    nationality: "Indonesian",
    country: "Indonesia",
    iso_2: "ID",
    iso_3: "IDN",
  },
  {
    nationality: "Iranian",
    country: "Iran, Islamic Republic Of",
    iso_2: "IR",
    iso_3: "IRN",
  },
  { nationality: "Iraqi", country: "Iraq", iso_2: "IQ", iso_3: "IRQ" },
  { nationality: "Irish", country: "Ireland", iso_2: "IE", iso_3: "IRL" },
  { nationality: "Manx", country: "Isle of Man", iso_2: "IM", iso_3: "IMN" },
  { nationality: "Israeli", country: "Israel", iso_2: "IL", iso_3: "ISR" },
  { nationality: "Italian", country: "Italy", iso_2: "IT", iso_3: "ITA" },
  { nationality: "Jamaican", country: "Jamaica", iso_2: "JM", iso_3: "JAM" },
  { nationality: "Japanese", country: "Japan", iso_2: "JP", iso_3: "JPN" },
  { nationality: "Jersey", country: "Jersey", iso_2: "JE", iso_3: "JEY" },
  { nationality: "Jordanian", country: "Jordan", iso_2: "JO", iso_3: "JOR" },
  {
    nationality: "Kazakhstani",
    country: "Kazakhstan",
    iso_2: "KZ",
    iso_3: "KAZ",
  },
  { nationality: "Kenyan", country: "Kenya", iso_2: "KE", iso_3: "KEN" },
  { nationality: "I-Kiribati", country: "Kiribati", iso_2: "KI", iso_3: "KIR" },
  {
    nationality: "North Korean",
    country: "Korea, Democratic People'S Republic of",
    iso_2: "KP",
    iso_3: "PRK",
  },
  {
    nationality: "South Korean",
    country: "Korea, Republic of",
    iso_2: "KR",
    iso_3: "KOR",
  },
  { nationality: "Kuwaiti", country: "Kuwait", iso_2: "KW", iso_3: "KWT" },
  {
    nationality: "Kyrgyzstani",
    country: "Kyrgyzstan",
    iso_2: "KG",
    iso_3: "KGZ",
  },
  {
    nationality: "Laotian",
    country: "Lao People'S Democratic Republic",
    iso_2: "LA",
    iso_3: "LAO",
  },
  { nationality: "Latvian", country: "Latvia", iso_2: "LV", iso_3: "LVA" },
  { nationality: "Lebanese", country: "Lebanon", iso_2: "LB", iso_3: "LBN" },
  { nationality: "Basotho", country: "Lesotho", iso_2: "LS", iso_3: "LSO" },
  { nationality: "Liberian", country: "Liberia", iso_2: "LR", iso_3: "LBR" },
  {
    nationality: "Libyan",
    country: "Libyan Arab Jamahiriya",
    iso_2: "LY",
    iso_3: "LBY",
  },
  {
    nationality: "Liechtensteiner",
    country: "Liechtenstein",
    iso_2: "LI",
    iso_3: "LIE",
  },
  {
    nationality: "Lithuanian",
    country: "Lithuania",
    iso_2: "LT",
    iso_3: "LTU",
  },
  {
    nationality: "Luxembourger",
    country: "Luxembourg",
    iso_2: "LU",
    iso_3: "LUX",
  },
  { nationality: "Macao", country: "Macao", iso_2: "MO", iso_3: "MAC" },
  {
    nationality: "Macedonian",
    country: "Macedonia, The Former Yugoslav Republic of",
    iso_2: "MK",
    iso_3: "MKD",
  },
  { nationality: "Malagasy", country: "Madagascar", iso_2: "MG", iso_3: "MDG" },
  { nationality: "Malawian", country: "Malawi", iso_2: "MW", iso_3: "MWI" },
  { nationality: "Malaysian", country: "Malaysia", iso_2: "MY", iso_3: "MYS" },
  { nationality: "Maldivian", country: "Maldives", iso_2: "MV", iso_3: "MDV" },
  { nationality: "Malian", country: "Mali", iso_2: "ML", iso_3: "MLI" },
  { nationality: "Maltese", country: "Malta", iso_2: "MT", iso_3: "MLT" },
  {
    nationality: "Marshallese",
    country: "Marshall Islands",
    iso_2: "MH",
    iso_3: "MHL",
  },
  {
    nationality: "Martiniquais",
    country: "Martinique",
    iso_2: "MQ",
    iso_3: "MTQ",
  },
  {
    nationality: "Mauritanian",
    country: "Mauritania",
    iso_2: "MR",
    iso_3: "MRT",
  },
  { nationality: "Mauritian", country: "Mauritius", iso_2: "MU", iso_3: "MUS" },
  { nationality: "Mahoran", country: "Mayotte", iso_2: "YT", iso_3: "MYT" },
  { nationality: "Mexican", country: "Mexico", iso_2: "MX", iso_3: "MEX" },
  {
    nationality: "Micronesian",
    country: "Micronesia, Federated States of",
    iso_2: "FM",
    iso_3: "FSM",
  },
  {
    nationality: "Moldovan",
    country: "Moldova, Republic of",
    iso_2: "MD",
    iso_3: "MDA",
  },
  { nationality: "Monacan", country: "Monaco", iso_2: "MC", iso_3: "MCO" },
  { nationality: "Mongolian", country: "Mongolia", iso_2: "MN", iso_3: "MNG" },
  {
    nationality: "Montenegrin",
    country: "Montenegro",
    iso_2: "ME",
    iso_3: "MNE",
  },
  {
    nationality: "Montserratian",
    country: "Montserrat",
    iso_2: "MS",
    iso_3: "MSR",
  },
  { nationality: "Moroccan", country: "Morocco", iso_2: "MA", iso_3: "MAR" },
  {
    nationality: "Mozambican",
    country: "Mozambique",
    iso_2: "MZ",
    iso_3: "MOZ",
  },
  { nationality: "Burmese", country: "Myanmar", iso_2: "MM", iso_3: "MMR" },
  { nationality: "Namibian", country: "Namibia", iso_2: "NA", iso_3: "NAM" },
  { nationality: "Nauruan", country: "Nauru", iso_2: "NR", iso_3: "NRU" },
  { nationality: "Nepali", country: "Nepal", iso_2: "NP", iso_3: "NPL" },
  { nationality: "Dutch", country: "Netherlands", iso_2: "NL", iso_3: "NLD" },
  {
    nationality: "Netherlands Antillean",
    country: "Netherlands Antilles",
    iso_2: "AN",
    iso_3: "ANT",
  },
  {
    nationality: "New Caledonian",
    country: "New Caledonia",
    iso_2: "NC",
    iso_3: "NCL",
  },
  {
    nationality: "New Zealand",
    country: "New Zealand",
    iso_2: "NZ",
    iso_3: "NZL",
  },
  {
    nationality: "Nicaraguan",
    country: "Nicaragua",
    iso_2: "NI",
    iso_3: "NIC",
  },
  { nationality: "Nigerien", country: "Niger", iso_2: "NE", iso_3: "NER" },
  { nationality: "Nigerian", country: "Nigeria", iso_2: "NG", iso_3: "NGA" },
  { nationality: "Niuean", country: "Niue", iso_2: "NU", iso_3: "NIU" },
  {
    nationality: "Norfolk Islander",
    country: "Norfolk Island",
    iso_2: "NF",
    iso_3: "NFK",
  },
  {
    nationality: "Northern Mariana Islander",
    country: "Northern Mariana Islands",
    iso_2: "MP",
    iso_3: "MNP",
  },
  { nationality: "Norwegian", country: "Norway", iso_2: "NO", iso_3: "NOR" },
  { nationality: "Omani", country: "Oman", iso_2: "OM", iso_3: "OMN" },
  { nationality: "Pakistani", country: "Pakistan", iso_2: "PK", iso_3: "PAK" },
  { nationality: "Palauan", country: "Palau", iso_2: "PW", iso_3: "PLW" },
  {
    nationality: "Palestinian",
    country: "Palestinian Territory, Occupied",
    iso_2: "PS",
    iso_3: "PSE",
  },
  { nationality: "Panamanian", country: "Panama", iso_2: "PA", iso_3: "PAN" },
  {
    nationality: "Papua New Guinean",
    country: "Papua New Guinea",
    iso_2: "PG",
    iso_3: "PNG",
  },
  { nationality: "Paraguayan", country: "Paraguay", iso_2: "PY", iso_3: "PRY" },
  { nationality: "Peruvian", country: "Peru", iso_2: "PE", iso_3: "PER" },
  {
    nationality: "Filipino",
    country: "Philippines",
    iso_2: "PH",
    iso_3: "PHL",
  },
  {
    nationality: "Pitcairn Islander",
    country: "Pitcairn Islands",
    iso_2: "PN",
    iso_3: "PCN",
  },
  { nationality: "Polish", country: "Poland", iso_2: "PL", iso_3: "POL" },
  { nationality: "Portuguese", country: "Portugal", iso_2: "PT", iso_3: "PRT" },
  {
    nationality: "Puerto Rican",
    country: "Puerto Rico",
    iso_2: "PR",
    iso_3: "PRI",
  },
  { nationality: "Qatari", country: "Qatar", iso_2: "QA", iso_3: "QAT" },
  { nationality: "Reunionese", country: "Reunion", iso_2: "RE", iso_3: "REU" },
  { nationality: "Romanian", country: "Romania", iso_2: "RO", iso_3: "ROU" },
  {
    nationality: "Russian",
    country: "Russian Federation",
    iso_2: "RU",
    iso_3: "RUS",
  },
  { nationality: "Rwandan", country: "Rwanda", iso_2: "RW", iso_3: "RWA" },
  {
    nationality: "Saint Barthelemy",
    country: "Saint Barthelemy",
    iso_2: "BL",
    iso_3: "BLM",
  },
  {
    nationality: "Saint Helenian",
    country: "Saint Helena",
    iso_2: "SH",
    iso_3: "SHN",
  },
  {
    nationality: "Saint Kitts and Nevis",
    country: "Saint Kitts and Nevis",
    iso_2: "KN",
    iso_3: "KNA",
  },
  {
    nationality: "Saint Lucian",
    country: "Saint Lucia",
    iso_2: "LC",
    iso_3: "LCA",
  },
  {
    nationality: "Saint Martin",
    country: "Saint Martin (French part)",
    iso_2: "MF",
    iso_3: "MAF",
  },
  {
    nationality: "Saint Pierre and Miquelon",
    country: "Saint Pierre and Miquelon",
    iso_2: "PM",
    iso_3: "SPM",
  },
  {
    nationality: "Saint Vincent and The Grenadines",
    country: "Saint Vincent and The Grenadines",
    iso_2: "VC",
    iso_3: "VCT",
  },
  { nationality: "Samoan", country: "Samoa", iso_2: "WS", iso_3: "WSM" },
  {
    nationality: "San Marinese",
    country: "San Marino",
    iso_2: "SM",
    iso_3: "SMR",
  },
  {
    nationality: "Sao Tomean",
    country: "Sao Tome and Principe",
    iso_2: "ST",
    iso_3: "STP",
  },
  { nationality: "Saudi", country: "Saudi Arabia", iso_2: "SA", iso_3: "SAU" },
  { nationality: "Senegalese", country: "Senegal", iso_2: "SN", iso_3: "SEN" },
  { nationality: "Serbian", country: "Serbia", iso_2: "RS", iso_3: "SRB" },
  {
    nationality: "Seychellois",
    country: "Seychelles",
    iso_2: "SC",
    iso_3: "SYC",
  },
  {
    nationality: "Sierra Leonean",
    country: "Sierra Leone",
    iso_2: "SL",
    iso_3: "SLE",
  },
  {
    nationality: "Singaporean",
    country: "Singapore",
    iso_2: "SG",
    iso_3: "SGP",
  },
  { nationality: "Slovak", country: "Slovakia", iso_2: "SK", iso_3: "SVK" },
  { nationality: "Slovenian", country: "Slovenia", iso_2: "SI", iso_3: "SVN" },
  {
    nationality: "Solomon Islander",
    country: "Solomon Islands",
    iso_2: "SB",
    iso_3: "SLB",
  },
  { nationality: "Somali", country: "Somalia", iso_2: "SO", iso_3: "SOM" },
  {
    nationality: "South African",
    country: "South Africa",
    iso_2: "ZA",
    iso_3: "ZAF",
  },
  {
    nationality: "South Georgia and The South Sandwich Islands",
    country: "South Georgia and The South Sandwich Islands",
    iso_2: "GS",
    iso_3: "SGS",
  },
  { nationality: "Spanish", country: "Spain", iso_2: "ES", iso_3: "ESP" },
  {
    nationality: "Sri Lankan",
    country: "Sri Lanka",
    iso_2: "LK",
    iso_3: "LKA",
  },
  { nationality: "Sudanese", country: "Sudan", iso_2: "SD", iso_3: "SDN" },
  { nationality: "Surinamer", country: "Suriname", iso_2: "SR", iso_3: "SUR" },
  {
    nationality: "Svalbard and Jan Mayen Islander",
    country: "Svalbard and Jan Mayen",
    iso_2: "SJ",
    iso_3: "SJM",
  },
  { nationality: "Swazi", country: "Swaziland", iso_2: "SZ", iso_3: "SWZ" },
  { nationality: "Swedish", country: "Sweden", iso_2: "SE", iso_3: "SWE" },
  { nationality: "Swiss", country: "Switzerland", iso_2: "CH", iso_3: "CHE" },
  {
    nationality: "Syrian",
    country: "Syrian Arab Republic",
    iso_2: "SY",
    iso_3: "SYR",
  },
  {
    nationality: "Taiwanese",
    country: "Taiwan, Province of China",
    iso_2: "TW",
    iso_3: "TWN",
  },
  {
    nationality: "Tajikistani",
    country: "Tajikistan",
    iso_2: "TJ",
    iso_3: "TJK",
  },
  {
    nationality: "Tanzanian",
    country: "Tanzania, United Republic of",
    iso_2: "TZ",
    iso_3: "TZA",
  },
  { nationality: "Thai", country: "Thailand", iso_2: "TH", iso_3: "THA" },
  {
    nationality: "Timorese",
    country: "Timor-Leste",
    iso_2: "TL",
    iso_3: "TLS",
  },
  { nationality: "Togolese", country: "Togo", iso_2: "TG", iso_3: "TGO" },
  { nationality: "Tokelauan", country: "Tokelau", iso_2: "TK", iso_3: "TKL" },
  { nationality: "Tongan", country: "Tonga", iso_2: "TO", iso_3: "TON" },
  {
    nationality: "Trinidadian or Tobagonian",
    country: "Trinidad and Tobago",
    iso_2: "TT",
    iso_3: "TTO",
  },
  { nationality: "Tunisian", country: "Tunisia", iso_2: "TN", iso_3: "TUN" },
  { nationality: "Turkish", country: "Turkey", iso_2: "TR", iso_3: "TUR" },
  {
    nationality: "Turkmen",
    country: "Turkmenistan",
    iso_2: "TM",
    iso_3: "TKM",
  },
  {
    nationality: "Turks and Caicos Islander",
    country: "Turks and Caicos Islands",
    iso_2: "TC",
    iso_3: "TCA",
  },
  { nationality: "Tuvaluan", country: "Tuvalu", iso_2: "TV", iso_3: "TUV" },
  { nationality: "Ugandan", country: "Uganda", iso_2: "UG", iso_3: "UGA" },
  { nationality: "Ukrainian", country: "Ukraine", iso_2: "UA", iso_3: "UKR" },
  {
    nationality: "Emirati",
    country: "United Arab Emirates",
    iso_2: "AE",
    iso_3: "ARE",
  },
  {
    nationality: "British",
    country: "United Kingdom",
    iso_2: "GB",
    iso_3: "GBR",
  },
  {
    nationality: "American",
    country: "United States",
    iso_2: "US",
    iso_3: "USA",
  },
  {
    nationality: "United States Minor Outlying Islands",
    country: "United States Minor Outlying Islands",
    iso_2: "UM",
    iso_3: "UMI",
  },
  { nationality: "Uruguayan", country: "Uruguay", iso_2: "UY", iso_3: "URY" },
  {
    nationality: "Uzbekistani",
    country: "Uzbekistan",
    iso_2: "UZ",
    iso_3: "UZB",
  },
  { nationality: "Vanuatu", country: "Vanuatu", iso_2: "VU", iso_3: "VUT" },
  {
    nationality: "Venezuelan",
    country: "Venezuela, Bolivarian Republic of",
    iso_2: "VE",
    iso_3: "VEN",
  },
  { nationality: "Vietnamese", country: "Viet Nam", iso_2: "VN", iso_3: "VNM" },
  {
    nationality: "Virgin Islands, British",
    country: "Virgin Islands, British",
    iso_2: "VG",
    iso_3: "VGB",
  },
  {
    nationality: "Virgin Islands, U.S.",
    country: "Virgin Islands, U.S.",
    iso_2: "VI",
    iso_3: "VIR",
  },
  {
    nationality: "Wallisian",
    country: "Wallis and Futuna",
    iso_2: "WF",
    iso_3: "WLF",
  },
  {
    nationality: "Sahrawi",
    country: "Western Sahara",
    iso_2: "EH",
    iso_3: "ESH",
  },
  { nationality: "Yemenite", country: "Yemen", iso_2: "YE", iso_3: "YEM" },
  { nationality: "Zambian", country: "Zambia", iso_2: "ZM", iso_3: "ZMB" },
  { nationality: "Zimbabwean", country: "Zimbabwe", iso_2: "ZW", iso_3: "ZWE" },
];

export default citizenshipList;
