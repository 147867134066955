import i18n from "../i18n";

const { t } = i18n;

export const REQUIRED_ERRORS = {
  email: () => t("Email is required"),
  password: () => t("Password is required"),
  newPassword: () => t("New password is required"),
  start_date: () => t("Please chose start date"),
  name: () => t("Please enter name"),
  address: () => t("Please enter address"),
  telephone: () => t("Please enter phone number"),
  phone: () => t("Please enter phone number"),
  iata: () => t("Please enter IATA number"),
  clia: () => t("Please enter CLIA number"),
  account_type: () => t("Please chose organization type"),
  consortia: () => t("Please chose consortia"),
  affiliation: () => t("Please chose affiliation"),
  file: () => t("Please attach file"),
  title: () => t("Please enter your title"),
  given_name: () => t("Please enter first name"),
  last_name: () => t("Please enter second name"),
  personalPhoneNumber: () => t("Please enter your phone number"),
  position: () => t("Please chose position"),
  confirmPassword: () => t("Password confirmation is required"),
  dayOfBirth: () => t("Please enter day of birth"),
  monthOfBirth: () => t("Please enter month of birth"),
  yearOfBirth: () => t("Please enter year of birth"),
  nationality: () => t("Please enter citizenship"),
  address1: () => t("Please enter 1st line address"),
  address2: () => t("Please enter 2nd line address"),
  city: () => t("Please enter city"),
  state: () => t("Please enter state"),
  country: () => t("Please enter country"),
  zip_code: () => t("Please enter zip code"),
  gender: () => t("Please chose your gender"),
};

export const WRONG_FORMAT_ERRORS = {
  email: () => t("Not valid email"),
  website: () => t("Not valid URL"),
  phone: () => t("Not valid phone"),
};

export const NOT_POSITIVE_ERRORS = {
  staterooms: () => t("Not positive integer"),
  rooms: () => t("Not positive integer"),
};

export const WRONG_INTEGER_ERRORS = {
  staterooms: () => t("Value should between 1 and 3"),
  rooms: () => t("Value should between 1 and 3"),
};

export const NOT_EQUAL_ERRORS = {
  confirmPassword: () => t("Passwords not equal"),
};

export const TOO_SHORT_ERRORS = {
  password: () => t("Password must be at least 8 symbols"),
};
