import { localesApi } from "@store/services/apiSingleton";

export interface InitLocalesResponse {
  languages: Array<{
    origin: string;
    language: Array<{ original: string; translation: string }>;
  }>;
}

export const LocalesAPI = localesApi.injectEndpoints({
  endpoints: (builder) => ({
    initLocales: builder.query<InitLocalesResponse, null>({
      query: () => ({
        url: "/wp-json/acf/v3/options/options/languages",
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyInitLocalesQuery } = LocalesAPI;
