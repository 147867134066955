import { Middleware } from "redux";

import { store } from "@store/store";

import { initApiToken, initPosSession } from "../actions/sessionActions";

const handleInvalidSession = async () => {
  try {
    await store.dispatch(initApiToken());
    await store.dispatch(initPosSession());
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);

    throw new Error(errorMessage);
  }
};

const sessionMiddleware: Middleware = () => (next) => async (action) => {
  if ("meta" in action && "requestId" in action.meta) {
    let result = await next(action);

    if (result.error && result.payload && result.payload.status === 401) {
      await handleInvalidSession();

      result = await next(action);
    }

    return result;
  }

  return next(action);
};

export default sessionMiddleware;
