import { useTranslation } from "react-i18next";

import BookingDetails from "@componentsAWX/base/BookingDetails";
import Button from "@componentsAWX/shared/Button";
import { showModal } from "@store/slices/modalSlice";
import { useTypedDispatch } from "@store/store";
import MODAL from "@utils/constants/modal";

import styles from "../index.module.scss";

function RightSideBlock() {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  // Function to dispatch a modal action to show the itinerary modal
  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  return (
    <div className={styles.right}>
      {/* View itinerary button */}
      <Button
        label={t("view itinerary")}
        variant="secondary"
        icon="plus"
        onClick={handleShowItineraryModal}
      />

      {/* Booking details */}
      <BookingDetails />
    </div>
  );
}

export default RightSideBlock;
