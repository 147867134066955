import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import SearchBar from "@componentsV1/base/SearchBar";
import CustomBreadcrumbs from "@componentsV1/shared/CustomBreadcrumbs";
import { useLazyInitCruiseQuery } from "@store/services/SearchService";
import { Room, RoomsState, updateRoomsState } from "@store/slices/roomsSlice";
import { ICruise } from "@store/slices/searchSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import { createArrayRange } from "@utils/helpers/createArrayRange";

import styles from "./index.module.scss";

const AVAILABLE_MARKETS: Array<keyof ICruise["markets"]> = [
  "inside",
  "outside",
  "balcony",
  "suite",
];

function SearchLayout({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch();
  const [getCruise] = useLazyInitCruiseQuery();

  const { cruiseId } = useParams();
  const [searchParams] = useSearchParams();

  const { cruise } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);

  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const source = searchParams.get("source") ?? "";

  const initCruise = () => {
    getCruise({ cruiseId: cruiseId as string, source });
  };

  const initBlankStaterooms = () => {
    const roomsRange = createArrayRange(1, totalRooms);

    const defaultMarketType = AVAILABLE_MARKETS.find((mKey) => {
      const market = cruise?.markets[mKey];

      return market && typeof market === "object" && "price" in market && market.price !== "0";
    });

    const initialRoomsState: RoomsState & { rooms: Record<number, Room> } = {
      rooms: {},
      cabins: undefined,
      isCabinsLoading: true,
    };

    roomsRange.forEach((num) => {
      const room = rooms?.[num] ?? {};

      const paramsFare = searchParams.get(`fare_${num}`);
      const paramsGrade = searchParams.get(`grade_${num}`);
      const paramsGuests = searchParams.get(`guests_${num}`);
      const paramsMarket = searchParams.get(`market_${num}`);

      const fare = cruise?.pricing.find((el) => el.rate_code === paramsFare || el.lowest_fare);

      const grade = cruise?.ship.grades.find((el) => el.code === paramsGrade);
      const guestsNumber = paramsGuests ? +paramsGuests : undefined;
      const marketType = paramsMarket;

      initialRoomsState.rooms[num] = {
        fare: fare ?? cruise?.pricing[0],
        grade,
        guestsNumber,
        marketType: marketType ?? defaultMarketType,
        pricing: room.pricing ?? {},
        ...room,
      };
    });

    dispatch(updateRoomsState(initialRoomsState));
  };

  useEffect(() => {
    if (cruiseId) {
      initCruise();
    }
  }, [cruiseId]);

  useEffect(() => {
    if (cruise) {
      initBlankStaterooms();
    }
  }, [cruise, totalRooms]);

  return (
    <main className={styles.container}>
      <SearchBar />

      <section className={styles.content}>
        <CustomBreadcrumbs />

        <div className={styles.children}>{children}</div>
      </section>
    </main>
  );
}

export default SearchLayout;
