import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

import RoomForm from "@componentsV1/pages/Passengers/molecules/RoomForm";
import { GuestFields } from "@store/slices/guestsSlice";
import { createArrayRange } from "@utils/helpers/createArrayRange";

import styles from "./index.module.scss";

interface IRoomFormsProps {
  isIncludesLead: boolean;
  roomNumber: number;
  guestsAmount: number;
}

type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
      errors: GuestFields | null;
    }
  >
>;

interface RoomFormsRef {
  validateForms: () => ValidateFormsOutput;
}

const RoomForms = forwardRef<RoomFormsRef, IRoomFormsProps>(function RoomForms(
  { isIncludesLead, roomNumber, guestsAmount }: IRoomFormsProps,
  ref: ForwardedRef<RoomFormsRef>,
) {
  const guestsRange = useMemo(() => createArrayRange(1, guestsAmount ?? 1), []);

  const roomFormsRefs: Record<
    number,
    MutableRefObject<{
      expand: () => void;
      collapse: () => void;
      scrollIntoView: () => void;
      validateForm: () => {
        inputs: GuestFields;
        errors: GuestFields | null;
      };
    } | null>
  > = {};

  const validateForms = () => {
    const output: ValidateFormsOutput = { [roomNumber]: {} };

    Object.values(roomFormsRefs).forEach((formRef, index) => {
      const validationResult = formRef.current?.validateForm();

      if (validationResult) {
        output[roomNumber][index + 1] = {
          ...formRef.current,
          ...validationResult,
        };
      }
    });

    return output;
  };

  useImperativeHandle(ref, () => ({ validateForms }), []);

  return (
    <div className={styles.container}>
      {guestsRange.map((guestNumber) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const guestRef = useRef(null);

        roomFormsRefs[guestNumber] = guestRef;

        return (
          <RoomForm
            key={guestNumber}
            ref={guestRef}
            isLead={isIncludesLead && guestNumber === 1}
            guestNumber={guestNumber}
            roomNumber={roomNumber}
          />
        );
      })}
    </div>
  );
});

export default RoomForms;
