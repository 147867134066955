import { ToolbarProps, ComponentsOverrides } from "@mui/material";

declare module "@mui/material/Toolbar" {
  interface ToolbarPropsColorOverrides {
    "bg-primary": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
    red: true;
  }
}

interface IMuiToolbar {
  defaultProps: Partial<ToolbarProps>;
  styleOverrides: ComponentsOverrides["MuiToolbar"];
}

export const MuiToolbar: IMuiToolbar = {
  defaultProps: {
    color: "bg-primary",
    component: "div",
  },
  styleOverrides: {
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 !important",
    },
  },
};
