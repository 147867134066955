import Breadcrumbs from "@mui/material/Breadcrumbs";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { matchPath, useLocation, useParams, useSearchParams } from "react-router-dom";

import CustomLink from "@componentsV1/shared/CustomLink";
import { useTypedSelector } from "@store/store";
import router from "@utils/constants/router";

import styles from "./index.module.scss";

const getCrumbSearchParams = (crumbParams: string[], searchParams: URLSearchParams) => {
  let output = "";

  searchParams.forEach((value, key) => {
    const firstChar = output.length === 0 ? "?" : "&";
    const shouldParamBeUsed = crumbParams.some((crumb) => key.startsWith(crumb.replace("*", "")));

    if (shouldParamBeUsed) {
      output += `${firstChar}${key}=${value}`;
    }
  });

  return output;
};

function CustomBreadcrumbs() {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { pathname, search } = useLocation();

  const { breadcrumbs_text } = useTypedSelector((state) => state.environment);

  const [crumbs, setCrumbs] = useState<Record<string, { title: string }>>({});

  const routesEntries = Object.entries(router).filter(([, route]) => !!route.title);

  const currentRouteIndex = routesEntries.findIndex(([path]) => matchPath(path, pathname));

  const initCrumbs = useCallback(() => {
    const slicedRouteEntries = routesEntries.slice(0, currentRouteIndex + 1);
    const newCrumbs: Record<string, { title: string }> = {};

    slicedRouteEntries.forEach(([routeKey, route]) => {
      const routeParam = routeKey.split(":")[1]?.split("/")[0];

      const crumbPathname = routeKey.replace(`:${routeParam}`, params[routeParam] ?? "");

      const crumbSearchParams = getCrumbSearchParams(route.crumbParams ?? [], searchParams);

      const pathnameWithSearch = `${crumbPathname}${crumbSearchParams}`;

      newCrumbs[pathnameWithSearch] = { title: route.title ?? "" };
    });

    setCrumbs(newCrumbs);
  }, [pathname, search]);

  useEffect(initCrumbs, [pathname, search]);

  return (
    <div className={styles.container}>
      <Breadcrumbs>
        {Object.entries(crumbs)
          .slice(0, currentRouteIndex + 1)
          .map(([crumbKey, crumb], index) => {
            const isLast =
              index === Object.entries(crumbs).slice(0, currentRouteIndex + 1).length - 1;

            return (
              <CustomLink key={crumbKey} to={crumbKey} isPrimary={isLast} isDisabled={isLast}>
                {crumb.title}
              </CustomLink>
            );
          })}
      </Breadcrumbs>

      {breadcrumbs_text && <p className={styles.text}>{t(breadcrumbs_text)}</p>}
    </div>
  );
}

export default CustomBreadcrumbs;
