import dayjs from "dayjs";

const useCookies = () => {
  const { cookie } = document;

  const getCookie = (name: string) => {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(";").map((c) => c.trim());
    const foundCookie = cookies.find((c) => c.startsWith(nameEQ));

    return foundCookie ? foundCookie.substring(nameEQ.length) : "";
  };

  const setCookie = (name: string, value: string, seconds: number) => {
    const utcDate = dayjs().add(seconds, "seconds").toDate().toUTCString();

    document.cookie = `${name}=${value}; expires=${utcDate}; secure; path=/`;
  };

  return { cookie, getCookie, setCookie };
};

export default useCookies;
