import { ComponentsOverrides, Theme } from "@mui/material";

import { lato } from "@fonts/latoFont";

declare module "@mui/material/Link" {
  interface LinkPropsColorOverrides {
    "bg-primary": true;
    "bg-secondary": true;
    "bg-dark": true;
    "bg-button": true;
    "text-primary": true;
    "text-light": true;
    "text-dark": true;
    red: true;
  }
}

interface IMuiLink {
  styleOverrides: ComponentsOverrides<Theme>["MuiLink"];
}

export const MuiLink: IMuiLink = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: lato.fontFamily,
      fontWeight: theme.typography.fontWeightMedium,
      whiteSpace: "nowrap",
      fontSize: theme.typography.pxToRem(16),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(18),
      },
    }),
  },
};
