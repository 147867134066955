import { searchApi } from "@store/services/apiSingleton";
import { ICruise } from "@store/slices/searchSlice";
import { store } from "@store/store";

export interface ILocation {
  type: "ship" | "country" | "cruise_tag";
  name: string;
  value: string;
}

type ISearchResultsResponse = ICruise[] | undefined;

export interface ISearchResultsRequest {
  search: string;
}

interface ICruiseRequest {
  cruiseId: string;
  source: string;
}

interface ISearchLocationsResponse {
  last_date: string;
  auto_complete_items: Array<Record<string, string>>;
}

export const SearchAPI = searchApi.injectEndpoints({
  endpoints: (builder) => ({
    initSearchResults: builder.query<ISearchResultsResponse, ISearchResultsRequest>({
      query: ({ search }) => {
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        return {
          url: `/search/${search}&session=${sessionKey ?? ""}&language=${api_language ?? "en-us"}`,
          method: "GET",
        };
      },
    }),
    initCruise: builder.query<ICruise, ICruiseRequest>({
      query: ({ cruiseId, source }) => {
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        return {
          url: `/search/${cruiseId}?session=${
            sessionKey ?? ""
          }&language=${api_language ?? "en-us"}&source=${source}`,
        };
      },
    }),
    searchLocations: builder.query<ISearchLocationsResponse, unknown>({
      query: () => {
        const { sessionKey } = store.getState().session;
        const { api_language } = store.getState().environment;

        return {
          url: `/searchform/?session=${sessionKey ?? ""}&language=${api_language ?? "en-us"}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useLazyInitSearchResultsQuery,
  useLazyInitCruiseQuery,
  useLazySearchLocationsQuery,
} = SearchAPI;
