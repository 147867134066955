export const countryCodeToFullName = (code: string) => {
  const isValidCountryCode = /^[A-Z]{2}$/.test(code);

  if (!isValidCountryCode) {
    return "Invalid country code";
  }

  const country = new Intl.DisplayNames(["en"], { type: "region" }).of(code);

  return country ?? code;
};
