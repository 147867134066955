import React from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import RootLayoutAWX from "@componentsAWX/layouts/Root";
import RootLayoutCRT from "@componentsCRT/layouts/Root";
import RootGuard from "@componentsV1/guards/RootGuard";
import RootLayoutV1 from "@componentsV1/layouts/Root";
import PRIVATE_ROUTES from "@utils/constants/router";
import { LayoutType, ROOT, UIVersion } from "@utils/constants/routes";

const LAYOUTS_BY_VERSION: Record<
  UIVersion,
  React.FC<
    React.PropsWithChildren<{
      type: LayoutType;
      children: React.ReactNode;
    }>
  >
> = {
  v1: RootLayoutV1,
  crt: RootLayoutCRT,
  awx: RootLayoutAWX,
};

function Navigation() {
  return (
    <RootGuard>
      <Router>
        <Routes>
          {Object.entries(PRIVATE_ROUTES).map(([routeKey, route]) => {
            const { element: Element, layout, uiVersion } = route;

            const RootLayout = LAYOUTS_BY_VERSION[uiVersion];

            return (
              <Route
                key={routeKey}
                path={routeKey}
                element={
                  <RootLayout type={layout}>
                    <Element />
                  </RootLayout>
                }
              />
            );
          })}

          <Route path="*" element={<Navigate to={ROOT} />} />
        </Routes>
      </Router>
    </RootGuard>
  );
}

export default Navigation;
