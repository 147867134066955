import { paymentApi } from "@store/services/apiSingleton";
import { store } from "@store/store";

export const PaymentAPI = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    createOpaquePayment: builder.query({
      query: ({ pnr, source, transactions }) => {
        const { sessionKey: session } = store.getState().session;

        const { api_agency: agency, payment_api_path: payment_path } = store.getState().environment;

        return {
          url: payment_path,
          method: "POST",
          body: {
            agency,
            pnr,
            session,
            source,
            transactions,
          },
        };
      },
    }),
  }),
});

export const { useLazyCreateOpaquePaymentQuery } = PaymentAPI;
