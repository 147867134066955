import { useEffect, useState } from "react";

import SvgIcon from "@componentsV1/shared/SvgIcon";

import styles from "./index.module.scss";

interface ICheckboxProps {
  label?: string | React.ReactNode;
  value: boolean;
  valueKey?: string;
  onChange?: ({ value, valueKey }: { value: boolean; valueKey: string }) => void;
}

function Checkbox({ label, value, valueKey, onChange }: ICheckboxProps) {
  const [internalValue, setInternalValue] = useState(!!value);

  const handleChange = (newValue: boolean) => () => {
    if (onChange) {
      onChange({ value: newValue, valueKey: valueKey ?? "" });
    }

    if (!onChange) {
      setInternalValue(value);
    }
  };

  useEffect(() => {
    setInternalValue(!!value);
  }, [value]);

  return (
    <div
      className={styles.container}
      onClick={handleChange(!internalValue)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleChange(!internalValue)();
        }
      }}
      role="checkbox"
      aria-checked={internalValue}
      tabIndex={0}
    >
      <SvgIcon type={internalValue ? "checked" : "unchecked"} className={styles.icon} />

      {label && <p className={styles.label}>{label}</p>}
    </div>
  );
}

Checkbox.defaultProps = {
  label: "",
  valueKey: undefined,
  onChange: () => null,
};

export default Checkbox;
