import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Button from "@componentsV1/shared/Button";
import { useLazyCreateReservationQuery } from "@store/services/CabinSelectService";
import { GuestFields } from "@store/slices/guestsSlice";
import { updateRooms } from "@store/slices/roomsSlice";
import { showToast } from "@store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import TOAST from "@utils/constants/toast";
import { prepareParams } from "@utils/helpers/rooms";

import styles from "./index.module.scss";

interface ISubmitButtonProps {
  onClick: () => void;
}

type SubmitButtonPayload = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
    }
  >
>;

const SubmitButton = forwardRef(function SubmitButton(
  { onClick }: ISubmitButtonProps,
  ref: ForwardedRef<{
    submit: (guests: SubmitButtonPayload) => void;
  }>,
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [reservation] = useLazyCreateReservationQuery();

  const { cruiseId } = useParams();
  const [searchParams] = useSearchParams();
  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const currentRoomNumber = +(searchParams.get("room") ?? 0);

  const { rooms } = useTypedSelector((state) => state.rooms);
  const { cruise } = useTypedSelector((state) => state.search);

  const { app_language, skip_payment_step } = useTypedSelector((state) => state.environment);

  const [isLoading, setIsLoading] = useState(false);

  const submit = async (guests: SubmitButtonPayload) => {
    setIsLoading(true);

    const payload = {
      rate_code: rooms?.[1].fare?.rate_code ?? "",
      cruiseId: cruiseId ?? "",
      source: cruise?.source ?? "",
      cabins: Object.values(rooms ?? {}).map((room, roomIndex) => ({
        cabin_number: room.cabin?.number ?? "",
        dining_seating: "",
        dining_table: "",
        grade_code: room.grade?.code ?? "",
        guests: Object.values(guests[roomIndex + 1] ?? {}).map((guest, guestIndex) => ({
          address: [guest.inputs.address1, guest.inputs.address2].filter((el) => el),
          city: guest.inputs.city,
          country: guest.inputs.country,
          date_of_birth: `${guest.inputs.yearOfBirth}-${guest.inputs.monthOfBirth}-${guest.inputs.dayOfBirth}`,
          email: guest.inputs.email,
          given_name: guest.inputs.given_name,
          language: app_language,
          lastname: guest.inputs.last_name,
          last_name: guest.inputs.last_name,
          lead_passenger: guestIndex === 0 && roomIndex === 0,
          nationality: guest.inputs.nationality,
          phone: guest.inputs.phone,
          state: guest.inputs.state,
          title: guest.inputs.title,
          gender: guest.inputs.gender,
          zip_code: guest.inputs.zip_code,
          request: guest.inputs.request,
        })),
      })),
    };

    try {
      const { data } = await reservation({ ...payload });
      const updatedStateRooms = structuredClone(rooms);

      if (data?.pnr && updatedStateRooms) {
        updatedStateRooms[1].pnr = data.pnr;

        const pathParam = skip_payment_step ? "payment-confirmation" : "payment";

        const params = prepareParams(
          searchParams,
          rooms ?? {},
          currentRoomNumber,
          currentRoomNumber < totalRooms,
        );

        dispatch(updateRooms(updatedStateRooms));
        navigate(`/search-results/${cruiseId}/${pathParam}?${params}`);
      }

      if (!data) {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            duration: TOAST.DEFAULT_DURATION,
            message: t("PNR was not received from the server"),
          }),
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          duration: TOAST.DEFAULT_DURATION,
          message: t("Reservation creation failed"),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({ submit }), [submit]);

  return (
    <Button
      id="passengers_submit_button"
      label={t("submit")}
      className={styles.submitButton}
      onClick={onClick}
      loading={isLoading}
      disabled={isLoading}
    />
  );
});

export default SubmitButton;
