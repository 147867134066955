import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SearchFilterState {
  price: number[];
  sortName: boolean;
  sortDate: boolean;
  sortPrice: boolean;
  sortType: string;
}

export const SORT_TYPE = {
  NANE: "name",
  DATE: "date",
  PRICE: "price",
};

const initialState: SearchFilterState = {
  price: [],
  sortType: SORT_TYPE.DATE,
  sortName: true,
  sortDate: true,
  sortPrice: true,
};

const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    filterRangePrice(state: SearchFilterState, action: PayloadAction<number[]>) {
      state.price = action.payload;
    },
    sortName(state: SearchFilterState, action: PayloadAction<boolean>) {
      searchFilterSlice.caseReducers.clearFilterSort(state);

      state.sortName = state.sortType !== SORT_TYPE.NANE ? true : action.payload;
      state.sortType = SORT_TYPE.NANE;
    },
    sortDate(state: SearchFilterState, action: PayloadAction<boolean>) {
      searchFilterSlice.caseReducers.clearFilterSort(state);

      state.sortDate = state.sortType !== SORT_TYPE.DATE ? true : action.payload;
      state.sortType = SORT_TYPE.DATE;
    },
    sortPrice(state: SearchFilterState, action: PayloadAction<boolean>) {
      searchFilterSlice.caseReducers.clearFilterSort(state);

      state.sortPrice = state.sortType !== SORT_TYPE.PRICE ? true : action.payload;
      state.sortType = SORT_TYPE.PRICE;
    },
    clearFilterSort(state: SearchFilterState) {
      state.sortName = true;
      state.sortDate = true;
      state.sortPrice = true;
    },
  },
});

export const { filterRangePrice, sortName, sortDate, sortPrice } = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
