import { Box, Grid2 as Grid } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

const FAKE_DESTINATION_IMAGES = [
  "/images/crt/destinations/destination-1.png",
  "/images/crt/destinations/destination-2.png",
  "/images/crt/destinations/destination-3.png",
];

export function DestinationGallery() {
  const { cruise } = useTypedSelector((state) => state.search);

  const images = useMemo(() => {
    const output = cruise?.cruise.images ? cruise?.cruise.images : FAKE_DESTINATION_IMAGES;

    return output;
  }, [cruise]);

  return (
    <Grid container mt={{ xs: 4, md: 8 }} spacing={{ xs: 2, sm: 3, md: 4 }}>
      {images.map((image, index) => (
        <Grid
          key={image}
          size={{
            xs: index < 2 ? 6 : 12,
            md: 4,
          }}
        >
          <Box
            component="img"
            src={image}
            alt="destination"
            sx={{
              width: "100%",
              height: { xs: index < 2 ? 206 : 210, md: 350, lg: 728 },
              borderRadius: (theme) => theme.toPx(theme.customShape.largeRadius),
              objectFit: "cover",
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
