const ERROR_TYPE = "error" as const;
const SUCCESS_TYPE = "success" as const;
const DEFAULT_DURATION = 3000;

const TOAST = {
  ERROR_TYPE,
  SUCCESS_TYPE,
  DEFAULT_DURATION,
};

export default TOAST;
