import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Grade } from "@store/slices/searchSlice";

export interface ModalState {
  type: string;
  data?: null | string[] | Grade;
}

const initialState: ModalState = {
  type: "",
  data: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal(state: ModalState, action: PayloadAction<ModalState>) {
      state.type = action.payload.type;
      state.data = action.payload.data;
    },
    hideModal(state) {
      state.type = "";
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
