import { Typography, Grid2 as Grid } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

import { ItineraryAccordion } from "./ItineraryAccordion";

export function ItineraryContent() {
  const { cruise } = useTypedSelector((state) => state.search);

  const { title, description } = useMemo(() => {
    if (!cruise?.cruise?.descriptions || !cruise?.cruise?.itinerary) {
      return {
        description: "Loading...",
        itineraryDetails: [],
      };
    }

    const descriptionItem = cruise.cruise.descriptions.find(
      (desc) => desc.category === "Itinerary Text",
    );

    return {
      title: descriptionItem?.title ?? "N/A",
      description: descriptionItem?.description ?? "N/A",
    };
  }, [cruise]);

  return (
    <Grid spacing={4} container>
      <Grid size={{ xs: 12, lg: 7 }}>
        <Typography component="h2" variant="sectionTitle" color="text-primary" mb={4}>
          {title}
        </Typography>

        <Typography component="p" variant="footnote" mb={4}>
          {description}
        </Typography>
      </Grid>

      <Grid
        size={{ xs: 12, lg: 5 }}
        component="img"
        src={cruise?.cruise.map}
        alt="Route map"
        sx={{
          width: "100%",
          height: { xs: 334, md: 710, lg: 544 },
          borderRadius: (theme) => theme.toPx(theme.customShape.largeRadius),
          objectFit: "cover",
          mt: 3,
        }}
      />

      <ItineraryAccordion />
    </Grid>
  );
}
