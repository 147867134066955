import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { useMemo } from "react";

import styles from "./index.module.scss";

interface IInputProps {
  className?: string;
  value: string;
  valueKey?: string;
  label?: string;
  subtext?: string;
  placeholder?: string;
  autoComplete?: string;
  name?: string;
  errorMessage?: string;
  isRequired?: boolean;
  readOnly?: boolean;
  showError?: boolean;
  onClick?: () => void;
  onChange?: ({ value, valueKey }: { value: string; valueKey: string }) => void;
  onBlur?: ({ value, valueKey }: { value: string; valueKey: string }) => void;
}

function TextArea({
  className,
  value,
  valueKey,
  label,
  subtext,
  placeholder,
  autoComplete,
  name,
  errorMessage,
  isRequired,
  readOnly,
  showError,
  onClick,
  onChange,
  onBlur,
}: IInputProps) {
  const id = useMemo(nanoid, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      {subtext && <p className={classNames(styles.subtext, styles.subtext_small)}>{subtext}</p>}

      <div className={styles.fieldContainer}>
        <textarea
          id={id}
          className={classNames(styles.field, {
            [styles.field_error]: !!errorMessage,
          })}
          value={value}
          placeholder={placeholder}
          name={name}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      {showError && <span className={styles.error}>{errorMessage}</span>}
    </div>
  );
}

TextArea.defaultProps = {
  className: "",
  subtext: undefined,
  valueKey: "",
  label: "",
  placeholder: "",
  autoComplete: "",
  name: "",
  errorMessage: "",
  isRequired: false,
  readOnly: false,
  showError: true,
  onClick: () => null,
  onChange: () => null,
  onBlur: () => null,
};

export default TextArea;
