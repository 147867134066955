import classNames from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomLink from "@componentsV1/shared/CustomLink";
import { ROOT } from "@utils/constants/routes";

import styles from "./index.module.scss";

import "dayjs/locale/es";
import "dayjs/locale/en";

interface ISearchLocalStorage {
  duration: string | null;
  date: string[];
  chosenLocations: [] | Array<Record<string, string>>;
}

function FormatSearchString({ chosenLocations, date, duration }: ISearchLocalStorage) {
  const { t } = useTranslation();

  let datesFormat = date
    ?.filter((dateToFormat) => dateToFormat)
    .map((dateToFormat) => dayjs(dateToFormat).format("MMMM"));

  datesFormat = [...new Set(datesFormat)];

  return (
    <p>
      {t("You searched ")}
      {chosenLocations?.map((location, index) => (
        <strong key={location.name}>
          {!!index && " , "}
          {location.name}
        </strong>
      ))}
      {t(" in ")}
      {datesFormat?.map((dateFormat, index) => (
        <strong key={dateFormat}>
          {!!index && " - "}
          {dateFormat}
        </strong>
      ))}
      {duration && (
        <span>
          {" "}
          for <strong>{duration} nights</strong>
        </span>
      )}
    </p>
  );
}

function SearchBar() {
  const { t } = useTranslation();

  const [data, setData] = useState<ISearchLocalStorage>({
    duration: "",
    date: [],
    chosenLocations: [],
  });

  useEffect(() => {
    const storageSearch = localStorage.getItem("search");

    if (storageSearch) {
      const search: ISearchLocalStorage = JSON.parse(localStorage.getItem("search") ?? "");

      setData(search);
    }
  }, []);

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.content)}>
        <FormatSearchString
          date={data.date}
          duration={data.duration}
          chosenLocations={data.chosenLocations}
        />

        <CustomLink className={styles.searchEditLink} to={{ pathname: ROOT, search: "edit" }}>
          {t("edit search")}
        </CustomLink>
      </div>
    </div>
  );
}

export default SearchBar;
