function formatPhoneNumber(phoneNumber: string) {
  const outputNumber = phoneNumber.replace(/\D/g, "");

  if (outputNumber.length === 10) {
    return outputNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  return phoneNumber;
}

export default formatPhoneNumber;
