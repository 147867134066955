import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import sessionMiddleware from "@store/middlewares/sessionMiddleware";
import api from "@store/services/apiSingleton";
import agency from "@store/slices/agencySlice";
import environment from "@store/slices/environmentSlice";
import guests from "@store/slices/guestsSlice";
import locales from "@store/slices/localesSlice";
import modal from "@store/slices/modalSlice";
import pricing from "@store/slices/pricingSlice";
import reservation from "@store/slices/reservationSlice";
import rooms from "@store/slices/roomsSlice";
import searchFilter from "@store/slices/searchFilterSlice";
import search from "@store/slices/searchSlice";
import session from "@store/slices/sessionSlice";
import toast from "@store/slices/toastSlice";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["rooms", "guests"],
};

const reducers = {
  [api.session.reducerPath]: api.session.reducer,
  [api.pos.reducerPath]: api.pos.reducer,
  [api.search.reducerPath]: api.search.reducer,
  [api.agency.reducerPath]: api.agency.reducer,
  [api.environment.reducerPath]: api.environment.reducer,
  [api.payment.reducerPath]: api.payment.reducer,
  [api.reservation.reducerPath]: api.reservation.reducer,
  [api.locales.reducerPath]: api.locales.reducer,
  session,
  toast,
  modal,
  search,
  rooms,
  agency,
  reservation,
  environment,
  searchFilter,
  guests,
  pricing,
  locales,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.session.middleware)
      .concat(api.pos.middleware)
      .concat(api.search.middleware)
      .concat(api.agency.middleware)
      .concat(api.environment.middleware)
      .concat(api.payment.middleware)
      .concat(api.reservation.middleware)
      .concat(api.locales.middleware)
      .concat(sessionMiddleware),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
