import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

import { ShipSwiper } from "./ShipSwiper";

export const ShipOverview = () => {
  const { cruise } = useTypedSelector((state) => state.search);

  const { logo, title, description } = useMemo(() => {
    if (!cruise?.ship || !cruise?.ship.brand) {
      return {
        description: "Loading...",
      };
    }

    const descriptionItem = cruise.ship.descriptions.find(
      (desc) => desc.category === "Description " || desc.category === "Description",
    );

    const logo = cruise.ship.brand.logo;
    const title = cruise.ship.name;
    const description = descriptionItem?.description ?? "N/A";

    return {
      logo,
      title,
      description,
    };
  }, [cruise]);

  return (
    <Box>
      <Grid container size={12} spacing={4} sx={{ alignItems: "center" }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <ShipSwiper images={cruise?.ship.images ?? []} />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }} spacing={{ xs: 2, md: 4 }} direction="column" container>
          <Box component="img" src={logo} alt="ship brand logo" sx={{ width: 110 }} />

          <Typography component="h2" variant="sectionTitle" color="text-primary">
            {title}
          </Typography>

          <Typography component="p" variant="footnote">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
