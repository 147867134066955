import ExpandMoreIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/Remove";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  useMediaQuery,
  Grid2 as Grid,
} from "@mui/material";
import { useState } from "react";

import { useTypedSelector } from "@store/store";

export function ItineraryAccordion() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { cruise } = useTypedSelector((state) => state.search);

  const [expanded, setExpanded] = useState<string | false>(false);

  const ITINERARY =
    cruise?.cruise?.itinerary?.map((item) => ({
      day: item.day,
      port: item.port,
      arrive_time: item.arrive_time,
      depart_time: item.depart_time,
      extra_information: item.extra_information,
      description: item.description,
      image: item.images && item.images[0] ? item.images[0] : null,
    })) || [];

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      {ITINERARY.map((item, index) => {
        const extraInformation = item.extra_information;
        const description = item.description;

        const isExtraInformationValid =
          Boolean(extraInformation) && JSON.stringify(extraInformation) !== "{}";

        const isDescriptionValid = Boolean(description) && JSON.stringify(description) !== "{}";

        return (
          <Accordion
            key={`${item.port}-${index}`}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{ mb: 2 }}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${index}` ? (
                  <ExpandLessIcon color="text-primary" />
                ) : (
                  <ExpandMoreIcon color="text-primary" />
                )
              }
              sx={{ height: 94, px: 4, pb: 0, display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pr: 2,
                  flexGrow: 1,
                }}
              >
                <Typography component="p" variant="smallHeader" color="text-dark">
                  Day {item.day}: {item.port}
                </Typography>

                {!isMobile && item.arrive_time && item.depart_time && (
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Typography component="span" variant="footnote" fontWeight={600}>
                      ARRIVES:{" "}
                      <Typography
                        component="span"
                        variant="footnote"
                        color="text-primary"
                        fontWeight={600}
                      >
                        {item.arrive_time}
                      </Typography>
                    </Typography>

                    <Typography component="span" variant="footnote" fontWeight={600}>
                      DEPARTS:{" "}
                      <Typography
                        component="span"
                        variant="footnote"
                        color="text-primary"
                        fontWeight={600}
                      >
                        {item.depart_time}
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ p: 4, pt: 0 }}>
              <Grid container size={12} spacing={4} sx={{ alignItems: "center" }}>
                {item.image && (
                  <Grid
                    size={{ xs: 12, sm: 5 }}
                    component="img"
                    src={item.image.image}
                    alt="itinerary"
                    sx={{
                      width: "100%",
                      height: { xs: 196, md: 296, lg: 386 },
                      borderRadius: (theme) => theme.toPx(theme.customShape.largeRadius),
                      objectFit: "cover",
                    }}
                  />
                )}

                <Grid size={{ xs: 12, sm: item.image ? 7 : 12 }}>
                  <Typography component="span" variant="footnote">
                    {isDescriptionValid ? description : ""}
                  </Typography>

                  <Typography component="span" variant="footnote">
                    {isExtraInformationValid ? extraInformation : ""}
                  </Typography>

                  <Typography component="span" variant="footnote">
                    {!isExtraInformationValid && !isDescriptionValid && "No details"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
