function getDaysList(yearStr?: string, monthStr?: string): string[] {
  if (!yearStr && !monthStr) {
    return Array.from({ length: 30 }, (_, i) => String(i + 1).padStart(2, "0"));
  }

  const currentYear = new Date().getFullYear();
  const year = yearStr ? parseInt(yearStr, 10) : currentYear;
  const month = monthStr ? parseInt(monthStr, 10) - 1 : -1;

  if (Number.isNaN(year) || (month !== -1 && (Number.isNaN(month) || month < 0 || month > 11))) {
    throw new Error(`Invalid year or month: year=${yearStr}, month=${monthStr}`);
  }

  if (month === -1) {
    return Array.from({ length: 30 }, (_, i) => String(i + 1).padStart(2, "0"));
  }

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const daysList: string[] = [];

  for (let i = 1; i <= daysInMonth; i += 1) {
    daysList.push(String(i).padStart(2, "0"));
  }

  return daysList;
}

export default getDaysList;
