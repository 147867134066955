const guestFormRules = {
  address1: ["required", "trim", "string"],
  address2: ["trim", "string"],
  city: ["required", "trim", "string"],
  country: ["required", "string"],
  dayOfBirth: ["required", "trim", "string"],
  monthOfBirth: ["required", "trim", "string"],
  yearOfBirth: ["required", "trim", "string"],
  email: ["required", "trim", "email"],
  given_name: ["required", "trim", "string"],
  last_name: ["required", "trim", "string"],
  lead_passenger: { one_of: [true, false] },
  nationality: ["required", "trim", "string"],
  phone: ["required", "phone"],
  state: ["required", "trim", "string"],
  title: ["required", "string"],
  gender: ["required", "string"],
  zip_code: ["required", "trim", "string"],
  passport_number: ["trim", "string"],
  issue_date: ["trim", "string"],
  expiry_date: ["trim", "string"],
  place_of_birth: ["trim", "string"],
  country_of_issue: ["trim", "string"],
  request: ["trim", "string"],
};

export default guestFormRules;
