import CustomCarousel from "@componentsV1/shared/Carousel";
import { useTypedSelector } from "@store/store";

import styles from "./index.module.scss";

function ImagesCarouselModal() {
  const { data } = useTypedSelector((state) => state.modal);

  return (
    <div className={styles.container}>
      <CustomCarousel items={(data as string[]) ?? []} />
    </div>
  );
}

export default ImagesCarouselModal;
