import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "@componentsV1/shared/Button";
import CustomCarousel from "@componentsV1/shared/Carousel";
import { hideModal } from "@store/slices/modalSlice";
import { updateRoom } from "@store/slices/roomsSlice";
import { Grade } from "@store/slices/searchSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import { currencyToFormat } from "@utils/helpers/currency";
import { prepareParams } from "@utils/helpers/rooms";

import styles from "./index.module.scss";

function StateroomsModal() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { cruise } = useTypedSelector((state) => state.search);
  const { data } = useTypedSelector((state) => state.modal);
  const { rooms } = useTypedSelector((state) => state.rooms);

  const grade = data as Grade;
  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const currentRoomNumber = +(searchParams.get("room") ?? 0);

  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }

    return undefined;
  }, [rooms, currentRoomNumber]);

  const canGoForward = useMemo(() => {
    return !!room?.grade && !!room?.guestsNumber;
  }, [room]);

  const handleUpdateChosenGrades = (incomingGrade: Grade, guestsNumber: number) => () => {
    const updatedStateroom = structuredClone({
      ...room,
      incomingGrade,
      guestsNumber,
    });

    dispatch(updateRoom({ room: updatedStateroom, roomNumber: currentRoomNumber }));
  };

  const handleSubmit = () => {
    const pathParam = currentRoomNumber < totalRooms ? "rooms" : "cabin-select";

    const params = prepareParams(
      searchParams,
      rooms ?? {},
      currentRoomNumber,
      currentRoomNumber < totalRooms,
    );

    if (cruise) {
      navigate(`/search-results/${cruise.code}/${pathParam}?${params}`);
    }

    dispatch(hideModal());
  };

  const getButtonLabel = () => {
    if (currentRoomNumber < totalRooms) {
      return `continue to stateroom ${currentRoomNumber + 1}`;
    }

    return "continue to staterooms";
  };

  return (
    <div className={styles.container}>
      <CustomCarousel items={grade.images} />

      <p className={styles.name}>{grade.name}</p>

      <div className={styles.row}>
        <div className={styles.cell}>
          <span className={styles.cellKey}>located:</span>

          <span className={styles.cellValue}>{`Deck ${grade.decks
            .map((el) => el.code)
            .join(", ")}`}</span>
        </div>

        <div className={styles.cell}>
          <span className={styles.cellKey}>occupancy:</span>

          <span className={styles.cellValue}>{`Up to ${grade.maximum_occupancy} guests`}</span>
        </div>
      </div>

      <p className={styles.cellValue}>{grade.descriptions?.[0].description ?? ""}</p>

      <div className={styles.block}>
        <p className={styles.cellKey}>facilities</p>

        <ul>
          <li className={styles.cellValue}>
            <span>{grade.size}</span>
          </li>
        </ul>
      </div>

      <div className={styles.block}>
        <p className={styles.cellKey}>pricing per guest</p>

        <div className={styles.grades}>
          {[1, 2, 3, 4].map((guestsNumber) => {
            const { single, double, triple, quadruple } = grade;
            const guestTitle = guestsNumber === 1 ? t("guest") : t("guests");

            const pricesByGuestNumber: Record<number, number> = {
              1: single,
              2: double,
              3: triple,
              4: quadruple,
            };

            const price =
              guestsNumber > grade.maximum_occupancy || guestsNumber < grade.minimum_occupancy
                ? "$N/A"
                : currencyToFormat(
                    pricesByGuestNumber[guestsNumber],
                    cruise?.pricing?.[0]?.currency ?? "USD",
                  ).slice(0, -3);

            const isDisabled = price === "$N/A";

            const isActive =
              room?.grade?.code === grade.code && room?.guestsNumber === guestsNumber;

            return (
              <div
                key={guestsNumber}
                role="button"
                tabIndex={0}
                className={classNames(styles.grade, {
                  [styles.grade_active]: isActive,
                  [styles.grade_disabled]: isDisabled,
                })}
                onClick={!isDisabled ? handleUpdateChosenGrades(grade, guestsNumber) : () => null}
                onKeyPress={(e) => {
                  if ((e.key === "Enter" || e.key === " ") && !isDisabled) {
                    handleUpdateChosenGrades(grade, guestsNumber);
                  }
                }}
              >
                <p className={styles.gradeName}>{`${guestsNumber} ${guestTitle}`}</p>

                <p className={styles.gradePrice}>{`${price ?? 0}`}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          label={getButtonLabel()}
          disabled={!canGoForward}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

export default StateroomsModal;
