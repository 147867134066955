import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import LoadingContainer from "@componentsV1/containers/LoadingContainer";
import CompletedStaterooms from "@componentsV1/pages/Rooms/molecules/CompletedStaterooms";
import FareCodes from "@componentsV1/pages/Rooms/molecules/FareCodes";
import StateroomsList from "@componentsV1/pages/Rooms/molecules/StateroomsList";
import StateroomsMarkets from "@componentsV1/pages/Rooms/molecules/StateroomsMarkets";
import Button from "@componentsV1/shared/Button";
import { showModal } from "@store/slices/modalSlice";
import { setPricing } from "@store/slices/pricingSlice";
import { Room, updateRoom } from "@store/slices/roomsSlice";
import { Grade } from "@store/slices/searchSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";
import MODAL from "@utils/constants/modal";
import { currencyToFormat } from "@utils/helpers/currency";
import { insertValuesToString } from "@utils/helpers/insertValuesToString";
import { prepareParams } from "@utils/helpers/rooms";

import styles from "./index.module.scss";

function Rooms() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { cruiseId } = useParams();

  const { isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { cruise } = useTypedSelector((state) => state.search);

  const { show_pricing_breakdown, gft_additional_text } = useTypedSelector(
    (state) => state.environment,
  );

  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const currentRoomNumber = +(searchParams.get("room") ?? 0);

  const room = useMemo(() => {
    if (rooms && currentRoomNumber) {
      return rooms[currentRoomNumber];
    }

    return undefined;
  }, [rooms, currentRoomNumber]);

  const canGoForward = useMemo(() => {
    return !!room?.grade && !!room?.guestsNumber;
  }, [room]);

  const additionalGftText = useMemo(() => {
    if (!room?.grade || !room?.fare) {
      return "";
    }

    const { grade, fare } = room;

    const gft = fare?.prices.find((item) => item.grade === grade.code)?.gft
      ? Number(fare?.prices.find((item) => item.grade === grade.code)?.gft)
      : 0;

    const result = insertValuesToString(gft_additional_text, {
      gft: currencyToFormat(gft, cruise?.pricing?.[0]?.currency ?? "USD"),
    });

    return result;
  }, [room]);

  const updateStateRoom = (stateroom: Room) => {
    dispatch(updateRoom({ room: stateroom, roomNumber: currentRoomNumber }));
  };

  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  const handleShowImagesModal = (images: string[]) => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.IMAGES, data: images }));
  };

  const handleShowStateroomsModal = (grade: Grade) => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.STATEROOMS, data: grade }));
  };

  const handleSubmit = () => {
    const pathParam = currentRoomNumber < totalRooms ? "rooms" : "cabin-select";

    const params = prepareParams(
      searchParams,
      rooms ?? {},
      currentRoomNumber,
      currentRoomNumber < totalRooms,
    );

    navigate(`/search-results/${cruiseId}/${pathParam}?${params}`);
  };

  const getButtonLabel = () => {
    if (currentRoomNumber < totalRooms) {
      return `${t("continue to stateroom")} ${currentRoomNumber + 1}`;
    }

    return t("continue to staterooms");
  };

  useEffect(() => {
    dispatch(setPricing(rooms));
  }, [rooms]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={isCruiseLoading || !rooms || !room}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1 className={styles.title}>{t("choose staterooms")}</h1>

            <p className={styles.subtitle}>
              {t("stateroom")} {currentRoomNumber}
            </p>

            <FareCodes room={room} handleChoseRate={updateStateRoom} />

            <StateroomsMarkets room={room} handleChoseMarket={updateStateRoom} />

            <StateroomsList
              room={room}
              handleChoseStateroom={updateStateRoom}
              handleShowImagesModal={handleShowImagesModal}
              handleShowStateroomsModal={handleShowStateroomsModal}
            />

            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                label={getButtonLabel()}
                onClick={handleSubmit}
                disabled={!canGoForward}
                id="continue"
              />
            </div>

            {gft_additional_text && <span className={styles.footerNote}>{additionalGftText}</span>}
          </div>

          <div className={styles.right}>
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="plus"
              onClick={handleShowItineraryModal}
            />

            <CompletedStaterooms />

            {show_pricing_breakdown && (
              <Button
                className={styles.button}
                label={getButtonLabel()}
                onClick={handleSubmit}
                disabled={!canGoForward}
                id="continue-2"
              />
            )}
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Rooms;
