import { Box, Typography, Grid2 as Grid } from "@mui/material";
import { useMemo } from "react";

import { useTypedSelector } from "@store/store";

export function ShipCruiseLine() {
  const { cruise } = useTypedSelector((state) => state.search);

  const { logo, title, descriptions, images } = useMemo(() => {
    if (!cruise?.ship || !cruise?.ship.brand) {
      return {
        description: "Loading...",
      };
    }

    const logo = cruise.ship.brand.logo;
    const title = cruise.ship.brand.name;
    const descriptions = cruise.ship.brand.description;
    const images = cruise.ship.brand.images;

    return {
      logo,
      title,
      descriptions,
      images,
    };
  }, [cruise]);

  return (
    <Box>
      <Typography component="p" variant="sectionHeader" color="text-primary" mb={2}>
        The Cruise line
      </Typography>

      <Grid size={{ xs: 12, md: 6 }} spacing={{ xs: 2, md: 4 }} direction="column" container>
        <Box
          component="img"
          src={logo}
          alt="ship brand logo"
          sx={{ width: { xs: 200, md: 300, lg: 270 } }}
        />

        <Typography
          component="p"
          variant="footnote"
          color="text-dark"
          fontWeight={700}
          textTransform="uppercase"
        >
          {title}
        </Typography>

        {descriptions?.map(({ description }) => {
          return (
            <Typography
              key={description}
              component="p"
              variant="footnote"
              fontWeight={400}
              color="text-dark"
            >
              {description}
            </Typography>
          );
        })}

        <Grid container spacing={2}>
          {(images ?? []).map((image) => {
            return <Grid key={image} component="img" src={image} alt="cruise line" />;
          })}
        </Grid>
      </Grid>
    </Box>
  );
}
