import classNames from "classnames";

import Footer from "@componentsV1/base/Footer";
import Header from "@componentsV1/base/Header";
import ModalContainer from "@componentsV1/containers/ModalContainer";
import ToastContainer from "@componentsV1/containers/ToastContainer";
import AuthLayout from "@componentsV1/layouts/Auth";
import SearchLayout from "@componentsV1/layouts/Search";
import { useTypedSelector } from "@store/store";
import { LayoutType } from "@utils/constants/routes";

import styles from "./index.module.scss";

const LAYOUTS_BY_TYPE: Record<LayoutType, React.FC<React.PropsWithChildren>> = {
  auth: AuthLayout,
  search: SearchLayout,
};

interface IRootLayoutProps {
  type: LayoutType;
  children: React.ReactNode;
}

function RootLayout({ type, children }: IRootLayoutProps) {
  const { header_background_color, footer_background_color } = useTypedSelector(
    (state) => state.environment,
  );

  const isShowHeader = Boolean(header_background_color);
  const isShowFooter = Boolean(footer_background_color);

  const Layout = LAYOUTS_BY_TYPE[type];

  return (
    <ToastContainer>
      <ModalContainer>
        <div>
          {isShowHeader && <Header />}

          <main
            className={classNames(styles.container, {
              [styles.container_withHeader]: isShowHeader,
            })}
          >
            <section className={styles.content}>
              <Layout>{children}</Layout>
            </section>
          </main>

          {isShowFooter && <Footer />}
        </div>
      </ModalContainer>
    </ToastContainer>
  );
}

export default RootLayout;
