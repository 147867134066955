export function parseMarkdownWithLink(markdown: string) {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

  const parsedContent = [];

  let lastIndex = 0;
  let match;

  while ((match = linkRegex.exec(markdown)) !== null) {
    if (match.index > lastIndex) {
      parsedContent.push({ text: markdown.slice(lastIndex, match.index) });
    }

    parsedContent.push({
      text: match[1],
      url: match[2],
    });

    lastIndex = linkRegex.lastIndex;
  }

  if (lastIndex < markdown.length) {
    parsedContent.push({ text: markdown.slice(lastIndex) });
  }

  return parsedContent;
}
