const currentDate = new Date();
const currentYear = currentDate.getFullYear();

function getYearsList(lastYear = 0, downYear = 100) {
  const yearsList = [];

  for (let i = currentYear - downYear; i <= currentYear + lastYear; i += 1) {
    yearsList.push(i);
  }

  return yearsList;
}

export default getYearsList;
