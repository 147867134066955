import classNames from "classnames";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Room } from "@store/slices/roomsSlice";
import { Price } from "@store/slices/searchSlice";
import { useTypedSelector } from "@store/store";
import { parseMarkdownWithLink } from "@utils/helpers/parseMarkdown";

import styles from "./index.module.scss";

interface IFareCodesProps {
  room?: Room;
  handleChoseRate: (stateroom: Room) => void;
}

function FareCodes({ room, handleChoseRate }: IFareCodesProps) {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const { cruise } = useTypedSelector((state) => state.search);

  const isRateChoseAvailable = +(searchParams.get("room") ?? 1) <= 1;

  const sortFare = useMemo(() => {
    const fareArrCopy = cruise?.pricing;
    const findTrue = fareArrCopy?.find((item) => item.lowest_fare);
    const findFalse = fareArrCopy?.filter((item) => !item.lowest_fare) ?? [];

    return findTrue ? [findTrue, ...findFalse] : findFalse;
  }, [cruise]);

  const handleRateClick = (fare: Price) => () => {
    if (isRateChoseAvailable) {
      const updatedStateroom = structuredClone({ ...room, fare });

      handleChoseRate(updatedStateroom);
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("select a fare code")}</p>

      <div
        className={classNames(styles.items, {
          [styles.items_short]: (cruise?.pricing ?? []).length <= 1,
        })}
      >
        {sortFare.map((item) => {
          const isChosen = item?.rate_code === room?.fare?.rate_code;

          return (
            <div key={item?.rate_code} className={styles.item}>
              <div
                className={classNames(styles.itemHeader, {
                  [styles.itemHeader_active]: isChosen,
                })}
              >
                <p>
                  {t("fare:")} {item?.rate_name}
                </p>
              </div>

              <div className={styles.itemBody}>
                {(!item.rate_description || item.rate_description.length === 0) && (
                  <p> {item?.rate_name}</p>
                )}

                {item.rate_description && item.rate_description.length !== 0 && (
                  <ul className={styles.itemBodyList}>
                    {item.rate_description.map((desc: string) => {
                      const parsedDesc = parseMarkdownWithLink(desc);

                      return (
                        <li key={desc} className={styles.itemBodyDesc}>
                          {parsedDesc.map((parsed) => {
                            return parsed.url ? (
                              <a
                                key={parsed.text}
                                href={parsed.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {parsed.text}
                              </a>
                            ) : (
                              <span key={parsed.text}>{parsed.text}</span>
                            );
                          })}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>

              <div
                className={classNames(styles.itemFooter, {
                  [styles.itemFooter_unchosen]: !isChosen,
                  [styles.itemFooter_disabled]: !isRateChoseAvailable,
                })}
              >
                <button
                  type="button"
                  className={styles.selectButton}
                  onClick={!isChosen ? handleRateClick(item) : undefined}
                  disabled={isChosen || !isRateChoseAvailable}
                >
                  {isChosen ? t("selected") : t("select")}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

FareCodes.defaultProps = {
  room: {},
};

export default FareCodes;
