import { reservationApi } from "@store/services/apiSingleton";
import { ReservationInterface } from "@store/slices/reservationSlice";

export const ReservationApi = reservationApi.injectEndpoints({
  endpoints: (builder) => ({
    retrieveReservation: builder.query<
      ReservationInterface,
      { pnr: string; channel_partner: string; agency: string }
    >({
      query: (params) => ({
        url: `/res/reservation-list/${params.pnr}/?channel=CS&channel_partner=${params.channel_partner}&agency=${params.agency}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyRetrieveReservationQuery } = ReservationApi;
