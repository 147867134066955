import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { useMemo } from "react";

import styles from "./index.module.scss";

interface IInputProps {
  className?: string;
  value: string;
  valueKey?: string;
  label?: string;
  placeholder?: string;
  autoComplete?: string;
  name?: string;
  type?: string;
  errorMessage?: string;
  isRequired?: boolean;
  readOnly?: boolean;
  showError?: boolean;
  onClick?: () => void;
  onChange?: ({ value, valueKey }: { value: string; valueKey: string }) => void;
  onBlur?: ({ value, valueKey }: { value: string; valueKey: string }) => void;
}

function Input({
  className,
  value,
  valueKey,
  label,
  placeholder,
  autoComplete,
  name,
  type,
  errorMessage,
  isRequired,
  readOnly,
  showError,
  onClick,
  onChange,
  onBlur,
}: IInputProps) {
  const id = useMemo(nanoid, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      <div className={styles.fieldContainer}>
        <input
          id={id}
          type={type}
          className={classNames(styles.field, {
            [styles.field_error]: !!errorMessage,
          })}
          value={value}
          placeholder={placeholder}
          name={name}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      {showError && <span className={styles.error}>{errorMessage}</span>}
    </div>
  );
}

Input.defaultProps = {
  className: "",
  valueKey: "",
  label: "",
  placeholder: "",
  autoComplete: "",
  name: "",
  type: "text",
  errorMessage: "",
  isRequired: false,
  readOnly: false,
  showError: true,
  onClick: () => null,
  onChange: () => null,
  onBlur: () => null,
};

export default Input;
