import { useEffect } from "react";

import useCookies from "@hooks/useCookies";
import { initApiToken, initEnvironment, initPosSession } from "@store/actions/sessionActions";
import { updateSessionKey, updateToken } from "@store/slices/sessionSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store";

function SessionGuard({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch();
  const { getCookie, setCookie } = useCookies();

  const { isInitialized } = useTypedSelector((state) => state.environment);
  const { token, sessionKey } = useTypedSelector((state) => state.session);

  const cookieToken = getCookie("token");
  const cookieSessionKey = getCookie("sessionKey");

  const initEnvironmentVariables = () => {
    dispatch(initEnvironment());
  };

  const initToken = () => {
    if (cookieToken) {
      dispatch(updateToken(cookieToken));
    } else {
      dispatch(initApiToken());
    }
  };

  const initAgentSession = () => {
    dispatch(updateSessionKey(cookieSessionKey));
  };

  useEffect(initEnvironmentVariables, []);

  useEffect(() => {
    if (isInitialized) {
      initToken();
    }
  }, [isInitialized]);

  useEffect(() => {
    initAgentSession();
  }, []);

  useEffect(() => {
    if (token && !sessionKey) {
      dispatch(initPosSession());
    }
  }, [token, sessionKey]);

  useEffect(() => {
    if (token && !cookieToken) {
      setCookie("token", token, 3600);
    }
  }, [token, cookieToken]);

  useEffect(() => {
    if (sessionKey && !cookieSessionKey) {
      setCookie("sessionKey", sessionKey, 3600);
    }
  }, [sessionKey, cookieSessionKey]);

  return <div>{children}</div>;
}

export default SessionGuard;
