import { Box, Typography } from "@mui/material";

import { useTypedSelector } from "@store/store";

export function CruiseDescription() {
  const { cruise } = useTypedSelector((state) => state.search);

  const { title, description } = cruise?.cruise?.descriptions.find(
    (desc) => desc.category === "Highlights",
  ) ?? {
    title: "",
    description: "",
  };

  return (
    <Box mt={{ xs: 4, md: 8 }} sx={{ textAlign: { md: "center" } }}>
      <Typography component="p" variant="sectionTitle" color="text-primary">
        {title}
      </Typography>

      <Typography component="p" variant="footnote" color="text-dark" mt={{ xs: 2, md: 4 }}>
        {description}
      </Typography>
    </Box>
  );
}
