import SvgIcon from "@componentsV1/shared/SvgIcon";

import styles from "./index.module.scss";

interface ICounterProps {
  value: string;
  valueKey?: string;
  errorMessage?: string;
  min?: number;
  max?: number;
  onChange: ({ value, valueKey }: { value: string; valueKey?: string }) => void;
}

function Counter({ value, valueKey, errorMessage, min, max, onChange }: ICounterProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value >= (min ?? 0) && +e.target.value <= (max ?? Infinity)) {
      onChange({ value: e.target.value, valueKey });
    }
  };

  const handleButtonClick = (action: "increase" | "decrease") => () => {
    let newValue = +value;

    switch (action) {
      case "increase": {
        newValue += 1;
        break;
      }

      default: {
        newValue -= 1;
        break;
      }
    }

    if (newValue >= (min ?? 0) && newValue <= (max ?? Infinity)) {
      onChange({ value: `${newValue}`, valueKey });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        <button className={styles.button} onClick={handleButtonClick("decrease")} type="button">
          <SvgIcon type="minus" />
        </button>

        <input
          className={styles.input}
          type="number"
          value={value}
          min={min}
          max={max}
          onChange={handleChange}
        />

        <button className={styles.button} onClick={handleButtonClick("increase")} type="button">
          <SvgIcon type="plus" />
        </button>
      </div>

      <span className={styles.error}>{errorMessage}</span>
    </div>
  );
}

Counter.defaultProps = {
  valueKey: "",
  errorMessage: "",
  min: 0,
  max: undefined,
};

export default Counter;
