import { useState } from "react";

export const usePaymentState = () => {
  // State variables for managing payment details (old) TODO: remove once AirWallex is set up
  const [priceType, setPriceType] = useState<"full" | "deposit">("full");
  const [isLoading, setIsLoading] = useState(false);

  // AirWallex card state (new)
  // Example: element ready states, controls the display for when elements are successfully mounted
  const [cardNumberReady, setCardNumberReady] = useState(false);
  const [cvcReady, setCvcReady] = useState(false);
  const [expiryReady, setExpiryReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputErrorMessage, setInputErrorMessage] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
  });
  // Example: element validation state, checks if each field is completed by the shopper
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);

  return {
    priceType,
    setPriceType,
    isLoading,
    setIsLoading,
    // aiwallex:
    cardNumberReady,
    setCardNumberReady,
    cvcReady,
    setCvcReady,
    expiryReady,
    setExpiryReady,
    cardNumberComplete,
    setCardNumberComplete,
    cvcComplete,
    setCvcComplete,
    expiryComplete,
    setExpiryComplete,
    errorMessage,
    setErrorMessage,
    inputErrorMessage,
    setInputErrorMessage,
  };
};
